import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BaseViewCardPropsType, FetchDataParams, GxpDataType, QueryParamsPropsType } from 'types/wctTypes';
import { filterParams, getOktaToken } from 'utils/common-methods';
import { GXP_TRAINING_URL } from 'utils/constants'
import { StringKeyAnyDataProps, StringKeyDataProps } from 'utils/data-types';

interface gmpTrainingSummaryItem {
    manager?: number | string;
    manager_staff_id?: number | string;
    compliance_percentage?: number | string;
    pending?: number | string;
    overdue?: number | string;
    target?: number | string;
    cost_center_name?: number | string;
    cost_center_code?: number | string;
}

interface gmpSiteCompliance {
    aoh_compliance?: number | string;
    shift_compliance?: number | string;
    cost_center_compliance?: number | string;
}

interface gmpTrainingTableItem {
    la_name?: number | string;
    la_code?: number | string;
    overdue?: number | string;
    target?: number | string;
}

interface gmpTrainingGraphItem {
    Compliance_Date?: number | string;
    cumulative_overdue?: number | string;
    cumulative_on_track?: number | string;
    Compliance_Rate?: number | string;
}

interface gmpTrainingDataType {
    summary: gmpTrainingSummaryItem[];
    table_Data: gmpTrainingTableItem[];
    graph_data: gmpTrainingGraphItem[];
    compliance_data: gmpSiteCompliance[];
    query_params?: QueryParamsPropsType
}

interface gmpTrainingInfoState {
    gmpTrainingInfo: BaseViewCardPropsType | null;
    loading: boolean;
    error: boolean;
}

interface gmpGraphItem {
    compliance_date?: string | number;
    cumulative_overdue?: number | string;
    cumulative_on_track?: number | string;
    compliance_rate?: number | string;
}

const initialState: gmpTrainingInfoState = {
    gmpTrainingInfo: null,
    loading: false,
    error: false,
}

const fetchGmpTraining = createAsyncThunk<gmpTrainingDataType, FetchDataParams>(
    GXP_TRAINING_URL,
    async (params) => {
        const filteredParams = filterParams(params)
        const token: string = getOktaToken();
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}${GXP_TRAINING_URL}?${new URLSearchParams(filteredParams).toString()}`,
            {
                method: "GET",
                headers: {
                    authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error('Failed to fetch Gmp Training Compliance');
        }
        return await response.json();
    }
)

const gmpConvertedData = (data: gmpGraphItem[]): StringKeyDataProps[] => {
    const sortedData = [...data].sort((a, b) => {
        if (
            typeof a.compliance_date === "string" &&
            typeof b.compliance_date === "string"
        ) {
            const prevDate = new Date(a.compliance_date)
            const nextDate = new Date(b.compliance_date)
            const monthDifference = prevDate.getMonth() - nextDate.getMonth()
            if (monthDifference !== 0) {
                return monthDifference
            }
            return prevDate.getDate() - nextDate.getDate()
        }
        return 0
    })

    const datas = sortedData.map((item) => {
        if (typeof item.compliance_date === "string") {
            const dateFormat = new Date(item.compliance_date).toLocaleString(
                "en-US",
                { month: "short", day: "numeric" }
            );
            return {
                name: dateFormat,
                value: item.compliance_rate ?? 0
            }
        }
        return { name: "", value: "" }
    })
    return datas
}

const transformData = (gmpTrainingInfo: gmpTrainingDataType): BaseViewCardPropsType => {
    const gxpBaseViewData: BaseViewCardPropsType = {
        cardData: {},
        graphData: [],
        graphParams: {},
        cardProps: {
            alertType: 'success'
        }
    }

    const gxpTrainingSanitizedData: StringKeyDataProps = {};
    let lineCompliancePercentage;
    let managerName;
    let managerStaffId;
    let target;
    let overdue;
    let pending;
    let shiftCompliancePercentage;
    let costCenterName;
    let costCenterCode;
    let costCenterCompliancePercentage;
    let laName;
    let laCode;
    let overDueTable;
    let targetTable;
    let siteCompliancePercentage;
    let complianceDate;
    let complianceRate;
    let cumulativeOverdue;

    const tierLabel = gmpTrainingInfo.query_params?.tierLabel ?? ""

    if (gmpTrainingInfo?.summary?.length) {
        lineCompliancePercentage = Number(
            Number(gmpTrainingInfo?.summary[0]?.compliance_percentage).toFixed(2)
        );
        managerName = gmpTrainingInfo?.summary[0]?.manager;
        managerStaffId = gmpTrainingInfo?.summary[0]?.manager_staff_id;
        target = gmpTrainingInfo?.summary[0]?.target;
        overdue = gmpTrainingInfo?.summary[0]?.overdue;
        pending = gmpTrainingInfo?.summary[0]?.pending;
        costCenterName = gmpTrainingInfo?.summary[0]?.cost_center_name;
        costCenterCode = gmpTrainingInfo?.summary[0]?.cost_center_code;
    }
    if (gmpTrainingInfo?.compliance_data?.length) {
        siteCompliancePercentage = Number(
            Number(gmpTrainingInfo?.compliance_data?.[0]?.aoh_compliance).toFixed(2)
        );
        shiftCompliancePercentage = Number(
            Number(gmpTrainingInfo?.compliance_data?.[0]?.shift_compliance).toFixed(
                2
            )
        );
        costCenterCompliancePercentage = Number(
            Number(
                gmpTrainingInfo?.compliance_data?.[0]?.cost_center_compliance
            ).toFixed(2)
        );
    }
    if (gmpTrainingInfo?.table_Data?.length) {
        laName = gmpTrainingInfo?.table_Data[0]?.la_name;
        laCode = gmpTrainingInfo?.table_Data[0]?.la_code;
        overDueTable = gmpTrainingInfo?.table_Data[0]?.overdue;
        targetTable = gmpTrainingInfo?.table_Data[0]?.target;
    }
    if (gmpTrainingInfo?.graph_data?.length) {
        complianceDate = gmpTrainingInfo?.graph_data[0]?.Compliance_Date;
        complianceRate = gmpTrainingInfo?.graph_data[0]?.Compliance_Rate;
        cumulativeOverdue = gmpTrainingInfo?.graph_data[0]?.cumulative_overdue;
    }
    if (
        typeof lineCompliancePercentage === "number" &&
        lineCompliancePercentage >= 0
    ) {
        gxpTrainingSanitizedData["LINE COMPLIANCE %"] = lineCompliancePercentage;
    }
    if (
        typeof shiftCompliancePercentage === "number" &&
        shiftCompliancePercentage >= 0
    ) {
        gxpTrainingSanitizedData["SHIFT COMPLIANCE %"] =
            shiftCompliancePercentage;
    }
    if (tierLabel.toLowerCase() !== "tier 3") {
        if (
            typeof costCenterCompliancePercentage === "number" &&
            costCenterCompliancePercentage >= 0
        ) {
            gxpTrainingSanitizedData["BUILDING COMPLIANCE %"] =
                costCenterCompliancePercentage;
        }
    }

    if (
        typeof siteCompliancePercentage === "number" &&
        siteCompliancePercentage >= 0
    ) {
        gxpTrainingSanitizedData["SITE COMPLIANCE %"] = siteCompliancePercentage;
    }
    gmpTrainingInfo?.table_Data?.forEach((item, index) => {
        if (typeof item.la_name === "string") {
            gxpTrainingSanitizedData[" " + "&" + item.la_name] = item.overdue ?? "";
        }
    });
    gmpTrainingInfo?.summary?.forEach((item, index) => {
        if (typeof item.manager === "string") {
            gxpTrainingSanitizedData[item.manager] = item.pending ?? "";
        }
    });
    gmpTrainingInfo?.summary?.forEach((item, index) => {
        if (typeof item.cost_center_name === "string") {
            gxpTrainingSanitizedData[item.cost_center_name] = item.overdue ?? "";
        }
    });

    if (
        tierLabel?.toLowerCase() === "tier 0" ||
        tierLabel?.toLowerCase() === "tier 1" ||
        tierLabel?.toLowerCase() === "tier 2"
    ) {
        gxpBaseViewData.graphParams.tooltipContent = "% Compliance Each day over last 7 Days"
    } else if (tierLabel?.toLowerCase() === "tier 3") {
        gxpBaseViewData.graphParams.tooltipContent = "% Compliance Each Week Over Last 6 Months"
    }

    if (
        tierLabel?.toLowerCase() === "tier 0" &&
        typeof lineCompliancePercentage === "number" &&
        lineCompliancePercentage <= 95
    ) {
        gxpBaseViewData.cardProps.alertType = "error"
    } else if (
        tierLabel?.toLowerCase() === "tier 1" &&
        typeof shiftCompliancePercentage === "number" &&
        shiftCompliancePercentage <= 95
    ) {
        gxpBaseViewData.cardProps.alertType = "error"
    } else if (
        tierLabel?.toLowerCase() === "tier 2" &&
        typeof costCenterCompliancePercentage === "number" &&
        costCenterCompliancePercentage <= 95
    ) {
        gxpBaseViewData.cardProps.alertType = "error"
    } else if (
        tierLabel?.toLowerCase() === "tier 3" &&
        typeof siteCompliancePercentage === "number" &&
        siteCompliancePercentage <= 95
    ) {
        gxpBaseViewData.cardProps.alertType = "error"
    } else {
        gxpBaseViewData.cardProps.alertType = "success"
    }

    if (gmpTrainingInfo?.graph_data?.length) {
        const outputData = gmpConvertedData(gmpTrainingInfo?.graph_data);
        gxpBaseViewData.graphData = outputData
        gxpBaseViewData.graphParams.isGraph = true
    } else {
        gxpBaseViewData.graphData = []
        gxpBaseViewData.graphParams.isGraph = false
    }
    gxpBaseViewData.cardData = gxpTrainingSanitizedData

    return gxpBaseViewData
}

const gmpTrainingReducer = createSlice({
    name: 'gmpTraining',
    initialState,
    reducers: {
        clearGmpTrainingData(state) {
            state.gmpTrainingInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGmpTraining.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchGmpTraining.fulfilled, (state, action) => {
                state.loading = false;
                state.gmpTrainingInfo = transformData(action.payload);
            })
            .addCase(fetchGmpTraining.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export { fetchGmpTraining };

export const { clearGmpTrainingData } = gmpTrainingReducer.actions

export default gmpTrainingReducer.reducer;