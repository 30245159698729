import React from 'react';
import calendarCss from './Calendar.module.css';

export interface CalendarProps {
    greenDates?: number[]; // Array of dates to be highlighted in green
    redDates?: number[]; // Array of dates to be highlighted in red
    greenColor?: string; // Optional custom green color
    redColor?: string; // Optional custom red color
}

function getDaysInCurrentMonth() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1; // getMonth() returns 0-11, so add 1 for 1-12
    return new Date(year, month, 0).getDate();
  }

const Calendar: React.FC<CalendarProps> = ({ greenDates, redDates, greenColor = '#F0FFF0', redColor = '#FFE7E6' }) => {
    const daysInMonth = getDaysInCurrentMonth(); // Get the number of days in the current month
    const currentDate = parseInt(`${new Date().getDate()}`)
    const renderDays = () => {
        const days = [];
        for (let i = 1; i <= daysInMonth; i++) {
            let dayColorClassName =  greenDates ? (greenDates?.includes(i) ? calendarCss.green : calendarCss.red) : redDates ? (redDates?.includes(i) ? calendarCss.red : calendarCss.green) : ''
            let dayStyle = greenDates?.includes(i) ? (greenColor ? { backgroundColor: greenColor } : {}) : (redColor ? { backgroundColor: redColor } : {})
            if (i < currentDate) {
                dayColorClassName = `${calendarCss.day} ${dayColorClassName}`
            } else if (i === currentDate) {
                dayColorClassName = `${calendarCss.day} ${calendarCss.active}`
                dayStyle = {}
            } else {
                dayColorClassName = `${calendarCss.day} ${calendarCss.disabled}`
                dayStyle = {}
            }
            days.push(
                <div
                    key={i}
                    className={dayColorClassName}
                    style={dayStyle}>
                    {i}
                </div>
            );
        }
        return days;
    };

    return <div className={calendarCss.calendar}>{renderDays()}</div>;
};

export default Calendar;
