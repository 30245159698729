import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { FetchDataParams } from 'types/wctTypes';
import { filterParams, getOktaToken } from 'utils/common-methods';
import { SEE_CARDS_URL } from 'utils/constants';
 
interface SeeCardsOverdueItem {
    see_cards_last_24hours?: number | string;
    total_overdue?: number | string;
    total_coming_due_in_5days?: number | string;
    total_coming_due_in_2days?: number | string;
}
 
interface OPDItem {
    date?: string;
    see_card_last_5_days?: number | string;
    see_card_last_30_days?: number | string;
}
 
interface AreaAlertItem {
    count?: number | string;
    employee_cost_center_name?: string;
    see_card_count?: number | string;
}
 
interface SeeCardsDataType {
    overdues: SeeCardsOverdueItem[];
    overduesPerDay: OPDItem[];
    areaAlert: AreaAlertItem[];
}
 
interface TransformedData {
    seeCards: SeeCardsDataType;
}
 
interface SeeCardsInfoState {
    seeCardsInfo: TransformedData | null;
    loading: boolean;
    error: boolean;
}
 
const initialState: SeeCardsInfoState = {
    seeCardsInfo: null,
    loading: false,
    error: false,
}
 
const fetchSeeCards = createAsyncThunk<SeeCardsDataType, FetchDataParams>(
    SEE_CARDS_URL,
    async (params) => {
        const filteredParams = filterParams(params)
        const token: string = getOktaToken();

        const response = await fetch(`${process.env.REACT_APP_API_URL}/work-center-team/metrics/safety/base-view/see-cards?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });
 
        if (!response.ok) {
            throw new Error('Failed to fetch seeCards');
        }
        return await response.json();
    }
);
 
const transformData = (data: SeeCardsDataType): TransformedData => ({
    seeCards: {
            overdues: data.overdues?.map((item) => ({
                see_cards_last_24hours: item.see_cards_last_24hours ?? "",
                total_overdue: item.total_overdue ?? "",
                total_coming_due_in_5days: item.total_coming_due_in_5days ?? "",
                total_coming_due_in_2days: item.total_coming_due_in_2days ?? ""
               
            })),
            overduesPerDay: data.overduesPerDay?.map((item) => ({
                date: item.date ?? "",
                see_card_last_5_days: item.see_card_last_5_days ?? "",
                see_card_last_30_days: item.see_card_last_30_days ?? ""
            })),
            areaAlert: data.areaAlert?.map((item) => ({
                count: item.count ?? "",
                employee_cost_center_name: item.employee_cost_center_name ?? "",
                see_card_count: item.see_card_count ?? ""
            }))
        }
});
 
const seeCardsReducer = createSlice({
    name: 'seeCards',
    initialState,
    reducers: {
        clearSeeCardsData(state) {
            state.seeCardsInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSeeCards.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchSeeCards.fulfilled, (state, action) => {
                state.loading = false;
                state.seeCardsInfo = transformData(action.payload);
            })
            .addCase(fetchSeeCards.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
    },
});
 
export { fetchSeeCards };

export const { clearSeeCardsData } = seeCardsReducer.actions
 
export default seeCardsReducer.reducer;