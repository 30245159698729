import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOktaToken } from 'utils/common-methods';
import { MINOR_DEVIATION_DEEP_DIVE_URL } from 'utils/constants'

interface minorDeviationSummaryItem {
    open_deviation_last_24h?: number | string;
    unclosed_deviations?: number | string;
    avg_ageing?: number | string;
    overdue_minor_deviation?: number | string;
    "%_aging_minor_deviation"?: number | string;
    open_minor_deviation?: number | string;
    mtd_deviation_ad_percentage?: number | string;
    mtd_aging_deviation_percentage?: number | string;
}

interface minorDeviationTableItem {
    initiated_date?: number | string;
    deviation_id?: number | string;
    area?: number | string;
    status?: number | string;
    nc_type?: number | string;
    age?: number | string;
    deviation_owner?: number | string;
    qa_contact?: number | string;
    short_desc?: number | string;
}

interface graphItem7Days {
  last_7d_date?: number | string;
  open_aging_count_7d?: number | string;
  open_count_7d?: number | string;
  coming_due_last_7d_count?: number | string;
}

interface graphItemAdherence6m {
    Month?: number | string;
    Year?: number | string;
    deviation_percentage_6m?: number | string;
    on_time_review_6m?: number | string;
    not_on_time_review_6m?: number | string;
}

interface graphItemAdherenceMtd {
    mtd_date?: number | string;
    deviation_percentage_mtd?: number | string;
    on_time_review_mtd?: number | string;
    not_on_time_review_mtd?: number | string;
}

interface graphItemAging6m {
    Month?: number | string;
    Year?: number | string;
    aging_open_percentage_6m?: number | string;
    open_aging_count_6m?: number | string;
    open_count_6m?: number | string;
}
interface graphItemAgingMtd {
    mtd_date?: number | string;
    aging_open_percentage_mtd?: number | string;
    open_aging_count_mtd?: number | string;
    open_count_mtd?: number | string;
}

interface minorDeviationDataType {
    summary: minorDeviationSummaryItem[];
    tableDataAll: minorDeviationTableItem[];
    tableDataAging: minorDeviationTableItem[];
    tableDataOpen: minorDeviationTableItem[];
    graphData: graphItem7Days[];
    adherenceGraphData6m: graphItemAdherence6m[];
    adherenceGraphDataMtd: graphItemAdherenceMtd[];
    agingOpenGraphData6m: graphItemAging6m[];
    agingOpenGraphDataMtd: graphItemAgingMtd[];
}

interface minorDeviationInfoState {
    minorDeviationDeepDiveInfo: minorDeviationDataType | null;
    loading: boolean;
    error: boolean;
}

const initialState: minorDeviationInfoState = {
    minorDeviationDeepDiveInfo: null,
    loading: false,
    error: false,
};

interface FetchDataParams {
    workstream?: string | undefined,
    site?: string | undefined,
    persona?: string | undefined,
    tierNumber?: string | undefined,
    shift?: string | undefined,
    costCenter?: string | undefined,
    line?: string | undefined,
    building?: string | undefined
}

const fetchMinorDeviationDeepDive = createAsyncThunk<minorDeviationDataType, FetchDataParams>(
    MINOR_DEVIATION_DEEP_DIVE_URL,
    async (params) => {
        const { workstream, site, persona, tierNumber, shift, costCenter, line, building } = params;
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}${MINOR_DEVIATION_DEEP_DIVE_URL}?workStream=${workstream}&site=${site}&persona=${persona}&tierNumber=${tierNumber}&line=${line}&shift=${shift}&costCenter=${costCenter}&building=${building}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch Minor Deviation');
        }
        return await response.json();
    }
);

const minorDeviationDeepDiveReducer = createSlice({
    name: 'minorDeviationDeepDive',
    initialState,
    reducers: {
        clearQualityMinorDevData(state) {
            state.minorDeviationDeepDiveInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMinorDeviationDeepDive.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchMinorDeviationDeepDive.fulfilled, (state, action) => {
                state.loading = false;
                state.minorDeviationDeepDiveInfo = action.payload;
            })
            .addCase(fetchMinorDeviationDeepDive.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export { fetchMinorDeviationDeepDive };
export const { clearQualityMinorDevData } = minorDeviationDeepDiveReducer.actions

export default minorDeviationDeepDiveReducer.reducer;