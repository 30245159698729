import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOktaToken } from 'utils/common-methods';
import { MAJOR_DEVIATION_DEEP_DIVE_URL } from 'utils/constants'

interface majorDeviationSummaryItem {
    overdue_major_deviation?: number | string;
    coming_due_major_deviation_in_7_days?: number | string;
    "%_aging_major_deviation"?: number | string;
    open_major_deviation?: number | string;
    "%_major_deviations_adherence_(mtd)"?: number | string;
    "%_aging_major_deviations_(mtd)"?: number | string;
}

interface majorDeviationTableItem {
    initiated_date?: number | string;
    deviation_id?: number | string;
    status?: number | string;
    area?: number | string;
    nc_type?: number | string;
    extension_times?: number | string;
    deviation_owner?: number | string;
    qa_contact?: number | string;
    short_desc?: number | string;
}

interface graphItem7Days {
    last_7d_date?: number | string;
    coming_due_last_7d_count?: number | string;
    open_count_7d?: number | string;
    open_aging_count_7d?: number | string;
}

interface graphItemAdherence6m {
    Month?: number | string;
    Year?: number | string;
    deviation_percentage_6m?: number | string;
    on_time_review_6m?: number | string;
    not_on_time_review_6m?: number | string;
}

interface graphItemAdherenceMtd {
    mtd_date?: number | string;
    deviation_percentage_mtd?: number | string;
    on_time_review_mtd?: number | string;
    not_on_time_review_mtd?: number | string;
}

interface graphItemAging6m {
    Month?: number | string;
    Year?: number | string;
    aging_open_percentage_6m?: number | string;
    open_aging_count_6m?: number | string;
    open_count_6m?: number | string;
}
interface graphItemAgingMtd {
    mtd_date?: number | string;
    aging_open_percentage_mtd?: number | string;
    open_aging_count_mtd?: number | string;
    open_count_mtd?: number | string;
}

interface majorDeviationDataType {
    summary: majorDeviationSummaryItem[];
    tableDataAll: majorDeviationTableItem[];
    tableDataOverdue: majorDeviationTableItem[];
    tableDataComingDue: majorDeviationTableItem[];
    tableDataAging: majorDeviationTableItem[];
    tableDataOpen: majorDeviationTableItem[];
    graphData: graphItem7Days[];
    adherenceGraphData6m: graphItemAdherence6m[];
    adherenceGraphDataMtd: graphItemAdherenceMtd[];
    agingOpenGraphData6m: graphItemAging6m[];
    agingOpenGraphDataMtd: graphItemAgingMtd[];
}

interface majorDeviationInfoState {
    majorDeviationDeepDiveInfo: majorDeviationDataType | null;
    loading: boolean;
    error: boolean;
}

const initialState: majorDeviationInfoState = {
    majorDeviationDeepDiveInfo: null,
    loading: false,
    error: false,
};

interface FetchDataParams {
    workstream?: string | undefined,
    site?: string | undefined,
    persona?: string | undefined,
    tierNumber?: string | undefined,
    shift?: string | undefined,
    costCenter?: string | undefined,
    line?: string | undefined,
    building?: string | undefined
}

const fetchMajorDeviationDeepDive = createAsyncThunk<majorDeviationDataType, FetchDataParams>(
    MAJOR_DEVIATION_DEEP_DIVE_URL,
    async (params) => {
        const { workstream, site, persona, tierNumber, shift, costCenter, line, building } = params;
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}${MAJOR_DEVIATION_DEEP_DIVE_URL}?workStream=${workstream}&site=${site}&persona=${persona}&tierNumber=${tierNumber}&line=${line}&shift=${shift}&costCenter=${costCenter}&building=${building}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch Major Deviation');
        }
        return await response.json();
    }
);

const majorDeviationDeepDiveReducer = createSlice({
    name: 'majorDeviationDeepDive',
    initialState,
    reducers: {
        clearQualityMajorDevData(state) {
            state.majorDeviationDeepDiveInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMajorDeviationDeepDive.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchMajorDeviationDeepDive.fulfilled, (state, action) => {
                state.loading = false;
                state.majorDeviationDeepDiveInfo = action.payload;
            })
            .addCase(fetchMajorDeviationDeepDive.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export { fetchMajorDeviationDeepDive };
export const { clearQualityMajorDevData } = majorDeviationDeepDiveReducer.actions

export default majorDeviationDeepDiveReducer.reducer;