import { Radio } from "@gitlab-rtsensing/component-library";

type TimePeriod = string

interface RadioFiltersProps {
    timePeriod: TimePeriod;
    setTimePeriod: (value: TimePeriod) => void;
    tierLabel: string;
}
export const RadioFiltersGraph: React.FC<RadioFiltersProps> = ({
    timePeriod,
    setTimePeriod,
    tierLabel
}) => {
    let timePeriods: TimePeriod[] = []
    if (tierLabel === "Tier 3") {
        timePeriods = ["MTD", "Last 6 Months"]
    }
    return (
        <div className="d-flex" style={{ marginBottom: "10px" }}>
            <div className="ops-fs-5 ops-fw-bold" style={{ marginRight: "15px" }}>
                TIME PERIOD :
            </div>
            {timePeriods.map((period) => (
                <div key={period} style={{ marginRight: "8px" }}>
                    <Radio
                        name="timePeriod"
                        value={period}
                        checked={timePeriod === period}
                        onChange={() => setTimePeriod(period)}
                        variant="primary"
                    />
                </div>
            ))}
        </div>
    );
};
