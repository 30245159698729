import React, { useState } from "react";

import SummaryCard from "../../common/summaryCard/summaryCard";

import "./Quality.scss";
import { CapaEVColumns, CapaStatusColumns, ChangeControlColumns, GxpInfoType, GXPTableColumnsType, majorDeviationColumns, minorDeviationColumns } from "types/wctTypes";
import { Button } from "@gitlab-rtsensing/component-library";
import { DownloadOutlined } from "@ant-design/icons";
import { RadioFilters } from "components/resuable/radio-filters/RadioFiltersQuality";
import StackedBarChart from "components/common/StackedBar/StackedBar";
import { RadioFiltersGraph } from "components/resuable/radio-filters/RadioFilterGraph";
import ColorCodedTable from "components/common/table/ColorCodedTable";
import AohTable from "components/common/table/AohTable";

type DataType = GxpInfoType;

interface QualityTier3Props {
    data: any;
    tierLabel: string;
    activeKeyDeepDiveTab: string;
}

const QualityTier3: React.FC<QualityTier3Props> = ({
    data: { summary, heatmapData, adherenceGraphDataMtd, adherenceGraphData6m, agingOpenGraphDataMtd, agingOpenGraphData6m, tableDataAll, tableDataOverdue, tableDataComingDue, tableDataAging, tableDataOpen, CAPAtableDataAll, CAPAtableDataOverdue, CAPAtableDataComingDue, CAPAtableDataAging, CAPAtableDataOpen, CAPAEVtableDataAll, CAPAEVtableDataOverdue, CAPAEVtableDataComingDue, CAPAEVtableDataAging, CAPAEVtableDataOpen, capaAdherenceMtdGraph, capaAdherence6Month, capaAgingMtd, capaAging6Month },
    tierLabel,
    activeKeyDeepDiveTab,
}) => {
    const [minDevTableTimePeriod, setMinDevTableTimePeriod] = useState("All")
    const [minDevTable2TimePeriod, setMinDevTable2TimePeriod] = useState("All")
    const [graphTimePeriod, setGraphTimePeriod] = useState("MTD")
    const [agingGraphTimePeriod, setAgingGraphTimePeriod] = useState("MTD")

    const qualityDeepDive: any = {
        "Major Deviation": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary?.[0]}
                />
            ),
            titleAdherence: "% of Major Deviation Adherence",
            subtitleAdherence: "",
            graphRadioFiltersAdherence: (
                <RadioFiltersGraph
                    timePeriod={graphTimePeriod}
                    setTimePeriod={setGraphTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            stackBarAdMtd: (
                <StackedBarChart
                    data={adherenceGraphDataMtd}
                    chartType="major-deviation-adherence-mtd"
                />
            ),
            stackBarAd6m: (
                <StackedBarChart
                    data={adherenceGraphData6m}
                    chartType="major-deviation-adherence-6m"
                />
            ),
            titleAging: "% of Aging Major Deviation",
            subtitleAging: "",
            graphRadioFiltersAging: (
                <RadioFiltersGraph
                    timePeriod={agingGraphTimePeriod}
                    setTimePeriod={setAgingGraphTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            stackBarAgingMtd: (
                <StackedBarChart
                    data={agingOpenGraphDataMtd}
                    chartType="major-deviation-aging-mtd"
                />
            ),
            stackBarAging6m: (
                <StackedBarChart
                    data={agingOpenGraphData6m}
                    chartType="major-deviation-aging-6m"
                />
            ),
            title: "Major Deviations",
            radioFilters: (
                <RadioFilters
                    timePeriod={minDevTableTimePeriod}
                    setTimePeriod={setMinDevTableTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            button: (
                <Button
                    label="Download"
                    icon={<DownloadOutlined style={{ color: "#0063C3", fontSize: "14px" }} />}
                    iconPosition="right"
                    onClick={() => { }}
                    type="secondary"
                />
            ),
            table: <AohTable
                tableData={
                    (minDevTableTimePeriod === "All" || minDevTableTimePeriod === "Overdue")
                        ? (minDevTableTimePeriod === "All" ? tableDataAll : minDevTableTimePeriod === "Overdue" ? tableDataOverdue : tableDataComingDue) : (minDevTableTimePeriod === "Aging" ? tableDataAging : tableDataOpen)}
                tableColumns={majorDeviationColumns} />,
        },
        "Minor Deviation": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary?.[0]}
                />
            ),
            titleAdherence: "% of Minor Deviation Adherence",
            subtitleAdherence: "",
            graphRadioFiltersAdherence: (
                <RadioFiltersGraph
                    timePeriod={graphTimePeriod}
                    setTimePeriod={setGraphTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            stackBarAdMtd: (
                <StackedBarChart
                    data={adherenceGraphDataMtd}
                    chartType="minor-deviation-adherence-mtd"
                />
            ),
            stackBarAd6m: (
                <StackedBarChart
                    data={adherenceGraphData6m}
                    chartType="minor-deviation-adherence-6m"
                />
            ),
            titleAging: "% of Aging Minor Deviation",
            subtitleAging: "",
            graphRadioFiltersAging: (
                <RadioFiltersGraph
                    timePeriod={agingGraphTimePeriod}
                    setTimePeriod={setAgingGraphTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            stackBarAgingMtd: (
                <StackedBarChart
                    data={agingOpenGraphDataMtd}
                    chartType="minor-deviation-aging-mtd"
                />
            ),
            stackBarAging6m: (
                <StackedBarChart
                    data={agingOpenGraphData6m}
                    chartType="minor-deviation-aging-6m"
                />
            ),
            title2: "Minor Deviations",
            radioFilters: (
                <RadioFilters
                    timePeriod={minDevTableTimePeriod}
                    setTimePeriod={setMinDevTableTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            button: (
                <Button
                    label="Download"
                    icon={<DownloadOutlined style={{ color: "#0063C3", fontSize: "14px" }} />}
                    iconPosition="right"
                    onClick={() => { }}
                    type="secondary"
                />
            ),
            table: <AohTable
                tableData={
                    (minDevTableTimePeriod === "All" || minDevTableTimePeriod === "Overdue")
                        ? (minDevTableTimePeriod === "All" ? tableDataAll : minDevTableTimePeriod === "Overdue" ? tableDataOverdue : tableDataComingDue) : (minDevTableTimePeriod === "Aging" ? tableDataAging : tableDataOpen)}
                tableColumns={minorDeviationColumns} />
        },
        "GXP Training %Compliance": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary}
                />
            ),
            heatmapTitle: "Compliance Rate (%)",
            heatmap: (
                <ColorCodedTable
                    data={heatmapData}
                    metricKey="gxp"
                />
            )
        },
        "Change Control Status": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary}
                />
            ),
            titleAdherence: "% of Change Control Adherence",
            graphRadioFiltersAdherence: (
                <RadioFiltersGraph
                    timePeriod={graphTimePeriod}
                    setTimePeriod={setGraphTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            stackBarAdMtd: (
                <StackedBarChart
                    data={adherenceGraphDataMtd}
                    chartType="change-control-adherence-mtd"
                />
            ),
            stackBarAd6m: (
                <StackedBarChart
                    data={adherenceGraphData6m}
                    chartType="change-control-adherence-6m"
                />
            ),
            titleAging: "% of Aging Change Controls",
            graphRadioFiltersAging: (
                <RadioFiltersGraph
                    timePeriod={agingGraphTimePeriod}
                    setTimePeriod={setAgingGraphTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            stackBarAgingMtd: (
                <StackedBarChart
                    data={agingOpenGraphDataMtd}
                    chartType="change-control-aging-mtd"
                />
            ),
            stackBarAging6m: (
                <StackedBarChart
                    data={agingOpenGraphData6m}
                    chartType="change-control-aging-6m"
                />
            ),
            title2: "Change Control",
            radioFilters: (
                <RadioFilters
                    timePeriod={minDevTableTimePeriod}
                    setTimePeriod={setMinDevTableTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            button: (
                <Button
                    label="Download"
                    icon={<DownloadOutlined style={{ color: "#0063C3", fontSize: "14px" }} />}
                    iconPosition="right"
                    onClick={() => { }}
                    type="secondary"
                />
            ),
            table: <AohTable
                tableData={
                    (minDevTableTimePeriod === "All" || minDevTableTimePeriod === "Overdue")
                        ? (minDevTableTimePeriod === "All" ? tableDataAll : minDevTableTimePeriod === "Overdue" ? tableDataOverdue : tableDataComingDue) : (minDevTableTimePeriod === "Aging" ? tableDataAging : tableDataOpen)}
                tableColumns={ChangeControlColumns} />
        },
        "CAPA Status": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary}
                />
            ),
            titleAdherence: "% of CAPA Adherence",
            graphRadioFiltersAdherence: (
                <RadioFiltersGraph
                    timePeriod={graphTimePeriod}
                    setTimePeriod={setGraphTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            stackBarAdMtd: (
                <StackedBarChart
                    data={capaAdherenceMtdGraph}
                    chartType="capa-status-mtd"
                />
            ),
            stackBarAd6m: (
                <StackedBarChart
                    data={capaAdherence6Month}
                    chartType="capa-status-adherence-6m"
                />
            ),
            titleAging: "% of Aging CAPAs",
            graphRadioFiltersAging: (
                <RadioFiltersGraph
                    timePeriod={agingGraphTimePeriod}
                    setTimePeriod={setAgingGraphTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            stackBarAgingMtd: (
                <StackedBarChart
                    data={capaAgingMtd}
                    chartType="capa-status-aging-mtd"
                />
            ),
            stackBarAging6m: (
                <StackedBarChart
                    data={capaAging6Month}
                    chartType="capa-status-aging-6m"
                />
            ),
            title2: "CAPA",
            radioFilters: (
                <RadioFilters
                    timePeriod={minDevTableTimePeriod}
                    setTimePeriod={setMinDevTableTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            button: (
                <Button
                    label="Download"
                    icon={<DownloadOutlined style={{ color: "#0063C3", fontSize: "14px" }} />}
                    iconPosition="right"
                    onClick={() => { }}
                    type="secondary"
                />
            ),
            table: <AohTable
                tableData={
                    (minDevTableTimePeriod === "All" || minDevTableTimePeriod === "Overdue")
                        ? (minDevTableTimePeriod === "All" ? CAPAtableDataAll : minDevTableTimePeriod === "Overdue" ? CAPAtableDataOverdue : CAPAtableDataComingDue) : (minDevTableTimePeriod === "Aging" ? CAPAtableDataAging : CAPAtableDataOpen)}
                tableColumns={CapaStatusColumns} />,
            title3: "CAPA EV",
            radioFilters3: (
                <RadioFilters
                    timePeriod={minDevTable2TimePeriod}
                    setTimePeriod={setMinDevTable2TimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            button3: (
                <Button
                    label="Download"
                    icon={
                        <DownloadOutlined style={{ color: "#0063C3", fontSize: "14px" }} />
                    }
                    iconPosition="right"
                    onClick={() => { }}
                    type="secondary"
                />
            ),
            table3: <AohTable
                tableData={
                    (minDevTable2TimePeriod === "All" || minDevTable2TimePeriod === "Overdue")
                        ? (minDevTable2TimePeriod === "All" ? CAPAEVtableDataAll : minDevTable2TimePeriod === "Overdue" ? CAPAEVtableDataOverdue : CAPAEVtableDataComingDue) : (minDevTable2TimePeriod === "Aging" ? CAPAEVtableDataAging : CAPAEVtableDataOpen)}
                tableColumns={CapaEVColumns} />
        },
    }

    return (
        <div className="quality-tier-main">
            <div className="tier-container">
                <h2 className="tier-title">
                    {/* Summary card */}
                    {qualityDeepDive[activeKeyDeepDiveTab]?.summaryTitle}
                </h2>
            </div>
            {qualityDeepDive[activeKeyDeepDiveTab]?.summary}
            <div
                className={
                    qualityDeepDive[activeKeyDeepDiveTab]?.summary ? "mr-bottom-20" : ""
                }
            ></div>
            {/* Heatmap Card */}
            <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.heatmapTitle}
                </h2>
            </div>
            {/* main heatmap component */}
            {qualityDeepDive[activeKeyDeepDiveTab]?.heatmap}
            <div
                className={
                    qualityDeepDive[activeKeyDeepDiveTab]?.heatmap ||
                        qualityDeepDive[activeKeyDeepDiveTab]?.table
                        ? "mr-bottom-20"
                        : ""
                }
            ></div>
            {/* Adherence Graph */}
            <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.titleAdherence}
                </h2>
            </div>
            {qualityDeepDive[activeKeyDeepDiveTab]?.graphRadioFiltersAdherence}
            {graphTimePeriod === "MTD" ? <div>  {qualityDeepDive[activeKeyDeepDiveTab]?.stackBarAdMtd}</div> : <div>  {qualityDeepDive[activeKeyDeepDiveTab]?.stackBarAd6m}</div>}
            {/* Aging Graph */}
            <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.titleAging}
                </h2>
            </div>
            {qualityDeepDive[activeKeyDeepDiveTab]?.graphRadioFiltersAging}
            {agingGraphTimePeriod === "MTD" ? <div>  {qualityDeepDive[activeKeyDeepDiveTab]?.stackBarAgingMtd}</div> : <div>  {qualityDeepDive[activeKeyDeepDiveTab]?.stackBarAging6m}</div>}
            <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.title2}
                </h2>
                {qualityDeepDive[activeKeyDeepDiveTab]?.button}
            </div>

            <div className="tier-subtitle">
                {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle}
            </div>
            {qualityDeepDive[activeKeyDeepDiveTab]?.radioFilters}
            {qualityDeepDive[activeKeyDeepDiveTab]?.table}
            <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.title3}
                </h2>
                {qualityDeepDive[activeKeyDeepDiveTab]?.button3}
            </div>
            <div className="tier-subtitle">
                {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle3}
            </div>
            {qualityDeepDive[activeKeyDeepDiveTab]?.radioFilters3}
            {qualityDeepDive[activeKeyDeepDiveTab]?.table3}
        </div>
    );
};
export default QualityTier3
