// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ColorCodedTable_customTable__G58at table {
    padding-left: 14px !important;
}

.ColorCodedTable_customTable__G58at .ant-table-tbody >tr >td {
    padding: 0;
    height: 40px;
    border-bottom: none;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}


.ColorCodedTable_customTable__G58at .ant-table-tbody >tr >td.cost-center-column {
    padding-left: 8px;
}

.ColorCodedTable_customTable__G58at .ant-table-tbody >tr >td.cost-center-column {
    padding-left: 8px;
}

.ColorCodedTable_customTable__G58at .ant-table-tbody >tr >td.manager-column {
    padding-left: 8px;
}

.ColorCodedTable_customTable__G58at .ant-table-thead >tr>th {
    padding: 10px;
    padding-left: 8px !important;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/table/ColorCodedTable.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,gCAAgC;IAChC,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;;AAGA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,4BAA4B;IAC5B,gCAAgC;IAChC,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".customTable :global(table) {\n    padding-left: 14px !important;\n}\n\n.customTable :global(.ant-table-tbody >tr >td) {\n    padding: 0;\n    height: 40px;\n    border-bottom: none;\n    font-family: 'Inter', sans-serif;\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 18px;\n}\n\n\n.customTable :global(.ant-table-tbody >tr >td.cost-center-column) {\n    padding-left: 8px;\n}\n\n.customTable :global(.ant-table-tbody >tr >td.cost-center-column) {\n    padding-left: 8px;\n}\n\n.customTable :global(.ant-table-tbody >tr >td.manager-column) {\n    padding-left: 8px;\n}\n\n.customTable :global(.ant-table-thead >tr>th) {\n    padding: 10px;\n    padding-left: 8px !important;\n    font-family: 'Inter', sans-serif;\n    font-weight: 700;\n    font-size: 10px;\n    line-height: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customTable": `ColorCodedTable_customTable__G58at`
};
export default ___CSS_LOADER_EXPORT___;
