import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "store";
import {
  ConfigProvider,
  Select,
  Timeline,
  Table,
  Empty,
  Popover,
  Tag,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Loader } from "@gitlab-rtsensing/component-library";
import { fetchOneClickReport } from "reducers/oneClickReportReducer";
import AIGeneratedIcon from "assets/icons/AIGeneratedIcon";
import {
  sqdipOptions,
  timeOptions,
  tierOptions,
  tierLabelMappping,
} from "./constants";
import "./index.scss";

function OneClickReport() {
  const dispatch = useDispatch<AppDispatch>();
  const { oneClickReportInfo, isOneClickLoading } = useSelector(
    (state: RootState) => state.oneClickReport
  );
  const [selectedSqdip, setSelectedSqdip] = useState("all");
  const [selectedTime, setSelectedTime] = useState("last_month");
  const [selectedTier, setSelectedTier] = useState("tier0");

  useEffect(() => {
    const params = {
      site: "aoh",
      tier: selectedTier,
      time: selectedTime,
      sqdip: selectedSqdip,
    };
    dispatch(fetchOneClickReport(params));
  }, [selectedSqdip, selectedTime, selectedTier]);

  const filterDropdown = (
    label: string,
    options: any,
    selectedValue: string,
    setSelectedValue: any
  ) => {
    return (
      <div className="aoh-col-4 d-flex align-items-center">
        <span
          style={{
            marginRight: "10px",
            fontSize: "10px",
            color: "#595959",
            fontWeight: 700,
          }}
        >{`${label}:`}</span>
        <ConfigProvider
          theme={{
            components: {
              Select: {
                fontSize: 12,
              },
            },
          }}
        >
          <Select
            style={{ width: "100%" }}
            options={options}
            onChange={(value) => setSelectedValue(value)}
            value={selectedValue}
          />
        </ConfigProvider>
      </div>
    );
  };

  const timeLineItems = oneClickReportInfo?.data?.map((item: any) => {
    return {
      label: (
        <div style={{ fontWeight: 700 }}>
          <div style={{ color: "#595959" }}>{item?.datetime}</div>
          <div style={{ color: "#8c8c8c" }}>{item?.metric}</div>
          <div style={{ color: "#8c8c8c" }}>{item?.tier_filter}</div>
        </div>
      ),
      children: (
        <>
          <div dangerouslySetInnerHTML={{ __html: item?.summary }}></div>
          <Table
            bordered
            scroll={{ x: "max-content" }}
            columns={item?.columns?.map((column: any) => ({
              title: column?.title,
              dataIndex: column?.dataIndex,
              key: column?.key,
              render: (text: string) =>
                text?.length > 80 ? (
                  <Popover
                    content={text}
                    title=""
                    overlayInnerStyle={{
                      maxHeight: "260px",
                      overflowY: "auto",
                      padding: "10px",
                    }}
                    overlayStyle={{
                      maxWidth: "760px",
                      maxHeight: "260px",
                    }}
                  >
                    {text.slice(0, 80)}...
                  </Popover>
                ) : (
                  text
                ),
            }))}
            dataSource={item?.data}
            pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
            style={{ marginTop: "10px" }}
          />
        </>
      ),
    };
  });

  return (
    <div style={{ marginTop: "200px", width: "100%" }}>
      <div className="aoh-row" style={{ marginTop: "20px" }}>
        {filterDropdown("SQDIP", sqdipOptions, selectedSqdip, setSelectedSqdip)}
        {filterDropdown("TIME", timeOptions, selectedTime, setSelectedTime)}
        {filterDropdown("TIER", tierOptions, selectedTier, setSelectedTier)}
      </div>
      <div className="d-flex flex-column" style={{ marginTop: "20px" }}>
        <div
          style={{
            fontSize: "12px",
            color: "#595959",
            fontWeight: 700,
            marginBottom: "2px",
          }}
        >
          Summary of alerts generated in last 24 hours:
        </div>
        <Tag
          color="blue"
          style={{
            width: "100%",
            whiteSpace: "break-spaces",
            minHeight: "50px",
          }}
        >
          {isOneClickLoading ? (
            <div className="summary-loader">
              <Loader />
            </div>
          ) : (
            <div className="d-flex">
              <AIGeneratedIcon className="ai-generated-icon" />
              <div
                dangerouslySetInnerHTML={{
                  __html: oneClickReportInfo?.summary,
                }}
              ></div>
            </div>
          )}
        </Tag>
      </div>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ marginTop: "20px" }}
      >
        <h3>Showing results for {tierLabelMappping[selectedTier]}</h3>
        <Button
          type="secondary"
          label="Download"
          className="run-report-btn"
          icon={<DownloadOutlined style={{ fontSize: "14px" }} />}
          iconPosition="right"
        />
      </div>
      {isOneClickLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      ) : oneClickReportInfo?.data?.length === 0 ? (
        <div style={{ marginTop: "120px" }}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      ) : (
        <div style={{ marginTop: "40px" }}>
          <ConfigProvider
            theme={{
              components: {
                Timeline: {
                  fontSize: 12,
                },
                Table: {
                  fontSize: 12,
                },
                Popover: {
                  fontSize: 12,
                },
              },
            }}
          >
            <div className="timeline-container">
              <Timeline mode="left" items={timeLineItems} />
            </div>
          </ConfigProvider>
        </div>
      )}
    </div>
  );
}

export default OneClickReport;
