import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOktaToken } from 'utils/common-methods';

interface EhssDataItem {
    due_date?: string;
    task_name?: string;
    task_code?: string;
    total_due_in_5_days?: string;
    total_due_in_2_days?: string;
    site_compliance_percentage?: string;
}

interface ehssSummaryItem {
    total_due_in_5_days?: string;
    total_due_in_2_days?: string;
}

interface EhssGraphItem {
  compliance?: number | string;
  compliance_date?: string;
  compliance_week?: string;
  cumulative_overdue?: number | string;
  cumulative_on_track?: number | string;
  compliance_rate_percentage?: number | string;
  manager_name?: string;
  overdue?: number | string;
  cost_center_name?: string;
  Compliance_percentage?: number;
  week_start?: string;
  formatted_date?: string;
}

interface EhssDataType {
    data: EhssDataItem[];
    target: string;
    complianceRateData: EhssGraphItem[];
    summary?: ehssSummaryItem[];
}

interface TransformedData {
    ehss: EhssDataType;
}

interface EhssInfoState {
    ehssDeepDiveInfo: TransformedData | null;
    loading: boolean;
    error: boolean;
}

const initialState: EhssInfoState = {
    ehssDeepDiveInfo: null,
    loading: false,
    error: false,
};

interface FetchDataParams {
    workStream?: string | undefined,
    site?: string | undefined,
    persona?: string | undefined,
    tierNumber?: string | undefined,
    shift?: string | undefined,
    line?: string | undefined,
    building?: string | undefined,
}

const fetchEhssDeepDive = createAsyncThunk<EhssDataType, FetchDataParams>(
    'work-center-team/metrics/safety/deep-dive-view/ehss',
    async (params) => {
        const { workStream, site, persona, tierNumber, shift, line, building } = params;
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/work-center-team/metrics/safety/deep-dive-view/ehss?workStream=${workStream}&site=${site}&persona=${persona}&tierNumber=${tierNumber}&line=${line}&shift=${shift}&building=${building}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
            throw new Error('Failed to fetch ehss');
        }
        return await response.json();
    }
);

const transformData = (data: EhssDataType): TransformedData => ({
    ehss: {
        data: data?.data?.map((item) => ({
            due_date: item.due_date ? new Date(item.due_date).toLocaleDateString('en-GB') : "",
            task_name: item.task_name ?? "",
            task_code: item.task_code ?? "",
            site_compliance_percentage: item.site_compliance_percentage ?? "",

        })),
        target: data.target,
        complianceRateData: data?.complianceRateData?.map((item, key) => {
            const complianceDate = item.compliance_date
              ? new Date(item.compliance_date)
              : item.compliance_week
              ? new Date(item.compliance_week)
              : item.week_start
              ? new Date(item.week_start)
              : item.formatted_date? new Date(item.formatted_date): null;
            const formattedComplianceDate = complianceDate ? complianceDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' }) : "";
            return {
                key,
                overdue: item.overdue ?? "",
                compliance_date: formattedComplianceDate,
                cumulative_overdue: item.cumulative_overdue ?? "",
                cumulative_on_track: item.cumulative_on_track ?? "",
                compliance_rate: (item.compliance_rate_percentage ?? item.Compliance_percentage) ?? "",
                manager: item.manager_name?.replace(/ \(\d+\)$/, "") ?? "",
                cost_center_name: item.cost_center_name ?? "",
            };
        }),
        summary: data?.summary,
    }
});

const ehssReducerDeepDive = createSlice({
    name: 'ehssDeepDive',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEhssDeepDive.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchEhssDeepDive.fulfilled, (state, action) => {
                state.loading = false;
                state.ehssDeepDiveInfo = transformData(action.payload);
            })
            .addCase(fetchEhssDeepDive.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });

    },
});

export { fetchEhssDeepDive };

export default ehssReducerDeepDive.reducer;