import { StatusIssueIcon, StatusPositiveIcon, ExpandArrowsIcon, OpsTooltip, OpsTooltipTrigger, OpsTooltipContent } from "@gitlab-rtsensing/component-library";
import { Alert, Card, Empty } from "antd";
import css from './CustomMetricCard.module.css'
import { SvgProps } from "@gitlab-rtsensing/component-library/lib/components/elements/icons";
import Calendar, { CalendarProps } from "../calendar/Calendar";
import SimpleLineGraph from "../charts-graph/simple-line-graph/SimpleLineGraph";
import { useEffect } from "react";
import { actionMap } from "../../../utils/custom-metric-card.helper";
import { callCustomMetricApiWithCurrentParams } from "reducers/customMetricParamsReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
type CustomMetricCardAlert = 'Red' | 'Green' | undefined;

interface CustomMetricCardProps
  extends HeaderProps,
    AlertStatusIconProps,
    CalendarProps {
  children?: React.ReactNode;
  isToolTip?: boolean;
  tooltipContent?: string;
  className?: string;
  isCalendar?: boolean;
  isGrid?: boolean;
  isGraph?: boolean;
  data?: CardDataProps;
  graphData?: GraphDataProps[];
  calendarData?: CardDataProps;
  referenceLine?: number;
  setIsOpenDeepDive?: (isOpen: boolean) => void;
  setActiveKeyDeepDiveTab?: (key: string) => void;
  label?: boolean;
  actionKey?: keyof typeof actionMap;
  tierLabel?: string;
}

interface AlertStatusIconProps extends SvgProps {
    alertType?: "error" | "success"
}

interface CardDataProps {
    [key: string]: any;
}

interface GraphDataProps {
    name?: string;
    value?: number
}

interface CustomAlertCSSProperties extends React.CSSProperties {
    "--card-status-icon-height"?: string;
}

interface HeaderProps {
    title: string
    headerStatusColor?: CustomMetricCardAlert;
    isAlert?: boolean;
    alertType?: "error" | "success"
    setIsOpenDeepDive?: (isOpen: boolean) => void
    setActiveKeyDeepDiveTab?: (key: string) => void
}

const headerStatusIconColor: Record<string, string> = {
    Red: 'var(--ops-sem-red-primary)',
    Green: '#2CC84D',
    Yellow: '#2CC84D'
}

const statusIconSize = {
    width: '16',
    height: '16'
}

const AlertStatusIcon: React.FC<AlertStatusIconProps> = (props) => {
    if (props.alertType === 'error') {
        return (
            <StatusIssueIcon
                fill={headerStatusIconColor.Red}
                height={statusIconSize.height}
                width={statusIconSize.width}
                {...props}
            />
        )
    } else {
        return (
            <StatusPositiveIcon
                fill={headerStatusIconColor.Green}
                height={statusIconSize.height}
                width={statusIconSize.width}
                {...props}
            />
        )
    }
}

const alertStyle: CustomAlertCSSProperties = {
    "--card-status-icon-height": `${statusIconSize.height}px`
};

const Header: React.FC<HeaderProps> = (props) => {
    if (props.isAlert) {
        return (
            <Alert
                style={alertStyle}
                rootClassName={css.customAlert}
                closable={false}
                showIcon
                icon={<AlertStatusIcon alertType={props.alertType} {...props} />}
                message={<span className={css.customHeader}>{props.title}</span>}
                type={props.alertType}
                action={
                    <ExpandArrowsIcon
                        style={{
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            props.setIsOpenDeepDive?.(true);
                            props.setActiveKeyDeepDiveTab?.(props.title);
                        }}
                        height={'16'}
                        width={'16'}
                        {...props}
                    />}
            />)
    } else {
        return (
            <span className={css.customHeader}>{props.title}</span> //icon here
        )
    }
}

const CustomMetricCard: React.FC<CustomMetricCardProps> = (props) => {
    const dispatch = useDispatch<AppDispatch>();
    const { isGraph, isAlert, className, id, title, actionKey, tierLabel } = props; // Destructured at the top
    const customMetricParams = useSelector(
      (state: RootState) => state.customMetricParams
    );

    const tierNumber = customMetricParams?.customMetricParams?.tierNumber;
    let customClassNames = isGraph ? css.customCardBody : ''
    customClassNames = isAlert ? `${css.customCardHead} ${customClassNames} ${className}` : `${className} ${customClassNames} ${css.customCalendarHead}`
    useEffect(() => {
      if (
        customMetricParams &&
        actionKey &&
        tierLabel?.toLocaleLowerCase() === tierNumber
      ) {
        dispatch(callCustomMetricApiWithCurrentParams(actionKey));
      }
    }, [customMetricParams, actionKey, dispatch, tierNumber]);

    return (
        <Card id={id} className={customClassNames} size="small"
            title={<Header title={title} isAlert={props.isAlert} headerStatusColor={props.headerStatusColor} alertType={props.alertType ?? 'success'} setIsOpenDeepDive={props.setIsOpenDeepDive} setActiveKeyDeepDiveTab={props.setActiveKeyDeepDiveTab} />}
        >
            {props.isCalendar && (props.greenDates ?? props.redDates) &&
                <Calendar greenDates={props.greenDates} redDates={props.redDates} greenColor={props.greenColor} redColor={props.redColor} />}
            {props.isGrid && props.data &&
                <div className={css.cardContent}>
                    {Object.entries(props.data)?.map(([key, value], index) => {
                        const keys = key.split("&")
                        
                        if (
                          keys.length > 1 &&
                          props.title === "Minor Deviation"
                        ) {
                          return (
                            <div key={key}>
                              {index !== 0 && index % 3 === 1 && (
                                <hr className={css.breakLine}></hr>
                              )}
                              {
                                <p className={css.cardContentRow}>
                                  <div>
                                    <span className={css.cardContentLabel1}>
                                      {keys[0]}
                                    </span>
                                    <span className={css.cardContentLabel2}>
                                      {keys[1]}
                                    </span>
                                  </div>
                                  {props.alertType === "error" && (
                                    <span
                                      className={
                                        key === "OVERDUE" ||
                                        key === "SHIFT COMPLIANCE %"
                                          ? css.errorValueColor
                                          : css.cardContentValue2
                                      }
                                    >
                                      {value}
                                    </span>
                                  )}
                                  {props.alertType === "success" && (
                                    <span
                                      className={
                                        key === "OVERDUE" ||
                                        key === "SHIFT COMPLIANCE %"
                                          ? css.successValueColor
                                          : css.cardContentValue2
                                      }
                                    >
                                      {value}
                                    </span>
                                  )}
                                </p>
                              }

                              {/* {index % 3 === 1 && <hr className={css.breakLine}></hr>} */}
                            </div>
                          );
                        } else if (props.title === "Right First Time" && Array.isArray(value)) {
                          return (
                            <div key={key}>
                                {(title !== "GXP Training %Compliance" && title !== "EHSS Training Compliance" && title !== "Change Control Status" && title !== "CAPA Status") && key !== 'OPEN DEVIATION' && index !== 0 && index % 3 === 0 && <hr className={css.breakLine}></hr>}
                              {index !== 0 && <>
                                <hr className={css.breakLine}></hr>
                              </>}
                              <h4 className={css.pdla}>{String(key).toUpperCase()}</h4>
                              {value?.length > 0 && value?.map((val: any,i:number) => {
                                return  <p key={`${i}_${val}`} className={css.cardContentRow}>
                                    <span className={css.cardContentLabel}>{val?.targetbatch || val?.line}</span>
                                    {props.alertType === 'error' && <span className={css.cardContentValue}>{val?.deviation_record_count || val?.open_exp}</span>}
                                    {props.alertType === 'success' && <span className={css.cardContentValue}>{val?.deviation_record_count || val?.open_exp}</span>}
                                  </p>
                              })}
                            </div>
                          );
                        }
                      return (
                        <div key={key}>
                                {(title !== "GXP Training %Compliance" && title !== "EHSS Training Compliance" && title !== "Change Control" && title !== "CAPA Status") && key !== 'OPEN DEVIATION' && index !== 0 && index % 3 === 0 && <hr className={css.breakLine}></hr>}
                                {index === 1 && props.label && <>
                                    <hr className={css.breakLine}></hr>
                                    <h4 className={css.pdla}>PENDING LEARNING ACTIVITIES</h4>
                                </>}
                                <p className={css.cardContentRow}>
                                    <span className={css.cardContentLabel}>{key}</span>
                                    {props.alertType === 'error' && <span className={(key.toLowerCase() === 'overdue' || key === 'SHIFT COMPLIANCE %') ? css.errorValueColor : css.cardContentValue}>{value}</span>}
                                    {props.alertType === 'success' && <span className={(key.toLowerCase() === 'overdue' || key === 'SHIFT COMPLIANCE %') ? css.successValueColor : css.cardContentValue}>{value}</span>}
                                </p>
                                {/* {(key === 'TARGET' || key === 'PENDING 1') && index % 3 === 1 && <hr className={css.breakLine}></hr>} */}
                            </div>
                        )
                    }
                    )}
                </div>}
            {JSON.stringify(props.data) === '{}' && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

            {props.isGraph && props.graphData && props.graphData?.length > 0 &&<OpsTooltip placement="right">
                <OpsTooltipTrigger>

                    <SimpleLineGraph data={props.graphData} targetReferenceValue={props.referenceLine} tipContent={props.tooltipContent} />
                </OpsTooltipTrigger>
                <OpsTooltipContent className={css.tipContainer}>
                    <div className={css.tipHeaderDesign}>Trend Graph</div>
                    <div className={css.tipContentDesign}>{props.tooltipContent}</div>
                </OpsTooltipContent>
            </OpsTooltip>}

        </Card >
    )
}

export default CustomMetricCard
