import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BaseViewCardPropsType, CardPropsType, FetchDataParams, QueryParamsPropsType } from 'types/wctTypes';
import { filterParams, getMonthName, getOktaToken } from 'utils/common-methods';
import { MINOR_DEVIATION_URL } from 'utils/constants'
import { StringKeyAnyDataProps, StringKeyDataProps } from 'utils/data-types';

interface minorDeviationSummaryItem {
    open_deviation_last_24h?: number | string;
    unclosed_deviations?: number | string;
    avg_ageing?: number | string;
    overdue?: number | string;
    aging_open_percentage?: number | string;
    open_deviation?: number | string;
    mtd_deviation_ad_percentage?: number | string;
    mtd_aging_deviation_percentage?: number | string;
}

interface minorDeviationTableItem {
    record_number?: number | string;
    area?: number | string;
    type?: number | string;
    age_unclosed_deviations?: number | string;
}

interface minorDeviationGraphItem {
    Month?: number | string;
    Year?: number | string;
    deviation_percentage_6m?: number | string;
}

interface minorDeviationDataType {
    summary: minorDeviationSummaryItem[]
    tableData: minorDeviationTableItem[]
    graphData: minorDeviationGraphItem[]
    query_params: QueryParamsPropsType
}

interface minorDeviation {
    Month?: number | string;
    Year?: number | string;
    deviation_percentage_6m?: number | string;
}

interface minorDeviationInfoState {
    minorDeviationInfo: BaseViewCardPropsType | null;
    loading: boolean;
    error: boolean;
}

const initialState: minorDeviationInfoState = {
    minorDeviationInfo: null,
    loading: false,
    error: false
}

const fetchMinorDeviation = createAsyncThunk<minorDeviationDataType, FetchDataParams>(
    MINOR_DEVIATION_URL,
    async (params) => {
        const filteredParams = filterParams(params)
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/${MINOR_DEVIATION_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })

        if (!response.ok) {
            throw new Error('Failed to fetch Minor Deviation')
        }
        return await response.json()
    }
)

const minorDevConvertData = (data: minorDeviation[]): StringKeyDataProps[] => {
    const sortedData = [...data].sort((a, b) => {
        if (typeof a.Month === "number" && typeof b.Month === "number") {
            return a.Month - b.Month;
        } else {
            return Number(a.Month) - Number(b.Month);
        }
    });
    return sortedData.map((item) => {
        const monthNumber =
            typeof item.Month === "number" ? item.Month : Number(item.Month);
        const value =
            typeof item.deviation_percentage_6m === "number"
                ? item.deviation_percentage_6m.toFixed(2)
                : "0.00";

        const monthName = getMonthName(monthNumber - 1);
        return { name: monthName, value: parseFloat(value) };
    });
}

const transformData = (minorDeviationInfo: minorDeviationDataType): BaseViewCardPropsType => {
    const minorDeviationBaseViewData: BaseViewCardPropsType = {
        cardData: {},
        graphData: [],
        graphParams: {},
        cardProps: {
            alertType: 'success'
        }
    }

    const tierLabel = minorDeviationInfo.query_params?.tierLabel

    const MinorDeviationSanitizedData: StringKeyDataProps = {};
    let openDeviationLast24;
    let unclosedDeviations;
    let avgAgeing;
    let overdue;
    let agingOpenPercentage;
    let openDeviation;
    let mtdDeviationAdPercentage;
    let mtdAgingDeviationPercentage;
    let recordNumber;
    let area;
    let type;
    let ageUnclosedDeviations;
    if (minorDeviationInfo?.summary?.length) {
        openDeviationLast24 =
            minorDeviationInfo?.summary[0]?.open_deviation_last_24h;
        unclosedDeviations = minorDeviationInfo?.summary[0]?.unclosed_deviations;
        avgAgeing = minorDeviationInfo?.summary[0]?.avg_ageing;
        overdue = minorDeviationInfo?.summary[0]?.overdue;
        agingOpenPercentage =
            minorDeviationInfo?.summary[0]?.aging_open_percentage;
        openDeviation = minorDeviationInfo?.summary[0]?.open_deviation;
        mtdDeviationAdPercentage =
            minorDeviationInfo?.summary[0]?.mtd_deviation_ad_percentage;
        mtdAgingDeviationPercentage =
            minorDeviationInfo?.summary[0]?.mtd_aging_deviation_percentage;
    }

    if (typeof openDeviationLast24 === "number" && openDeviationLast24 >= 0) {
        MinorDeviationSanitizedData["NEW OPEN LAST 24 HOURS"] =
            openDeviationLast24;
    }

    if (minorDeviationInfo?.tableData?.length) {
        recordNumber = minorDeviationInfo?.tableData[0]?.record_number;
        area = minorDeviationInfo?.tableData[0]?.area;
        type = minorDeviationInfo?.tableData[0]?.type;
        ageUnclosedDeviations =
            minorDeviationInfo?.tableData[0]?.age_unclosed_deviations;
    }

    minorDeviationInfo?.tableData?.forEach((item, index) => {
        if (typeof item.record_number === "string") {
            const area =
                typeof item.area === "string" ? item.area.toUpperCase() : `${item.area}`;
            MinorDeviationSanitizedData[item.record_number + "&" + area] =
                item.type ?? '';
        }
    });

    if (typeof unclosedDeviations === "number" && unclosedDeviations >= 0) {
        MinorDeviationSanitizedData["OPEN DEVIATIONS"] = unclosedDeviations;
    }
    if (typeof avgAgeing === "number" && avgAgeing >= 0) {
        MinorDeviationSanitizedData["AVG AGING"] = avgAgeing;
    }
    if (typeof overdue === "number" && overdue >= 0) {
        MinorDeviationSanitizedData.OVERDUE = overdue;
    }
    if (typeof agingOpenPercentage === "number" && agingOpenPercentage >= 0) {
        MinorDeviationSanitizedData["AGING OPEN %"] = agingOpenPercentage;
    }
    if (typeof openDeviation === "number" && openDeviation >= 0) {
        MinorDeviationSanitizedData["OPEN DEVIATION"] = openDeviation;
    }
    if (
        typeof mtdDeviationAdPercentage === "number" &&
        mtdDeviationAdPercentage >= 0
    ) {
        MinorDeviationSanitizedData["MTD DEVIATION ADHERENCE %"] =
            mtdDeviationAdPercentage;
    }
    if (
        typeof mtdAgingDeviationPercentage === "number" &&
        mtdAgingDeviationPercentage >= 0
    ) {
        MinorDeviationSanitizedData["MTD AGING DEVIATION %"] =
            mtdAgingDeviationPercentage;
    }
    minorDeviationBaseViewData.cardData = MinorDeviationSanitizedData
    if (tierLabel?.toLowerCase() === "tier 3") {
        minorDeviationBaseViewData.graphParams.tooltipContent = "% of Minor Deviations closed meeting target due date in last 6 months"
    } else {
        minorDeviationBaseViewData.graphParams.tooltipContent = ""
    }

    if (
        tierLabel?.toLowerCase() === "tier 0" &&
        typeof openDeviationLast24 === "number" &&
        openDeviationLast24 > 0
    ) {
        minorDeviationBaseViewData.cardProps.alertType = "error"
    } else if (
        tierLabel?.toLowerCase() === "tier 1" &&
        typeof unclosedDeviations === "number" &&
        unclosedDeviations > 0
    ) {
        minorDeviationBaseViewData.cardProps.alertType = "error"
    } else if (
        tierLabel?.toLowerCase() === "tier 2" &&
        typeof overdue === "number" &&
        overdue > 0
    ) {
        minorDeviationBaseViewData.cardProps.alertType = "error"
    } else if (
        tierLabel?.toLowerCase() === "tier 3" &&
        typeof mtdDeviationAdPercentage === "number" &&
        mtdDeviationAdPercentage <= 95
    ) {
        minorDeviationBaseViewData.cardProps.alertType = "error"
    } else {
        minorDeviationBaseViewData.cardProps.alertType = "success"
    }

    if (
        tierLabel?.toLowerCase() === "tier 3" &&
        minorDeviationInfo?.graphData?.length
    ) {
        const inputData1: minorDeviation[] = minorDeviationInfo?.graphData;
        const outputData: StringKeyDataProps[] = minorDevConvertData(inputData1);
        minorDeviationBaseViewData.graphData = outputData
        minorDeviationBaseViewData.graphParams.isGraph = true
    } else {
        minorDeviationBaseViewData.graphData = []
        minorDeviationBaseViewData.graphParams.isGraph = false
    }

    return minorDeviationBaseViewData
}

const minorDeviationReducer = createSlice({
    name: 'minorDeviation',
    initialState,
    reducers: {
        clearMinorDeviationData(state) {
            state.minorDeviationInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMinorDeviation.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchMinorDeviation.fulfilled, (state, action) => {
                state.loading = false;
                state.minorDeviationInfo = transformData(action.payload)
            })
            .addCase(fetchMinorDeviation.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export { fetchMinorDeviation };

export const { clearMinorDeviationData } = minorDeviationReducer.actions

export default minorDeviationReducer.reducer;