import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { FetchDataParams, GraphParamsType } from 'types/wctTypes'
import { filterParams, getMonthName, getOktaToken, setData, setDataKeys } from 'utils/common-methods'
import { QUALITY_RFT_URL } from 'utils/constants'
import { StringKeyAnyDataProps, StringKeyDataProps } from 'utils/data-types'

interface RFTType {
    card_data?: StringKeyDataProps
    deviations_over_target?: StringKeyDataProps[]
    pending_exception?: StringKeyDataProps[]
    mtd_graph_data?: StringKeyDataProps[]
    trend_graph_data?: StringKeyDataProps[]
    graph_params?: StringKeyAnyDataProps
}

interface RFTDataType {
    cardData: StringKeyDataProps
    cardProps: CardProps
    mtdGraphData?: StringKeyDataProps[]
    trendGraphData?: StringKeyDataProps[]
    graphParams?: GraphParamsType
}

interface CardProps {
    alertType: "error" | "success"
}
interface RFTInfoStateType {
    qualityRFTInfo: RFTDataType | null
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: boolean
}

const initialState: RFTInfoStateType = {
    qualityRFTInfo: null,
    status: 'idle',
    error: false,
}

const fetchQualityRFT = createAsyncThunk<RFTType, FetchDataParams>(
    QUALITY_RFT_URL,
    async (params) => {
        const filteredParams = filterParams(params)

        const token: string = getOktaToken()
        const response = await fetch(`${process.env.REACT_APP_API_URL}${QUALITY_RFT_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })

        if (!response.ok) {
            throw new Error('Failed to fetch RFT')
        }
        return await response.json()
    }
)

const transformData = (RFT: RFTType): RFTDataType => {
    const qualityRFTInfo: RFTDataType = {
        cardData: {},
        cardProps: {
            alertType: 'success'
        },
        mtdGraphData: [],
        trendGraphData: [],
        graphParams: {
            isGraph: false,
            tooltipContent: '',
            yAxisUnit: ''
        }
    }

    qualityRFTInfo.cardData = setData(RFT.card_data ? RFT.card_data : RFT)

    qualityRFTInfo.mtdGraphData = RFT.mtd_graph_data?.map(item => ({
        name: item?.date,
        value: item?.batch_percent
    }))

    qualityRFTInfo.trendGraphData = RFT.trend_graph_data?.map(item => ({
        name: getMonthName(item.month as number),
        value: item?.batch_percent
    }))

    qualityRFTInfo.graphParams = setDataKeys(RFT.graph_params ?? {})

    return qualityRFTInfo
}

const qualityRFTSlice = createSlice({
    name: 'qualityRFT',
    initialState,
    reducers: {
        clearQualityRFTData(state) {
            state.qualityRFTInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchQualityRFT.pending, (state) => {
                state.status = 'loading'
                state.error = false
            })
            .addCase(fetchQualityRFT.fulfilled, (state, action: PayloadAction<RFTType>) => {
                state.status = 'succeeded'
                state.qualityRFTInfo = transformData(action.payload)
            })
            .addCase(fetchQualityRFT.rejected, (state) => {
                state.status = 'failed'
                state.error = true
            })
    }
})

export { fetchQualityRFT }

export const { clearQualityRFTData } = qualityRFTSlice.actions

export default qualityRFTSlice.reducer