import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CardPropsType, FetchDataParams, QueryParamsPropsType } from 'types/wctTypes';
import { filterParams, getOktaToken } from 'utils/common-methods';
import { WORK_ORDER_URL } from 'utils/constants';
import { StringKeyAnyDataProps, StringKeyDataProps } from 'utils/data-types';

interface WorkOrderDataItem {
    overdue?: number | string;
    issued_last_24_hours?: number | string;
}

interface WorkOrderDataType {
    card_data: WorkOrderDataItem;
    query_params: QueryParamsPropsType
}

interface WorkOrderDataPropsType {
    cardData: StringKeyDataProps
    graphData: StringKeyDataProps[]
    graphParams: StringKeyAnyDataProps
    cardProps: CardPropsType
}

interface workOrderInfoState {
    workOrderInfo: WorkOrderDataPropsType | null;
    loading: boolean;
    error: boolean;
}

const initialState: workOrderInfoState = {
    workOrderInfo: null,
    loading: false,
    error: false,
}

const fetchWorkOrder = createAsyncThunk<WorkOrderDataType, FetchDataParams>(
    WORK_ORDER_URL,
    async (params) => {
        const filteredParams = filterParams(params)
        const token: string = getOktaToken();

        const response = await fetch(`${process.env.REACT_APP_API_URL}${WORK_ORDER_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch Work Orders');
        }
        return await response.json();
    }
);

const transformData = (workOrderData: WorkOrderDataType): WorkOrderDataPropsType => {
    const workOrderBaseViewData: WorkOrderDataPropsType = {
        cardData: {},
        graphData: [],
        graphParams: {},
        cardProps: {
            alertType: 'success'
        }
    }

    const tierLabel = workOrderData.query_params?.tierLabel

    const workOrderSanitizedData: StringKeyDataProps = {};
    const workOrderOverDue = workOrderData.card_data?.overdue;
    const workOrderIssuedLast24Hours =
        workOrderData.card_data?.issued_last_24_hours;

    if (typeof workOrderOverDue === "number" && workOrderOverDue >= 0) {
        workOrderSanitizedData.OVERDUE = workOrderOverDue;
    }

    if (
        typeof workOrderIssuedLast24Hours === "number" &&
        workOrderIssuedLast24Hours >= 0
    ) {
        workOrderSanitizedData["ISSUED LAST 24 HOURS"] =
            workOrderIssuedLast24Hours;
    }

    workOrderBaseViewData.cardData = workOrderSanitizedData

    if (
        (tierLabel?.toLowerCase() === "tier 2" ||
            tierLabel?.toLowerCase() === "tier 3") &&
        typeof workOrderOverDue === "number" &&
        workOrderOverDue > 0
    ) {
        workOrderBaseViewData.cardProps.alertType = "error"
    } else {
        workOrderBaseViewData.cardProps.alertType = "success"
    }

    return workOrderBaseViewData
}

const workOrderReducer = createSlice({
    name: 'workOrder',
    initialState,
    reducers: {
        clearworkOrderData(state) {
            state.workOrderInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWorkOrder.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchWorkOrder.fulfilled, (state, action) => {
                state.loading = false;
                state.workOrderInfo = transformData(action.payload);
            })
            .addCase(fetchWorkOrder.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });

    },
});

export { fetchWorkOrder };

export const { clearworkOrderData } = workOrderReducer.actions

export default workOrderReducer.reducer;