import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOktaToken } from 'utils/common-methods';
 
interface EhssDataItem {
    overdue?: number | string;
    manager_name?: string;
    manager_staff_id?: string;
    pending?: number | string;
    target?: number | string;
    la_name?: string;
    la_code?: string;
    cost_center_name?: number | string;
    cost_center_code?: number | string;
}

interface ehssCompliancePercentage {
    compliance_percentage?: number | string;
    
    shift_compliance?: number | string;
    cost_center_compliance?: number | string;
}
interface ehssSiteCompliance {
    aoh_compliance?: number | string;
}
 
interface EhssGraphItem {
    compliance_date?: string;
    cumulative_overdue?: number | string;
    cumulative_on_track?: number | string;
    compliance_rate?: number | string;
}
 
interface EhssDataType {
    compliance_percentage: ehssCompliancePercentage[];
    site_compliance: ehssSiteCompliance[];
    data: EhssDataItem[];
    target: string;
    graph_data: EhssGraphItem[];
}
  
interface EhssInfoState {
    ehssInfo: EhssDataType | null;
    loading: boolean;
    error: boolean;
}
 
const initialState: EhssInfoState = {
    ehssInfo: null,
    loading: false,
    error: false,
}

interface FetchDataParams {
    workStream?: string | undefined,
    site?: string | undefined,
    persona?: string | undefined,
    tierNumber?: string | undefined,
    shift?: string | undefined,
    costCenter?: string | undefined,
    line?: string | undefined,
    building?: string | undefined
}

const fetchEhss = createAsyncThunk<EhssDataType, FetchDataParams>(
    '/work-center-team/metrics/safety/base-view/ehss?workStream=manufacturing&site=aoh&persona=wct&tierNumber=tier%200&line=L001&shift=Shift%201&building=AOH1',
    async (params) => {
        const { workStream, site, persona, tierNumber, shift, costCenter, line, building } = params;
        const token: string = getOktaToken();

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/work-center-team/metrics/safety/base-view/ehss?workStream=${workStream}&site=${site}&persona=${persona}&tierNumber=${tierNumber}&line=${line}&shift=${shift}&costCenter=${costCenter}&building=${building}`,
          {
            method: "GET",
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
 
        if (!response.ok) {
            throw new Error('Failed to fetch ehss');
        }
        return await response.json();
    }
);

const ehssReducer = createSlice({
    name: 'ehss',
    initialState,
    reducers: {
        clearEhssData(state) {
            state.ehssInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEhss.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchEhss.fulfilled, (state, action) => {
                state.loading = false;
                state.ehssInfo = action.payload;
            })
            .addCase(fetchEhss.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
 
    },
});
 
export { fetchEhss };

export const { clearEhssData } = ehssReducer.actions
 
export default ehssReducer.reducer;