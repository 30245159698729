import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { Empty } from "antd";
import Label from "components/resuable/label";

export interface DataItem {
  date: string
  serious?: number
  potentiallySerious?: number
  major?: number
  minor?: number
  atRiskBehavior?: number
  atRiskCondition?: number
  safeBehavior?: number
  nonEHSIssue?: number
  aging?: number
  adherence?: number
  trends?: number
  last_7d_date?: string
  Month?: number
  Year?: number
  open_aging_count_7d?: number
  open_count_7d?: number
  mtd_date?: string
  not_on_time_review_mtd?: number
  on_time_review_mtd?: number
  deviation_percentage_mtd?: number
  deviation_percentage_6m?: number
  not_on_time_review_6m?: number
  on_time_review_6m?: number
  aging_open_percentage_6m?: number
  open_aging_count_6m?: number
  open_count_6m?: number
  open_count_mtd?: number
  open_aging_count_mtd?: number
  aging_open_percentage_mtd?: number
  coming_due_last_7d_count?: number
  aging_last_7days?: number
  normal_last_7days?: number
  compliance_date?: string
  compliance_rate?: number
  not_on_time_cc?: number
  on_time_cc?: number
  aging_cc?: number
  non_aging_cc?: number
  non_aging_flag?: number
  aging_flag?: number
  coming_due?: number
  on_time?: number
  not_on_time?: number
  aging_percentage?: number
  non_aging?: number
}

interface StackedBarChartProps {
  data: DataItem[]
  timePeriod?:
  | "Last Week"
  | "Last Month"
  | "Last 3 Months"
  | "MTD"
  | "Last6Months";
  chartType: "see-cards" | "inca" | "minor-deviation" | "minor-deviation-adherence-mtd" | "minor-deviation-adherence-6m" | "minor-deviation-aging-mtd" | "minor-deviation-aging-6m"
  | "major-deviation" | "major-deviation-adherence-mtd" | "major-deviation-adherence-6m" | "major-deviation-aging-mtd" | "major-deviation-aging-6m" | "change-control-status"
  | "change-control-adherence-6m" | "change-control-aging-mtd" | "change-control-aging-6m" | "change-control-adherence-mtd" | "capa-status" | 
  "capa-status-mtd" | "capa-status-adherence-6m" | "capa-status-aging-mtd" | "capa-status-aging-6m"
}

interface LegendProps {
  statusValue: string;
  circleColor: string;
}

const COLORS = {
  serious: "#E01029",
  potentiallySerious: "#FDB81E",
  major: "#0063C3",
  minor: "#9FE7E2",
  atRiskBehavior: "#0063C3",
  atRiskCondition: "#FDB81E",
  safeBehavior: "#2FBCB6",
  nonEHSIssue: "#0063C3",
  aging: "#E01029",
  adherence: "#0063C3",
  trends: "#FDB81E",
};

const excludedKeys = ['last_7d_date', 'Month', 'Year', 'deviation_percentage_6m',
  'deviation_percentage_mtd', 'mtd_date', 'aging_open_percentage_6m', 'aging_open_percentage_mtd', 'compliance_date', 'compliance_rate', 'aging_percentage'];

const StackedBarChart: React.FC<StackedBarChartProps> = ({
  data,
  chartType,
  timePeriod,
}) => {
  const [chartOptions, setChartOptions] = useState({});
  const [isChartReady, setIsChartReady] = useState(false);

  const nameStyleInfo = {
    fontSize: 7,
    fontWeight: 700,
    fontFamily: "Inter",
    lineHeight: 14,
    color: "#595959",
    align: "center",
    verticalAlign: "middle",
    rotate: 90,
  };
  function formatMonthYear(item: DataItem): string {
    const month = item.Month?.toString().padStart(2, "0") ?? "01";
    const year = item.Year?.toString().slice(-2) ?? "24";
    return `${month}/${year}`;
  }

  function formatMTDDate(item: DataItem): string | undefined {
    const date = item.mtd_date ?? item.last_7d_date ?? item.date ?? item.compliance_date

    if (date) {
      const dateObj = new Date(date);
      const day = String(dateObj.getDate()).padStart(2, "0");
      const month = String(dateObj.getMonth() + 1).padStart(2, "0");
      return `${month}/${day}`;
    }
    return undefined;
  }
  function minMaxValue(items: DataItem[]): any {
    if (!items || items.length === 0) {
      return undefined;
    }
    const excludedKeys = ['last_7d_date', 'Month', 'Year'];
    const numericValues: number[] = items.flatMap(item =>
      Object.entries(item)
        .filter(
          ([key, value]) =>
            !excludedKeys.includes(key) &&
            typeof value === 'number' &&
            value !== null &&
            value !== undefined
        )
        .map(([_, value]) => value as number)
    );

    const minValue = Math.min(...numericValues);
    let maxValue = Math.max(...numericValues);
    if (numericValues.length === 0) {
      return { Min: 0, Max: 100 };
    } else if (maxValue === 0) {
      return { Min: minValue, Max: 100 }
    }
    const padding = 0.5 * maxValue;
    maxValue += padding;

    return { Min: minValue, Max: maxValue };
  }

  function newMinMaxValue(items: DataItem[]): any {
    if (!items || items.length === 0) {
      return undefined;
    }
    
    const numericValues: number[] = items.flatMap(item =>
      Object.entries(item)
        .filter(
          ([key, value]) =>
            !excludedKeys.includes(key) &&
            typeof value === 'number' &&
            value !== null &&
            value !== undefined
        )
        .map(([_, value]) => value as number)
    );

    const minValue = Math.min(...numericValues);
    let maxValue = Math.max(...numericValues);
    if (numericValues.length === 0) {
      return { Min: 0, Max: 100 };
    } else if (maxValue === 0) {
      return { Min: minValue, Max: 100 }
    }
    
    maxValue = Math.ceil(maxValue / 5) * 5

    return { Min: minValue, Max: maxValue };
  }

  const minMaxValueVar = minMaxValue(data)
  const newMinMaxValueVar = newMinMaxValue(data)

  const getSeriesData = () => {
    if (!data) return [];
    if (chartType === "see-cards") {
      return [
        {
          name: "Serious",
          data: data.map((d) => d.serious ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: COLORS.serious },
        },

        {
          name: "Potentially Serious",
          data: data.map((d) => d.potentiallySerious ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: COLORS.potentiallySerious },
        },

        {
          name: "Major",
          data: data.map((d) => d.major ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: COLORS.major },
        },

        {
          name: "Minor",
          data: data.map((d) => d.minor ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: COLORS.minor },
        },

        {
          name: "Non EHS Issue",
          data: data.map((d) => d.nonEHSIssue ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: COLORS.nonEHSIssue },
        },
      ];
    } else if (chartType === "minor-deviation-adherence-mtd") {
      return [
        {
          name: "On-Time Minor Deviations",
          data: data?.map((d) => d.on_time_review_mtd ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#0063C3" },
          yAxisIndex: 1
        },
        {
          name: "Not On-Time Minor Deviations",
          data: data?.map((d) => d.not_on_time_review_mtd ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#FDB81E" },
          yAxisIndex: 1
        },
        {
          name: "% of Minor Deviation Adherence",
          data: data?.map((d) => d.deviation_percentage_mtd ?? 0),
          type: "line",
          lineStyle: {
            type: 'dotted'
          },
          itemStyle: { color: "#2FBCB6" },
        },
      ];
    } else if (chartType === "minor-deviation-adherence-6m") {
      return [
        {
          name: "On-Time Minor Deviations",
          data: data?.map((d) => d.on_time_review_6m ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#0063C3" },
          yAxisIndex: 1
        },
        {
          name: "Not On-Time Minor Deviations",
          data: data?.map((d) => d.not_on_time_review_6m ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#FDB81E" },
          yAxisIndex: 1
        },
        {
          name: "% of Minor Deviation Adherence",
          data: data?.map((d) => d.deviation_percentage_6m ?? 0),
          type: "line",
          lineStyle: {
            type: 'dotted'
          },
          itemStyle: { color: "#2FBCB6" },
        },
      ];
    } else if (chartType === "minor-deviation-aging-mtd") {
      return [
        {
          name: "Non-Aging Minor Deviations",
          data: data?.map((d) => d.open_count_mtd ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#0063C3" },
          yAxisIndex: 1
        },
        {
          name: "Aging Minor Deviations",
          data: data?.map((d) => d.open_aging_count_mtd ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#FDB81E" },
          yAxisIndex: 1
        },
        {
          name: "% of Aging Minor Deviations",
          data: data?.map((d) => d.aging_open_percentage_mtd ?? 0),
          type: "line",
          lineStyle: {
            type: 'dotted'
          },
          itemStyle: { color: "#2FBCB6" },
        },
      ];
    } else if (chartType === "minor-deviation-aging-6m") {
      return [
        {
          name: "Non-Aging Minor Deviations",
          data: data?.map((d) => d.open_count_6m ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#0063C3" },
          yAxisIndex: 1
        },
        {
          name: "Aging Minor Deviations",
          data: data?.map((d) => d.open_aging_count_6m ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#FDB81E" },
          yAxisIndex: 1
        },
        {
          name: "% of Aging Minor Deviations",
          data: data?.map((d) => d.aging_open_percentage_6m ?? 0),
          type: "line",
          lineStyle: {
            type: 'dotted'
          },
          itemStyle: { color: "#2FBCB6" },
        },
      ];
    } else if (chartType === "minor-deviation") {
      return [
        {
          name: "Non-Aging Minor Deviations",
          data: data?.map((d) => d.open_count_7d ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#0063C3" },
        },
        {
          name: "Aging Minor Deviations",
          data: data?.map((d) => d.open_aging_count_7d ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#FDB81E" },
        },
        {
          name: "Trend of daily Coming Due Minor Deviations",
          data: data?.map((d) => d.coming_due_last_7d_count ?? 0),
          barWidth: 16,
          type: "line",
          lineStyle: {
            type: "dotted",
          },
          stack: "total",
          itemStyle: { color: "#2FBCB6" },
        },
      ];
    } else if (chartType === "major-deviation-adherence-mtd") {
      return [
        {
          name: "On-Time Major Deviations",
          data: data?.map((d) => d.on_time_review_mtd ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#0063C3" },
          yAxisIndex: 1
        },
        {
          name: "Not On-Time Major Deviations",
          data: data?.map((d) => d.not_on_time_review_mtd ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#FDB81E" },
          yAxisIndex: 1
        },
        {
          name: "% of Major Deviation Adherence",
          data: data?.map((d) => d.deviation_percentage_mtd ?? 0),
          type: "line",
          lineStyle: {
            type: 'dotted'
          },
          itemStyle: { color: "#2FBCB6" },
        },
      ];
    } else if (chartType === "major-deviation-adherence-6m") {
      return [
        {
          name: "On-Time Major Deviations",
          data: data?.map((d) => d.on_time_review_6m ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#0063C3" },
          yAxisIndex: 1
        },
        {
          name: "Not On-Time Major Deviations",
          data: data?.map((d) => d.not_on_time_review_6m ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#FDB81E" },
          yAxisIndex: 1
        },
        {
          name: "% of Major Deviation Adherence",
          data: data?.map((d) => d.deviation_percentage_6m ?? 0),
          type: "line",
          lineStyle: {
            type: 'dotted'
          },
          itemStyle: { color: "#2FBCB6" },
        },
      ];
    } else if (chartType === "major-deviation-aging-mtd") {
      return [
        {
          name: "Non-Aging Major Deviations",
          data: data?.map((d) => d.open_count_mtd ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#0063C3" },
          yAxisIndex: 1
        },
        {
          name: "Aging Major Deviations",
          data: data?.map((d) => d.open_aging_count_mtd ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#FDB81E" },
          yAxisIndex: 1
        },
        {
          name: "% of Aging Major Deviations",
          data: data?.map((d) => d.aging_open_percentage_mtd ?? 0),
          type: "line",
          lineStyle: {
            type: 'dotted'
          },
          itemStyle: { color: "#2FBCB6" },
        },
      ];
    } else if (chartType === "major-deviation-aging-6m") {
      return [
        {
          name: "Non-Aging Major Deviations",
          data: data?.map((d) => d.open_count_6m ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#0063C3" },
          yAxisIndex: 1
        },
        {
          name: "Aging Major Deviations",
          data: data?.map((d) => d.open_aging_count_6m ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#FDB81E" },
          yAxisIndex: 1
        },
        {
          name: "% of Aging Major Deviations",
          data: data?.map((d) => d.aging_open_percentage_6m ?? 0),
          type: "line",
          lineStyle: {
            type: 'dotted'
          },
          itemStyle: { color: "#2FBCB6" },
        },
      ];
    } else if (chartType === "major-deviation") {
      return [
        {
          name: "Non-Aging Major Deviations",
          data: data?.map((d) => d.open_count_7d ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#0063C3" },
        },
        {
          name: "Aging Major Deviations",
          data: data?.map((d) => d.open_aging_count_7d ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#FDB81E" },
        },
        {
          name: "Trend of daily Coming Due Major Deviations",
          data: data?.map((d) => d.coming_due_last_7d_count ?? 0),
          barWidth: 16,
          type: "line",
          lineStyle: {
            type: 'dotted'
          },
          stack: "total",
          itemStyle: { color: "#2FBCB6" },
        },
      ];
    } else if (chartType === "change-control-status") {
      return [
        {
          name: "Non-Aging Change Controls",
          data: data?.map((d) => d.normal_last_7days ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#0063C3" },
        },
        {
          name: "Aging Change Controls",
          data: data?.map((d) => d.aging_last_7days ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#FDB81E" },
        },
        {
          name: "Trend of daily Coming Due Change Controls",
          data: data?.map((d) => d.coming_due_last_7d_count ?? 0),
          barWidth: 16,
          type: "line",
          lineStyle: {
            type: 'dotted'
          },
          stack: "total",
          itemStyle: { color: "#2FBCB6" },
        },
      ];
    } else if (chartType === "change-control-adherence-mtd" || chartType === "change-control-adherence-6m") {
      return [
        {
          name: "On-Time Change Controls",
          data: data?.map((d) => d.on_time_cc ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#0063C3" },
          yAxisIndex: 1
        },
        {
          name: "Not On-Time Change Controls",
          data: data?.map((d) => d.not_on_time_cc ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#FDB81E" },
          yAxisIndex: 1
        },
        {
          name: "% of Change Control Adherence",
          data: data?.map((d) => d.compliance_rate ?? 0),
          type: "line",
          lineStyle: {
            type: 'dotted'
          },
          itemStyle: { color: "#2FBCB6" },
        },
      ];
    } else if (chartType === "change-control-aging-mtd" || chartType === "change-control-aging-6m") {
      return [
        {
          name: "Non-Aging Change Controls",
          data: data?.map((d) => d.non_aging_cc ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#0063C3" },
          yAxisIndex: 1
        },
        {
          name: "Aging Change Controls",
          data: data?.map((d) => d.aging_cc ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: "#FDB81E" },
          yAxisIndex: 1
        },
        {
          name: "% of Aging Change Controls",
          data: data?.map((d) => d.compliance_rate ?? 0),
          type: "line",
          lineStyle: {
            type: 'dotted'
          },
          itemStyle: { color: "#2FBCB6" },
        },
      ];
    } else if (chartType === "capa-status") {
      return [
        {
          name: "Non-Aging CAPAs",
          data: data?.map((d) => d.non_aging_flag ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: COLORS.atRiskBehavior },
        },
        {
          name: "Aging CAPAs",
          data: data?.map((d) => d.aging_flag ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: COLORS.potentiallySerious },
        },
        {
          name: "Trend of daily Coming CAPAs",
          data: data?.map((d) => d.coming_due ?? 0),
          type: "line",
          lineStyle: {
            type: 'dotted'
          },
          itemStyle: { color: COLORS.safeBehavior },
          yAxisIndex: 1
        },
      ];
    } else if (chartType === "capa-status-mtd" || chartType === "capa-status-adherence-6m") {
      return [
        {
          name: "On time CAPAs",
          data: data?.map((d) => d.on_time ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: COLORS.atRiskBehavior },
        },
        {
          name: "Not on time CAPAs",
          data: data?.map((d) => d.not_on_time ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: COLORS.atRiskCondition },
        },
        {
          name: "% of CAPA Adherence",
          data: data?.map((d) => d.aging_percentage ?? 0),
          type: "line",
          lineStyle: {
            type: 'dotted'
          },
          itemStyle: { color: COLORS.safeBehavior },
          yAxisIndex: 1
        },
      ];
    } else if (chartType === "capa-status-aging-mtd" || chartType === "capa-status-aging-6m") {
      return [
        {
          name: "Non Aging CAPAs",
          data: data?.map((d) => d.non_aging ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: COLORS.atRiskBehavior },
        },
        {
          name: "Aging CAPAs",
          data: data?.map((d) => d.aging ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: COLORS.potentiallySerious },
        },
        {
          name: "% of Aging CAPAs",
          data: data?.map((d) => d.aging_percentage ?? 0),
          type: "line",
          lineStyle: {
            type: 'dotted'
          },
          itemStyle: { color: COLORS.safeBehavior },
          yAxisIndex: 1
        },
      ];
    } else {
      return [
        {
          name: "At-Risk Behavior",
          data: data.map((d) => d.atRiskBehavior ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: COLORS.atRiskBehavior },
        },

        {
          name: "At-Risk Condition",
          data: data.map((d) => d.atRiskCondition ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: COLORS.atRiskCondition },
        },

        {
          name: "Safe Behavior",
          data: data.map((d) => d.safeBehavior ?? 0),
          barWidth: 16,
          type: "bar",
          stack: "total",
          itemStyle: { color: COLORS.safeBehavior },
        },
      ];
    }
  };

  useEffect(() => {
    if (chartType === "minor-deviation") {
      const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "18%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data?.map((item) => formatMTDDate(item)),
          axisTick: { show: false },
        },
        yAxis: [
          {
            type: "value",
            name: "TOTAL OPEN MINOR DEVIATIONS",
            min: minMaxValueVar?.Min,
            max: minMaxValueVar?.Max,
            position: "right",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
            axisLabel: {
              align: "right",
              margin: 10,
            },
          },
          {
            type: "value",
            name: "TREND OF DAILY COMING DUE MINOR DEVIATIONS",
            min: minMaxValueVar?.Min,
            max: minMaxValueVar?.Max,
            position: "left",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
          },
        ],
        dataZoom: [
          {
            type: "inside",
            zoomLock: true,
            borderWidth: 0, // Border width of the slider track area
            borderColor: "#F0F0F0",
          },
          {
            type: "slider",
            show: false,
            showDetail: false,
            backgroundColor: "#f0f0f0",
            fillerColor: "#217ecf",
            // borderRadius: 12,
            borderColor: "transparent", // Border color of the slider track area
            borderRadius: 12,
            height: 20,
            handleStyle: {
              color: "#175891",
              borderRadius: 5,
              borderColor: "transparent",
              shadowColor: "transparent",
              // opacity: 0,
            },
            dataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            brushSelect: false,
            brushStyle: {
              // opacity: 0,
            },
          },
        ],

        series: getSeriesData(),
      };

      setChartOptions(options);
      setIsChartReady(true);
    } else if (chartType === "major-deviation") {
      const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "4%",
          right: "4%",
          bottom: "18%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data?.map((item) => formatMTDDate(item)),
          axisTick: { show: false },
        },
        yAxis: [
          {
            type: "value",
            name: "TREND OF DAILY COMING DUE MAJOR DEVIATIONS",
            min: minMaxValueVar?.Min,
            max: minMaxValueVar?.Max,
            position: "right",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
            axisLabel: {
              align: "right",
              margin: 10,
            },
          },
          {
            type: "value",
            name: "TOTAL OPEN MAJOR DEVIATIONS",
            nameLocation: "middle",
            position: "left",
            nameGap: 50,
            min: minMaxValueVar?.Min,
            max: minMaxValueVar?.Max,
            nameTextStyle: {
              fontSize: 7,
              fontWeight: 700,
              fontFamily: "Inter",
              lineHeight: 14,
              color: "#595959",
              align: "center",
              verticalAlign: "middle",
              rotate: 90,
            },
          },
        ],
        dataZoom: [
          {
            type: "inside",
            zoomLock: true,
            borderWidth: 0, // Border width of the slider track area
            borderColor: "#F0F0F0",
          },
          {
            type: "slider",
            show: false,
            showDetail: false,
            backgroundColor: "#f0f0f0",
            fillerColor: "#217ecf",
            // borderRadius: 12,
            borderColor: "transparent", // Border color of the slider track area
            borderRadius: 12,
            height: 20,
            handleStyle: {
              color: "#175891",
              borderRadius: 5,
              borderColor: "transparent",
              shadowColor: "transparent",
              // opacity: 0,
            },
            dataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            brushSelect: false,
            brushStyle: {
              // opacity: 0,
            },
          },
        ],

        series: getSeriesData(),
      };

      setChartOptions(options);
      setIsChartReady(true);
    } else if (chartType === "minor-deviation-adherence-mtd") {
      const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "18%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data?.map((item) => formatMTDDate(item)),
          axisTick: { show: false },
        },
        yAxis: [
          {
            type: "value",
            name: "% OF MINOR DEVIATION ADHERENCE",
            min: minMaxValueVar?.Min,
            max: 100,
            alignTicks: true,
            position: "right",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
            axisLabel: {
              align: "right",
              margin: 10,
              formatter: '{value}%'
            },
          },
          {
            type: "value",
            name: "TOTAL DUE MINOR DEVIATION IN REVIEW PERIOD",
            min: minMaxValueVar?.Min,
            max: newMinMaxValueVar?.Max,
            alignTicks: true,
            position: "left",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
          },
        ],
        dataZoom: [
          {
            type: "inside",
            zoomLock: true,
            borderWidth: 0, // Border width of the slider track area
            borderColor: "#F0F0F0",
          },
          {
            type: "slider",
            show: false,
            showDetail: false,
            backgroundColor: "#f0f0f0",
            fillerColor: "#217ecf",
            // borderRadius: 12,
            borderColor: "transparent", // Border color of the slider track area
            borderRadius: 12,
            height: 20,
            handleStyle: {
              color: "#175891",
              borderRadius: 5,
              borderColor: "transparent",
              shadowColor: "transparent",
              // opacity: 0,
            },
            dataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            brushSelect: false,
            brushStyle: {
              // opacity: 0,
            },
          },
        ],

        series: getSeriesData(),
      };

      setChartOptions(options);
      setIsChartReady(true);
    } else if (chartType === "minor-deviation-adherence-6m") {
      const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "18%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data?.map((item) => formatMonthYear(item)),
          axisTick: { show: false },
        },
        yAxis: [
          {
            type: "value",
            name: "% OF MINOR DEVIATION ADHERENCE",
            min: minMaxValueVar?.Min,
            max: 100,
            alignTicks: true,
            position: "right",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
            axisLabel: {
              align: "right",
              margin: 10,
              formatter: '{value}%'
            },
          },
          {
            type: "value",
            name: "TOTAL DUE MINOR DEVIATION IN REVIEW PERIOD",
            min: minMaxValueVar?.Min,
            max: newMinMaxValueVar?.Max,
            alignTicks: true,
            position: "left",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
          },
        ],
        dataZoom: [
          {
            type: "inside",
            zoomLock: true,
            borderWidth: 0, // Border width of the slider track area
            borderColor: "#F0F0F0",
          },
          {
            type: "slider",
            show: false,
            showDetail: false,
            backgroundColor: "#f0f0f0",
            fillerColor: "#217ecf",
            // borderRadius: 12,
            borderColor: "transparent", // Border color of the slider track area
            borderRadius: 12,
            height: 20,
            handleStyle: {
              color: "#175891",
              borderRadius: 5,
              borderColor: "transparent",
              shadowColor: "transparent",
              // opacity: 0,
            },
            dataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            brushSelect: false,
            brushStyle: {
              // opacity: 0,
            },
          },
        ],

        series: getSeriesData(),
      };

      setChartOptions(options);
      setIsChartReady(true);
    } else if (chartType === "minor-deviation-aging-6m") {
      const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "18%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data?.map((item) => formatMonthYear(item)),
          axisTick: { show: false },
        },
        yAxis: [
          {
            type: "value",
            name: "% OF AGING MINOR DEVIATIONS",
            min: minMaxValueVar?.Min,
            max: 100,
            alignTicks: true,
            position: "right",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
            axisLabel: {
              align: "right",
              margin: 10,
              formatter: '{value}%'
            },
          },
          {
            type: "value",
            name: "TOTAL OPEN MINOR DEVIATIONS",
            min: minMaxValueVar?.Min,
            max: newMinMaxValueVar?.Max,
            alignTicks: true,
            position: "left",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
          },
        ],
        dataZoom: [
          {
            type: "inside",
            zoomLock: true,
            borderWidth: 0, // Border width of the slider track area
            borderColor: "#F0F0F0",
          },
          {
            type: "slider",
            show: false,
            showDetail: false,
            backgroundColor: "#f0f0f0",
            fillerColor: "#217ecf",
            // borderRadius: 12,
            borderColor: "transparent", // Border color of the slider track area
            borderRadius: 12,
            height: 20,
            handleStyle: {
              color: "#175891",
              borderRadius: 5,
              borderColor: "transparent",
              shadowColor: "transparent",
              // opacity: 0,
            },
            dataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            brushSelect: false,
            brushStyle: {
              // opacity: 0,
            },
          },
        ],

        series: getSeriesData(),
      };

      setChartOptions(options);
      setIsChartReady(true);
    } else if (chartType === "minor-deviation-aging-mtd") {
      const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "18%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data?.map((item) => formatMTDDate(item)),
          axisTick: { show: false },
        },
        yAxis: [
          {
            type: "value",
            name: "% OF AGING MINOR DEVIATIONS",
            min: minMaxValueVar?.Min,
            max: 100,
            alignTicks: true,
            position: "right",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
            axisLabel: {
              align: "right",
              margin: 10,
              formatter: '{value}%'
            },
          },
          {
            type: "value",
            name: "TOTAL OPEN MINOR DEVIATIONS",
            min: minMaxValueVar?.Min,
            max: newMinMaxValueVar?.Max,
            alignTicks: true,
            position: "left",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
          },
        ],
        dataZoom: [
          {
            type: "inside",
            zoomLock: true,
            borderWidth: 0, // Border width of the slider track area
            borderColor: "#F0F0F0",
          },
          {
            type: "slider",
            show: false,
            showDetail: false,
            backgroundColor: "#f0f0f0",
            fillerColor: "#217ecf",
            // borderRadius: 12,
            borderColor: "transparent", // Border color of the slider track area
            borderRadius: 12,
            height: 20,
            handleStyle: {
              color: "#175891",
              borderRadius: 5,
              borderColor: "transparent",
              shadowColor: "transparent",
              // opacity: 0,
            },
            dataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            brushSelect: false,
            brushStyle: {
              // opacity: 0,
            },
          },
        ],

        series: getSeriesData(),
      };

      setChartOptions(options);
      setIsChartReady(true);
    } else if (chartType === "major-deviation-adherence-mtd") {
      const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "18%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data?.map((item) => formatMTDDate(item)),
          axisTick: { show: false },
        },
        yAxis: [
          {
            type: "value",
            name: "% OF MAJOR DEVIATION ADHERENCE",
            min: minMaxValueVar?.Min,
            max: 100,
            alignTicks: true,
            position: "right",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
            axisLabel: {
              align: "right",
              margin: 10,
              formatter: '{value}%'
            },
          },
          {
            type: "value",
            name: "TOTAL DUE MAJOR DEVIATION IN REVIEW PERIOD",
            min: minMaxValueVar?.Min,
            max: newMinMaxValueVar?.Max,
            alignTicks: true,
            position: "left",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
          },
        ],
        dataZoom: [
          {
            type: "inside",
            zoomLock: true,
            borderWidth: 0, // Border width of the slider track area
            borderColor: "#F0F0F0",
          },
          {
            type: "slider",
            show: false,
            showDetail: false,
            backgroundColor: "#f0f0f0",
            fillerColor: "#217ecf",
            // borderRadius: 12,
            borderColor: "transparent", // Border color of the slider track area
            borderRadius: 12,
            height: 20,
            handleStyle: {
              color: "#175891",
              borderRadius: 5,
              borderColor: "transparent",
              shadowColor: "transparent",
              // opacity: 0,
            },
            dataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            brushSelect: false,
            brushStyle: {
              // opacity: 0,
            },
          },
        ],

        series: getSeriesData(),
      };

      setChartOptions(options);
      setIsChartReady(true);
    } else if (chartType === "major-deviation-adherence-6m") {
      const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "18%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data?.map((item) => formatMonthYear(item)),
          axisTick: { show: false },
        },
        yAxis: [
          {
            type: "value",
            name: "% OF MAJOR DEVIATION ADHERENCE",
            min: minMaxValueVar?.Min,
            max: 100,
            alignTicks: true,
            position: "right",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
            axisLabel: {
              align: "right",
              margin: 10,
              formatter: '{value}%'
            },
          },
          {
            type: "value",
            name: "TOTAL DUE MAJOR DEVIATION IN REVIEW PERIOD",
            min: minMaxValueVar?.Min,
            max: newMinMaxValueVar?.Max,
            alignTicks: true,
            position: "left",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
          },
        ],
        dataZoom: [
          {
            type: "inside",
            zoomLock: true,
            borderWidth: 0, // Border width of the slider track area
            borderColor: "#F0F0F0",
          },
          {
            type: "slider",
            show: false,
            showDetail: false,
            backgroundColor: "#f0f0f0",
            fillerColor: "#217ecf",
            // borderRadius: 12,
            borderColor: "transparent", // Border color of the slider track area
            borderRadius: 12,
            height: 20,
            handleStyle: {
              color: "#175891",
              borderRadius: 5,
              borderColor: "transparent",
              shadowColor: "transparent",
              // opacity: 0,
            },
            dataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            brushSelect: false,
            brushStyle: {
              // opacity: 0,
            },
          },
        ],

        series: getSeriesData(),
      };

      setChartOptions(options);
      setIsChartReady(true);
    } else if (chartType === "major-deviation-aging-6m") {
      const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "18%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data?.map((item) => formatMonthYear(item)),
          axisTick: { show: false },
        },
        yAxis: [
          {
            type: "value",
            name: "% OF AGING MAJOR DEVIATION",
            min: minMaxValueVar?.Min,
            max: 100,
            alignTicks: true,
            position: "right",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
            axisLabel: {
              align: "right",
              margin: 10,
              formatter: '{value}%'
            },
          },
          {
            type: "value",
            name: "TOTAL OPEN MAJOR DEVIATION",
            min: minMaxValueVar?.Min,
            max: newMinMaxValueVar?.Max,
            alignTicks: true,
            position: "left",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
          },
        ],
        dataZoom: [
          {
            type: "inside",
            zoomLock: true,
            borderWidth: 0, // Border width of the slider track area
            borderColor: "#F0F0F0",
          },
          {
            type: "slider",
            show: false,
            showDetail: false,
            backgroundColor: "#f0f0f0",
            fillerColor: "#217ecf",
            // borderRadius: 12,
            borderColor: "transparent", // Border color of the slider track area
            borderRadius: 12,
            height: 20,
            handleStyle: {
              color: "#175891",
              borderRadius: 5,
              borderColor: "transparent",
              shadowColor: "transparent",
              // opacity: 0,
            },
            dataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            brushSelect: false,
            brushStyle: {
              // opacity: 0,
            },
          },
        ],

        series: getSeriesData(),
      };

      setChartOptions(options);
      setIsChartReady(true);
    } else if (chartType === "major-deviation-aging-mtd") {
      const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "18%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data?.map((item) => formatMTDDate(item)),
          axisTick: { show: false },
        },
        yAxis: [
          {
            type: "value",
            name: "% OF AGING MAJOR DEVIATION",
            min: minMaxValueVar?.Min,
            max: 100,
            alignTicks: true,
            position: "right",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
            axisLabel: {
              align: "right",
              margin: 10,
              formatter: '{value}%'
            },
          },
          {
            type: "value",
            name: "TOTAL OPEN MAJOR DEVIATION",
            min: minMaxValueVar?.Min,
            max: newMinMaxValueVar?.Max,
            alignTicks: true,
            position: "left",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
          },
        ],
        dataZoom: [
          {
            type: "inside",
            zoomLock: true,
            borderWidth: 0, // Border width of the slider track area
            borderColor: "#F0F0F0",
          },
          {
            type: "slider",
            show: false,
            showDetail: false,
            backgroundColor: "#f0f0f0",
            fillerColor: "#217ecf",
            // borderRadius: 12,
            borderColor: "transparent", // Border color of the slider track area
            borderRadius: 12,
            height: 20,
            handleStyle: {
              color: "#175891",
              borderRadius: 5,
              borderColor: "transparent",
              shadowColor: "transparent",
              // opacity: 0,
            },
            dataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            brushSelect: false,
            brushStyle: {
              // opacity: 0,
            },
          },
        ],

        series: getSeriesData(),
      };

      setChartOptions(options);
      setIsChartReady(true);
    } else if (chartType === "change-control-status") {
      const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "4%",
          right: "4%",
          bottom: "18%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data?.map((item) => formatMTDDate(item)),
          axisTick: { show: false },
        },
        yAxis: [
          {
            type: "value",
            name: "TREND OF DAILY COMING DUE CHANGE CONTROLS",
            min: minMaxValueVar?.Min,
            max: minMaxValueVar?.Max,
            position: "right",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
            axisLabel: {
              align: "right",
              margin: 10,
            },
          },
          {
            type: "value",
            name: "TOTAL OPEN CHANGE CONTROLS",
            nameLocation: "middle",
            position: "left",
            nameGap: 50,
            min: minMaxValueVar?.Min,
            max: minMaxValueVar?.Max,
            nameTextStyle: {
              fontSize: 7,
              fontWeight: 700,
              fontFamily: "Inter",
              lineHeight: 14,
              color: "#595959",
              align: "center",
              verticalAlign: "middle",
              rotate: 90,
            },
          },
        ],
        dataZoom: [
          {
            type: "inside",
            zoomLock: true,
            borderWidth: 0, // Border width of the slider track area
            borderColor: "#F0F0F0",
          },
          {
            type: "slider",
            show: false,
            showDetail: false,
            backgroundColor: "#f0f0f0",
            fillerColor: "#217ecf",
            // borderRadius: 12,
            borderColor: "transparent", // Border color of the slider track area
            borderRadius: 12,
            height: 20,
            handleStyle: {
              color: "#175891",
              borderRadius: 5,
              borderColor: "transparent",
              shadowColor: "transparent",
              // opacity: 0,
            },
            dataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            brushSelect: false,
            brushStyle: {
              // opacity: 0,
            },
          },
        ],

        series: getSeriesData(),
      };

      setChartOptions(options);
      setIsChartReady(true);
    } else if (chartType === "change-control-adherence-mtd" || chartType === "change-control-adherence-6m") {
      const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "4%",
          right: "4%",
          bottom: "18%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data?.map((item) => formatMTDDate(item)),
          axisTick: { show: false },
        },
        yAxis: [
          {
            type: "value",
            name: "% OF CHANGE CONTROL ADHERENCE",
            min: minMaxValueVar?.Min,
            max: 100,
            alignTicks: true,
            position: "right",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
            axisLabel: {
              align: "right",
              margin: 10,
              formatter: '{value}%'
            },
          },
          {
            type: "value",
            name: "TOTAL DUE CHANGE CONTROLS IN REVIEW PERIOD",
            nameLocation: "middle",
            position: "left",
            nameGap: 50,
            min: minMaxValueVar?.Min,
            max: newMinMaxValueVar?.Max,
            alignTicks: true,
            nameTextStyle: {
              fontSize: 7,
              fontWeight: 700,
              fontFamily: "Inter",
              lineHeight: 14,
              color: "#595959",
              align: "center",
              verticalAlign: "middle",
              rotate: 90,
            },
          },
        ],
        dataZoom: [
          {
            type: "inside",
            zoomLock: true,
            borderWidth: 0, // Border width of the slider track area
            borderColor: "#F0F0F0",
          },
          {
            type: "slider",
            show: false,
            showDetail: false,
            backgroundColor: "#f0f0f0",
            fillerColor: "#217ecf",
            // borderRadius: 12,
            borderColor: "transparent", // Border color of the slider track area
            borderRadius: 12,
            height: 20,
            handleStyle: {
              color: "#175891",
              borderRadius: 5,
              borderColor: "transparent",
              shadowColor: "transparent",
              // opacity: 0,
            },
            dataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            brushSelect: false,
            brushStyle: {
              // opacity: 0,
            },
          },
        ],

        series: getSeriesData(),
      };

      setChartOptions(options);
      setIsChartReady(true);
    } else if (chartType === "change-control-aging-mtd" || chartType === "change-control-aging-6m") {
      const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "4%",
          right: "4%",
          bottom: "18%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data?.map((item) => formatMTDDate(item)),
          axisTick: { show: false },
        },
        yAxis: [
          {
            type: "value",
            name: "% OF AGING CHANGE CONTROLS",
            min: minMaxValueVar?.Min,
            max: 100,
            alignTicks: true,
            position: "right",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
            axisLabel: {
              align: "right",
              margin: 10,
              formatter: '{value}%'
            },
          },
          {
            type: "value",
            name: "TOTAL OPEN CHANGE CONTROLS",
            nameLocation: "middle",
            position: "left",
            nameGap: 50,
            min: minMaxValueVar?.Min,
            max: newMinMaxValueVar?.Max,
            alignTicks: true,
            nameTextStyle: {
              fontSize: 7,
              fontWeight: 700,
              fontFamily: "Inter",
              lineHeight: 14,
              color: "#595959",
              align: "center",
              verticalAlign: "middle",
              rotate: 90,
            },
          },
        ],
        dataZoom: [
          {
            type: "inside",
            zoomLock: true,
            borderWidth: 0, // Border width of the slider track area
            borderColor: "#F0F0F0",
          },
          {
            type: "slider",
            show: false,
            showDetail: false,
            backgroundColor: "#f0f0f0",
            fillerColor: "#217ecf",
            // borderRadius: 12,
            borderColor: "transparent", // Border color of the slider track area
            borderRadius: 12,
            height: 20,
            handleStyle: {
              color: "#175891",
              borderRadius: 5,
              borderColor: "transparent",
              shadowColor: "transparent",
              // opacity: 0,
            },
            dataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            brushSelect: false,
            brushStyle: {
              // opacity: 0,
            },
          },
        ],

        series: getSeriesData(),
      };

      setChartOptions(options);
      setIsChartReady(true);
    } else if (chartType === "capa-status") {
      const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "4%",
          right: "4%",
          bottom: "18%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data?.map((item) => formatMTDDate(item)),
          axisTick: { show: false },
        },
        yAxis: [
          {
            type: "value",
            name: "TOTAL OPEN CAPAS",
            nameLocation: "middle",
            position: "left",
            nameGap: 50,
            min: newMinMaxValueVar?.Min,
            max: newMinMaxValueVar?.Max,
            nameTextStyle: {
              fontSize: 7,
              fontWeight: 700,
              fontFamily: "Inter",
              lineHeight: 14,
              color: "#595959",
              align: "center",
              verticalAlign: "middle",
              rotate: 90,
            },
            alignTicks: true
          },
          {
            type: "value",
            name: "TREND OF DAILY COMING CAPAS",
            min: newMinMaxValueVar?.Min,
            max: newMinMaxValueVar?.Max,
            position: "right",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
            axisLabel: {
              align: "right",
              margin: 10,
            },
            alignTicks: true
          }
        ],
        dataZoom: [
          {
            type: "inside",
            zoomLock: true,
            borderWidth: 0, // Border width of the slider track area
            borderColor: "#F0F0F0",
          },
          {
            type: "slider",
            show: false,
            showDetail: false,
            backgroundColor: "#f0f0f0",
            fillerColor: "#217ecf",
            // borderRadius: 12,
            borderColor: "transparent", // Border color of the slider track area
            borderRadius: 12,
            height: 20,
            handleStyle: {
              color: "#175891",
              borderRadius: 5,
              borderColor: "transparent",
              shadowColor: "transparent",
              // opacity: 0,
            },
            dataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            brushSelect: false,
            brushStyle: {
              // opacity: 0,
            },
          },
        ],

        series: getSeriesData(),
      };

      setChartOptions(options);
      setIsChartReady(true);
    } else if (chartType === "capa-status-mtd" || chartType === "capa-status-adherence-6m") {
      const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "4%",
          right: "4%",
          bottom: "18%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data?.map((item) => formatMTDDate(item)),
          axisTick: { show: false },
        },
        yAxis: [
          {
            type: "value",
            name: "TOTAL DUE CAPAS IN REVIEW PERIOD",
            nameLocation: "middle",
            position: "left",
            nameGap: 50,
            min: newMinMaxValueVar?.Min,
            max: newMinMaxValueVar?.Max,
            nameTextStyle: {
              fontSize: 7,
              fontWeight: 700,
              fontFamily: "Inter",
              lineHeight: 14,
              color: "#595959",
              align: "center",
              verticalAlign: "middle",
              rotate: 90,
            },
            alignTicks: true
          },
          {
            type: "value",
            name: "% of CAPA ADHERENCE",
            min: newMinMaxValueVar?.Min,
            max: 100,
            position: "right",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
            axisLabel: {
              align: "right",
              margin: 10,
            },
            alignTicks: true
          }
        ],
        dataZoom: [
          {
            type: "inside",
            zoomLock: true,
            borderWidth: 0, // Border width of the slider track area
            borderColor: "#F0F0F0",
          },
          {
            type: "slider",
            show: false,
            showDetail: false,
            backgroundColor: "#f0f0f0",
            fillerColor: "#217ecf",
            // borderRadius: 12,
            borderColor: "transparent", // Border color of the slider track area
            borderRadius: 12,
            height: 20,
            handleStyle: {
              color: "#175891",
              borderRadius: 5,
              borderColor: "transparent",
              shadowColor: "transparent",
              // opacity: 0,
            },
            dataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            brushSelect: false,
            brushStyle: {
              // opacity: 0,
            },
          },
        ],

        series: getSeriesData(),
      };

      setChartOptions(options);
      setIsChartReady(true);
    } else if (chartType === "capa-status-aging-mtd" || chartType === "capa-status-aging-6m") {
      const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "4%",
          right: "4%",
          bottom: "18%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data?.map((item) => formatMTDDate(item)),
          axisTick: { show: false },
        },
        yAxis: [
          {
            type: "value",
            name: "TOTAL OPEN CAPAS",
            nameLocation: "middle",
            position: "left",
            nameGap: 50,
            min: newMinMaxValueVar?.Min,
            max: newMinMaxValueVar?.Max,
            nameTextStyle: {
              fontSize: 7,
              fontWeight: 700,
              fontFamily: "Inter",
              lineHeight: 14,
              color: "#595959",
              align: "center",
              verticalAlign: "middle",
              rotate: 90,
            },
            alignTicks: true
          },
          {
            type: "value",
            name: "% OF AGING CAPAS",
            min: newMinMaxValueVar?.Min,
            max: 100,
            position: "right",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              ...nameStyleInfo,
            },
            alignTicks: true,
            axisLabel: {
              align: "right",
              margin: 10,
            },
          }
        ],
        dataZoom: [
          {
            type: "inside",
            zoomLock: true,
            borderWidth: 0, // Border width of the slider track area
            borderColor: "#F0F0F0",
          },
          {
            type: "slider",
            show: false,
            showDetail: false,
            backgroundColor: "#f0f0f0",
            fillerColor: "#217ecf",
            // borderRadius: 12,
            borderColor: "transparent", // Border color of the slider track area
            borderRadius: 12,
            height: 20,
            handleStyle: {
              color: "#175891",
              borderRadius: 5,
              borderColor: "transparent",
              shadowColor: "transparent",
              // opacity: 0,
            },
            dataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            brushSelect: false,
            brushStyle: {
              // opacity: 0,
            },
          },
        ],

        series: getSeriesData(),
      };

      setChartOptions(options);
      setIsChartReady(true);
    } else {
      const options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "18%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data?.map((item) => item.date),
          axisTick: { show: false },
        },
        yAxis: {
          type: "value",
        },
        dataZoom: [
          {
            type: "inside",
            zoomLock: true,
            borderWidth: 12, // Border width of the slider track area
            borderColor: "#F0F0F0",
          },
          {
            type: "slider",
            show: chartType === "inca" && timePeriod !== "Last Week",
            showDetail: false,
            backgroundColor: "#f0f0f0",
            fillerColor: "#217ecf",
            // borderRadius: 12,
            borderColor: "transparent", // Border color of the slider track area
            borderRadius: 12,
            height: 20,
            handleStyle: {
              color: "#175891",
              borderRadius: 5,
              borderColor: "transparent",
              shadowColor: "transparent",
              // opacity: 0,
            },
            dataBackground: {
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                opacity: 0,
              },
            },
            brushSelect: false,
            brushStyle: {
              // opacity: 0,
            },
          },
        ],

        series: getSeriesData(),
      };

      setChartOptions(options);
      setIsChartReady(true);
    }
  }, [data, chartType]);

  const getLegendItems = (): LegendProps[] => {
    if (!data) return [];
    if (chartType === "see-cards") {
      return [
        { statusValue: "Serious", circleColor: COLORS.serious },
        {
          statusValue: "Potentially Serious",
          circleColor: COLORS.potentiallySerious,
        },
        { statusValue: "Major", circleColor: COLORS.major },
        { statusValue: "Minor", circleColor: COLORS.minor },
        { statusValue: "Non-EHS Issue", circleColor: COLORS.nonEHSIssue },
      ];
    } else if (chartType === "minor-deviation") {
      return [
        { statusValue: "NON-AGING MINOR DEVIATIONS", circleColor: "#0063C3" },
        { statusValue: "AGING MINOR DEVIATIONS", circleColor: "#FDB81E" },
        { statusValue: "TREND OF DAILY COMING DUE MINOR DEVIATIONS", circleColor: "#2FBCB6" },
      ];
    } else if (chartType === "major-deviation") {
      return [
        { statusValue: "NON-AGING MAJOR DEVIATIONS", circleColor: "#0063C3" },
        { statusValue: "AGING MAJOR DEVIATIONS", circleColor: "#FDB81E" },
        { statusValue: "TREND OF DAILY COMING DUE MAJOR DEVIATIONS", circleColor: "#2FBCB6" },
      ];
    } else if (chartType === "minor-deviation-adherence-mtd" || chartType === "minor-deviation-adherence-6m") {
      return [
        { statusValue: "ON-TIME MINOR DEVIATIONS", circleColor: "#0063C3" },
        { statusValue: "NOT ON-TIME MINOR DEVIATIONS", circleColor: "#FDB81E" },
        { statusValue: "% OF MINOR DEVIATION ADHERENCE", circleColor: "#2FBCB6" },
      ];
    } else if (chartType === "major-deviation-adherence-mtd" || chartType === "major-deviation-adherence-6m") {
      return [
        { statusValue: "ON-TIME MAJOR DEVIATIONS", circleColor: "#0063C3" },
        { statusValue: "NOT ON-TIME MAJOR DEVIATIONS", circleColor: "#FDB81E" },
        { statusValue: "% OF MAJOR DEVIATION ADHERENCE", circleColor: "#2FBCB6" },
      ];
    } else if (chartType === "minor-deviation-aging-mtd" || chartType === "minor-deviation-aging-6m") {
      return [
        { statusValue: "NON-AGING MINOR DEVIATIONS", circleColor: "#0063C3" },
        { statusValue: "AGING MINOR DEVIATIONS", circleColor: "#FDB81E" },
        { statusValue: "% OF AGING MINOR DEVIATIONS", circleColor: "#2FBCB6" },
      ];
    } else if (chartType === "major-deviation-aging-mtd" || chartType === "major-deviation-aging-6m") {
      return [
        { statusValue: "NON-AGING MAJOR DEVIATIONS", circleColor: "#0063C3" },
        { statusValue: "AGING MAJOR DEVIATIONS", circleColor: "#FDB81E" },
        { statusValue: "% OF AGING MAJOR DEVIATIONS", circleColor: "#2FBCB6" },
      ];
    } else if (chartType === "change-control-status") {
      return [
        { statusValue: "NON-AGING CHANGE CONTROLS", circleColor: "#0063C3" },
        { statusValue: "AGING CHANGE CONTROLS", circleColor: "#FDB81E" },
        { statusValue: "TREND OF DAILY COMING DUE CHANGE CONTROLS", circleColor: "#2FBCB6" },
      ];
    } else if (chartType === "change-control-adherence-mtd" || chartType === "change-control-adherence-6m") {
      return [
        { statusValue: "ON-TIME CHANGE CONTROLS", circleColor: "#0063C3" },
        { statusValue: "NON ON-TIME CHANGE CONTROLS", circleColor: "#FDB81E" },
        { statusValue: "% OF CHANGE CONTROL ADHERENCE", circleColor: "#2FBCB6" },
      ];
    } else if (chartType === "change-control-aging-mtd" || chartType === "change-control-aging-6m") {
      return [
        { statusValue: "NON CHANGE CONTROLS", circleColor: "#0063C3" },
        { statusValue: "AGING CHANGE CONTROLS", circleColor: "#FDB81E" },
        { statusValue: "% OF AGING CONTROLS", circleColor: "#2FBCB6" },
      ];
    } else if (chartType === "capa-status") {
      return [
        { statusValue: "NON-AGING CAPAS", circleColor: "#0063C3" },
        { statusValue: "AGING CAPAS", circleColor: "#FDB81E" },
        { statusValue: "TREND OF DAILY COMING CAPAS", circleColor: "#2FBCB6" },
      ];
    }  else if (chartType === "capa-status-mtd" || chartType === "capa-status-adherence-6m") {
      return [
        { statusValue: "ON-TIME CAPAS", circleColor: "#0063C3" },
        { statusValue: "NOT ON TIME CAPAS", circleColor: "#FDB81E" },
        { statusValue: "% OF CAPA ADHERENCE", circleColor: "#2FBCB6" },
      ];
    }  else if (chartType === "capa-status-aging-mtd" || chartType === "capa-status-aging-6m") {
      return [
        { statusValue: "NON AGING CAPAS", circleColor: "#0063C3" },
        { statusValue: "AGING CAPAS", circleColor: "#FDB81E" },
        { statusValue: "% OF AGING CAPAS", circleColor: "#2FBCB6" },
      ];
    } else {
      return [
        { statusValue: "At-Risk Behavior", circleColor: COLORS.atRiskBehavior },

        {
          statusValue: "At-Risk Condition",
          circleColor: COLORS.atRiskCondition,
        },

        { statusValue: "Safe Behavior", circleColor: COLORS.safeBehavior },
      ];
    }
  };

  const legendItems = getLegendItems();

  return (
    <div style={{ width: "100%" }}>
      {data && data.length > 0 ? (
        <>
          {isChartReady && <ReactECharts option={chartOptions} />}

          {legendItems.length > 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0 auto 16px auto"
                }}
              >
                {legendItems.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "16px",
                    }}
                  >
                    <Label
                      StatusValue={item.statusValue}
                      circleColor={item.circleColor}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No Data Available"
        />
      )}
    </div>
  );
};

export default StackedBarChart;
