import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getOktaToken } from "utils/common-methods";

interface FetchDataParams {
  workStream?: string | undefined;
  site?: string | undefined;
  persona?: string | undefined;
  tierNumber?: string | undefined;
  shift?: string | undefined;
  costCenter?: string | undefined;
  line?: string | undefined;
  interval?: number | undefined;
  building?: string | undefined;
}

interface IncaOpenIncidents {
  overdue_status?: number | string;
  incident?: number | string;
  incident_last_24_hours?: number | string;
  coming_due?: number | string;
}

interface IncaGraphItem {
  date?: string;
  number_of_inca_incident?: number | string;
}

interface IncaDataType {
  inca_global_ehss: any[];
  inca_open_incidents: any[];
  summary: any[];
  inca_trend_chart: any[];
  inca_bar_chart_type: any[];
  inca_bar_chart_priority: any[];
}

interface TransformedData {
  inca: IncaDataType;
}

interface IncaInfoState {
  incaDeepDiveInfo: TransformedData | null;
  loading: boolean;
  error: boolean;
}

interface GroupedData {
  date: string;
  serious: number;
  potentiallySerious: number;
  major: number;
  minor: number;
  nonEHSIssue: number;
}
interface ApiData {
  no_of_incidents: number;
  date_of_incident: string;
  priority: "Serious" | "Potentially Serious" | "Major" | "Minor" | "Non EHS Issue";
}

const initialState: IncaInfoState = {
  incaDeepDiveInfo: null,
  loading: false,
  error: false,
};

const fetchIncaDeepDive = createAsyncThunk<any, FetchDataParams>(
  "/work-center-team/metrics/safety/deep-dive-view/inca",
  async (params) => {
    const {
      workStream,
      site,
      persona,
      tierNumber,
      shift,
      costCenter,
      line,
      interval,
      building,
    } = params;
    const token: string = getOktaToken();
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/work-center-team/metrics/safety/deep-dive-view/inca?workStream=${workStream}&site=${site}&persona=${persona}&tierNumber=${tierNumber}&line=${line}&shift=${shift}&costCenter=${costCenter}&interval=${interval}&building=${building}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch inca");
    }
    return await response.json();
  }
);

const transformTrendChartData = (apiData: ApiData[]): GroupedData[] => {
  const groupedData: { [key: string]: GroupedData } = {};

  apiData?.forEach(({ date_of_incident: dateOfIncident, no_of_incidents: noOfIncidents, priority }) => {
    const date = new Date(dateOfIncident).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
    });

    if (!groupedData[date]) {
      groupedData[date] = { date, serious: 0, potentiallySerious: 0, major: 0, minor: 0, nonEHSIssue: 0 };
    }

    switch (priority) {
      case "Serious":
        groupedData[date].serious += Number(noOfIncidents);
        break;
      case "Potentially Serious":
        groupedData[date].potentiallySerious += Number(noOfIncidents);
        break;
      case "Major":
        groupedData[date].major += Number(noOfIncidents);
        break;
      case "Minor":
        groupedData[date].minor += Number(noOfIncidents);
        break;
      case "Non EHS Issue":
        groupedData[date].nonEHSIssue += Number(noOfIncidents);
        break;
      default:
        break;
    }
  });

  return Object.values(groupedData);
};

const transformData = (data: any): TransformedData => ({
  inca: {
    inca_global_ehss: data.inca_global_ehss?.map((item: any) => ({
      action_taken: item.action_taken ?? "",
      amgen_site: item.amgen_site ?? "",
      date: item.date ?? "",
      incident_type: item.incident_type ?? "",
      short_description: item.short_description ?? "",
    })),
    inca_open_incidents: data.inca_open_incidents?.map((item: any) => ({
      overdue: item.overdue_status ?? "",
      issue_date: item.issue_date ?? "",
      priority: item.priority ?? "",
      owner: item.owner ?? "",
      incident_type: item.incident_type ?? "",
      safety_pyramid_level: item.safety_pyramid_level ?? "",
      short_description: item.short_description ?? "",
      status: item.status ?? "",
    })),
    summary: data?.summary?.[0],
    inca_trend_chart: transformTrendChartData(data?.inca_trend_chart),
    inca_bar_chart_type: data.inca_type_bar_chart?.map((item: any) => ({
      name: item.incident_type ?? "",
      value: item.total_incidents ?? "",
    })),
    inca_bar_chart_priority: data.inca_priority_bar_chart?.map((item: any) => ({
      name: item.priority ?? "",
      value: item.total_incidents ?? "",
    })),
  },
});

const incaDeepDiveReducer = createSlice({
  name: "inca",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIncaDeepDive.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchIncaDeepDive.fulfilled, (state, action) => {
        state.loading = false;
        state.incaDeepDiveInfo = transformData(action.payload);
      })
      .addCase(fetchIncaDeepDive.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export { fetchIncaDeepDive };

export default incaDeepDiveReducer.reducer;
