import CircleIcon from "assets/icons/circle-icon";
import React from "react";
import './index.scss'

interface LabelProps {
  StatusValue: string | number;
  circleColor: string;
}

const Label: React.FC<LabelProps> = (props: LabelProps) => {
  const { StatusValue, circleColor } = props;
  return (
    <div className="aoh-label-status">
      <div
        className="aoh-d-flex align-items-center"
      >
        <div className="aoh-pr-1 aoh-d-flex">
          <CircleIcon fill={circleColor} stroke={circleColor} />
        </div>
        <div className="aoh-running-label aoh-d-flex ops-fs-5 ops-fw-bold">
          {StatusValue}
        </div>
      </div>
    </div>
  );
};
export default Label;
