// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AohTable_tableContainer__8a9yS{
    margin-top: 20px;
}

.ant-table-column-title {
    font-weight: 700 !important;
}

.ant-table-body::-webkit-scrollbar {
  height: 6px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/table/AohTable.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".tableContainer{\n    margin-top: 20px;\n}\n\n:global(.ant-table-column-title) {\n    font-weight: 700 !important;\n}\n\n:global(.ant-table-body::-webkit-scrollbar) {\n  height: 6px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": `AohTable_tableContainer__8a9yS`
};
export default ___CSS_LOADER_EXPORT___;
