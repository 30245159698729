import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOktaToken } from "utils/common-methods";

interface FetchDataParams {
  site?: string | undefined;
  tierNumber?: string | undefined;
  metrics?: string | undefined;
  id?: string | undefined;
}

const fetchOverallSummaryDeepdiveDeepdive = createAsyncThunk<any, FetchDataParams>(
  "/work-center-team/deepdive",
  async (params: FetchDataParams) => {
    const { site, tierNumber, metrics, id } =
      params;
    const token: string = getOktaToken();
    const response = await fetch(
      `${process.env.REACT_APP_GENAI_API_URL}/work-center-team/deepdive?site=${site}&tierNumber=${tierNumber}&id=${id}&metric=${metrics}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch overall summary");
    }
    return await response.json();
  }
);

const overallSummaryDeepdiveReducer = createSlice({
  name: "overallSummaryDeepdive",
  initialState: {
    overallSummaryDeepdiveInfo: { data: [] as any },
    isBaseSummaryDeepdiveLoading: false,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOverallSummaryDeepdiveDeepdive.pending, (state) => {
      state.isBaseSummaryDeepdiveLoading = true;
      state.error = false;
    });
    builder.addCase(fetchOverallSummaryDeepdiveDeepdive.fulfilled, (state, action) => {
      state.overallSummaryDeepdiveInfo = action.payload.data;
      state.isBaseSummaryDeepdiveLoading = false;
      state.error = false;
    });
    builder.addCase(fetchOverallSummaryDeepdiveDeepdive.rejected, (state) => {
      state.isBaseSummaryDeepdiveLoading = false;
      state.error = true;
    });
  },
});

export { fetchOverallSummaryDeepdiveDeepdive };
export default overallSummaryDeepdiveReducer.reducer;
