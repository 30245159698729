import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOktaToken } from "utils/common-methods";

interface FetchDataParams {
  workstream?: string | undefined;
  site?: string | undefined;
  persona?: string | undefined;
  tierNumber?: string | undefined;
  shift?: string | undefined;
  costCenter?: string | undefined;
  line?: string | undefined;
  building?: string | undefined;
}

const fetchDeepdiveSummary = createAsyncThunk<any, FetchDataParams>(
  "/work-center-team/deepdive-view/summary",
  async (params: FetchDataParams) => {
    const { workstream, site, persona, tierNumber, shift, costCenter, line, building } =
      params;
    const token: string = getOktaToken();
    const response = await fetch(
      `${process.env.REACT_APP_GENAI_API_URL}/work-center-team/deepdive-view/summary?workStream=${workstream}&site=${site}&persona=${persona}&tierNumber=${tierNumber}&line=${line}&shift=${shift}&costCenter=${costCenter}&building=${building}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch deep dive summary");
    }
    return await response.json();
  }
);

const deepdiveSummaryReducer = createSlice({
  name: "deepdiveSummary",
  initialState: {
    deepdiveSummaryInfo: { data: {} as any },
    loading: false,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDeepdiveSummary.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchDeepdiveSummary.fulfilled, (state, action) => {
      state.deepdiveSummaryInfo = action.payload;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(fetchDeepdiveSummary.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export { fetchDeepdiveSummary };
export default deepdiveSummaryReducer.reducer;
