import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BaseViewCardPropsType, FetchDataParams, QueryParamsPropsType } from 'types/wctTypes';
import { filterParams, getMonthName, getOktaToken } from 'utils/common-methods';
import { CAPA_URL } from 'utils/constants';
import { StringKeyDataProps } from 'utils/data-types';

interface CapaDataItem {
    overdue?: number | string;
    coming_due_in_2days?: number | string;
    coming_due_in_5days?: number | string;
}

interface CapaGraphItem {
    Month?: number | string;
    Year?: number | string;
    compliance_percentage_6m?: number | string;
}

interface CapaDataType {
    card_data: CapaDataItem
    graph_data: CapaGraphItem[]
    query_params: QueryParamsPropsType
}

interface CapaInfoState {
    capaInfo: BaseViewCardPropsType | null;
    loading: boolean;
    error: boolean;
}

const initialState: CapaInfoState = {
    capaInfo: null,
    loading: false,
    error: false,
}

const fetchCapa = createAsyncThunk<CapaDataType, FetchDataParams>(
    CAPA_URL,
    async (params) => {
        const filteredParams = filterParams(params)
        const token: string = getOktaToken()

        const response = await fetch(`${process.env.REACT_APP_API_URL}${CAPA_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch capa');
        }
        return await response.json();
    }
)

const capaConvertData = (data: CapaGraphItem[]): StringKeyDataProps[] => {
    return data.map((item) => {
      const monthNumber =
        typeof item.Month === "number" ? item.Month : Number(item.Month);
      const value =
        typeof item.compliance_percentage_6m === "number"
          ? item.compliance_percentage_6m.toFixed(2)
          : "0.00";
  
      const monthName = getMonthName(monthNumber - 1)
      return { name: monthName, value: parseFloat(value) }
    });
  }

const transformData = (capaData: CapaDataType): BaseViewCardPropsType => {
    const capaBaseViewData: BaseViewCardPropsType = {
        cardData: {},
        graphData: [],
        graphParams: {},
        cardProps: {
            alertType: 'success'
        }
    }

    const tierLabel = capaData.query_params?.tierLabel

    const capaSanitizedData: StringKeyDataProps = {};
    const capaOverDue = capaData.card_data?.overdue;
    const capaComingDueIn2Day = capaData.card_data?.coming_due_in_2days;
    const capaComingDueIn5Day = capaData.card_data?.coming_due_in_5days;

    if (
        tierLabel?.toLowerCase() === "tier 3" &&
        capaData?.graph_data?.length
    ) {
        const inputData1: CapaGraphItem[] = capaData?.graph_data;
        const outputData: StringKeyDataProps[] = capaConvertData(inputData1);
        capaBaseViewData.graphData = outputData
        capaBaseViewData.graphParams.isGraph = true
    } else {
        capaBaseViewData.graphData = []
        capaBaseViewData.graphParams.isGraph = false
    }

    if (typeof capaOverDue === "number" && capaOverDue >= 0) {
        capaSanitizedData.OVERDUE = capaOverDue;
    }

    if (typeof capaComingDueIn2Day === "number" && capaComingDueIn2Day >= 0) {
        capaSanitizedData["COMING DUE IN 2 DAYS"] = capaComingDueIn2Day;
    }

    if (typeof capaComingDueIn5Day === "number" && capaComingDueIn5Day >= 0) {
        capaSanitizedData["COMING DUE IN 5 DAYS"] = capaComingDueIn5Day;
    }
    capaBaseViewData.cardData = capaSanitizedData

    if (tierLabel?.toLowerCase() === "tier 3") {
        capaBaseViewData.graphParams.tooltipContent = "Monthly %CAPA Adherence over last 6 months"
    }

    if (typeof capaOverDue === "number" && capaOverDue > 0) {
        capaBaseViewData.cardProps.alertType = "error"
    } else {
        capaBaseViewData.cardProps.alertType = "success"
    }

    return capaBaseViewData
}

const capaReducer = createSlice({
    name: 'capa',
    initialState,
    reducers: {
        clearcapaData(state) {
            state.capaInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCapa.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchCapa.fulfilled, (state, action) => {
                state.loading = false;
                state.capaInfo = transformData(action.payload);
            })
            .addCase(fetchCapa.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });

    },
});

export { fetchCapa };

export const { clearcapaData } = capaReducer.actions

export default capaReducer.reducer;