import { Drawer, Timeline, Tag, Spin, Empty } from "antd";
import { dummyData } from "./contants";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { useEffect } from "react";
import { fetchOverallSummaryDeepdiveDeepdive } from "reducers/sqdipSummaryDeepdiveReducer";
import { Loader } from "@gitlab-rtsensing/component-library";

interface DeepdiveDrawerProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (isDrawerOpen: boolean) => void;
  deepDiveAlert: string;
  tier: any;
  metrics: string;
  id: string;
}

function DeepdiveDrawer(props: DeepdiveDrawerProps) {
  const { isDrawerOpen, setIsDrawerOpen, deepDiveAlert, tier, metrics, id } =
    props;
  const dispatch = useDispatch<AppDispatch>();
  const { overallSummaryDeepdiveInfo, isBaseSummaryDeepdiveLoading } =
    useSelector((state: RootState) => state.overallSummaryDeepdive);
  useEffect(() => {
    const params = {
      site: "aoh",
      tierNumber: tier,
      metrics: metrics.toLowerCase(),
      id,
    };
    dispatch(fetchOverallSummaryDeepdiveDeepdive(params));
  }, [isDrawerOpen]);

  const renderTimelineItems = () => {
    return Array.isArray(overallSummaryDeepdiveInfo)
      ? overallSummaryDeepdiveInfo.map((data: any, index) => {
          return (
            <Timeline.Item key={index}>
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#f7f9fc",
                  borderRadius: "8px",
                  padding: "16px 16px 10px 16px",
                }}
              >
                {Object.keys(data).map((key) => {
                  return (
                    <div
                      className="d-flex"
                      style={{
                        paddingBottom: "5px",
                        backgroundColor: "#f7f9fc",
                      }}
                      key={key}
                    >
                      <div
                        style={{
                          fontSize: "12px",
                          color: "#4a4b4c",
                          fontWeight: 700,
                          width: "30%",
                          marginRight: "12px",
                        }}
                      >
                        {`${key}:`}
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "#262626",
                          fontWeight: 400,
                          width: "70%",
                        }}
                      >
                        {data[key]}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Timeline.Item>
          );
        })
      : null;
  };

  return (
    <Drawer
      title={<h2>Deep Dive Alert Details</h2>}
      width={600}
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
      className="deepdive-drawer"
    >
      <Tag color="blue" style={{ width: "100%", whiteSpace: "break-spaces" }}>
        <div dangerouslySetInnerHTML={{ __html: deepDiveAlert }}></div>
      </Tag>
      <br />
      <br />
      <div>
        <div style={{ maxHeight: "520px", height: "520px", overflowY: "auto" }}>
          {isBaseSummaryDeepdiveLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </div>
          ) : Array.isArray(overallSummaryDeepdiveInfo) &&
            overallSummaryDeepdiveInfo.length === 0 ? (
            <div
              style={{
                textAlign: "center",
                padding: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          ) : (
            <Timeline>{renderTimelineItems()}</Timeline>
          )}
        </div>
      </div>
      <br />
      <div
        style={{
          height: "130px",
          background: "#fafafa",
          border: "1px solid #e3e3e3",
          borderRadius: "4px",
        }}
      >
        <div style={{ padding: "20px" }}>
          <h3>WCT Notes</h3>
        </div>
        <div style={{ position: "relative", textAlign: "center", top: "2%" }}>
          WCT Notes Coming Soon
        </div>
      </div>
    </Drawer>
  );
}

export default DeepdiveDrawer;
