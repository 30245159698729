import APIErrorMessage from "utils/api-error";
import cardCss from "./SummaryCard.module.scss";
import { Empty } from "antd";

interface AohSummaryProps {
  data?: any;
  tierLabel?: string;
  activeKeyDeepDiveTab?: string;
}

const SummaryCard: React.FC<AohSummaryProps> = ({ data }) => {
  const { cardDesign, container, upText, downText, mrBtm } = cardCss;
  const propertiesToDisplay = [
    { key: "issued_last_24_hours", label: "Safety Critical Work Orders in Last 24 Hours" },
    { key: "overdues", label: "Overdue" },
    { key: "incidents_last_24_hours", label: "Incidents Last 24 Hours" },
    { key: "coming_due_in_5days", label: "Coming Due Next 5 Days" },
    { key: "total_due_in_5_days", label: "Upcoming Due in 5 Days" },
    { key: "total_due_in_2_days", label: "Upcoming Due in 2 Days" },
    { key: "site_compliance_percentage", label: "Site Compliance Percentage" },
    { key: "see_cards_in_last_24_hours", label: "See Cards in Last 24 Hours" },
    { key: "total_coming_due_in_5days", label: "Coming Due in 5 Days" },
    { key: "total_overdue", label: "Overdue" },
    { key: "total_coming_due_in_2days", label: "Coming Due in 2 Days" },
    { key: "total_entered_last_24hours", label: "Incidents" },
    { key: "upcoming_due_in_5_days", label: "Upcoming Due in 5 Days" },
    { key: "total_see_cards_today", label: "See Cards Today" },
    { key: "coming_due_in_2days", label: "Coming Due in 2 Days" },
    { key: "total_entered_last_5day", label: "Incidents" },
    { key: "compliance_%_for_line", label: "Compliance % for line" },
    { key: "open_deviation_last_24h", label: "Open Minor Deviations in Last 24 Hours" },
    { key: "unclosed_deviations", label: "Open Minor Deviations" },
    { key: "avg_ageing", label: "Avg. age of unclosed minor deviation" },
    { key: "overdue", label: "Overdue" },
    { key: "unclosed", label: "unclosed" },
    { key: "mtd_deviation_ad_percentage", label: "% Minor Deviations closed meeting target due date(MTD)" },
    { key: "mtd_aging_deviation_percentage", label: "% Aging open Minor Deviation Target" },
    { key: "overdue_change_control", label: "Overdue Change Control" },
    { key: "overdue_major_deviation", label: "Overdue Major Deviation" },
    { key: "overdue_minor_deviation", label: "Overdue Minor Deviation" },
    { key: "coming_due_major_deviation_in_7_days", label: "Coming Due Major Deviation in 7 Days" },
    { key: "%_aging_major_deviation", label: "% Aging Major Deviation" },
    { key: "%_aging_minor_deviation", label: "% Aging Minor Deviation" },
    { key: "open_major_deviation", label: "Open Major Deviation" },
    { key: "open_minor_deviation", label: "Open Minor Deviation" },
    { key: "%_major_deviations_adherence_(mtd)", label: "% Major Deviations Adherence (MTD)" },
    { key: "%_aging_major_deviations_(mtd)", label: "% Aging Major Deviations (MTD)" },
    { key: "coming_due_in_7days", label: "coming due in 7 days" },
    { key: "coming_due_in_5_days", label: "Coming due in 5 days" },
    { key: "coming_due_in_2_days", label: "Coming due GXP Training in 2 days" },
    { key: "%_compliance", label: "% compliance" },
    {
      key: "coming_due_change_control_in_7_days",
      label: "Coming Due Change Control in 7 Days",
    },
    { key: "open_change_control", label: "Open Change Control" },
    { key: "aging_change_control", label: "% Aging Change Control" },
    { key: "perc_aging_cc", label: "% Aging Change Control" },
    { key: "open_cc", label: "Open Change Control" },
    { key: "coming_due_cc", label: "Coming Due Change Control in 7 Days" },
    { key: "perc_adherence_mtd", label: "% Change Control Adherence (MTD)" },
    { key: "perc_aging_mtd", label: "% Aging Change Control (MTD)" },
    { key: "number_of_overdues", label: "Overdue CAPAs" },
    { key: "coming_due_in_7_days", label: "Coming Due CAPAs in 7 Days" },
    { key: "open_capas", label: "Open CAPAs" },
    { key: "aging_percentage", label: "% Aging CAPAs" },
    { key: "capa_adherence_percentage_mtd", label: "% of CAPA Adherence(MTD)" },
    { key: "capa_ev_percentage_mtd", label: "% of Aging CAPA(MTD)" },
    { key: "capa_aging_percentage_mtd", label: "% CAPA EV(MTD)" },
    { key: "target", label: "Target" }
  ];

  return (
    <div className={container}>
      {data ? (
        propertiesToDisplay?.map(({ key, label }) => {
          const value = data?.[key];
          return (
            value !== undefined &&
            value !== "" && (
              <div key={`${key}`} className={cardDesign}>
                <div className={`${upText} ${mrBtm}`}>{value}</div>
                <div className={downText}>{label}</div>
              </div>
            )
          );
        })
      ) : (
        <div className={cardDesign}>
          <APIErrorMessage apierror={"No Data Available"} />
        </div>
      )}
    </div>
  );
};

export default SummaryCard;
