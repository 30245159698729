import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

interface Data {
  name: string;
  value: number;
}

interface HorizontalBarChartProps {
  title1: string;
  title2: string;
  subtitle1: string;
  subtitle2: string;
  EHSSProgramData: Data[];
  BehaviorObservationData: Data[];
}

const HorizontalBarChart: React.FC<HorizontalBarChartProps> = ({
  title1,
  title2,
  subtitle1,
  subtitle2,
  BehaviorObservationData,
  EHSSProgramData,
}) => {
  const [chartOptions, setChartOptions] = useState({});
  const [chartOptions2, setChartOptions2] = useState({});
  const getMaxValue = (data: Data[]) => {
    if (!Array.isArray(data) || data.length === 0) {
      return 0;
    }
    return Math.max(...data.map((item) => item.value));
  };

  const generateTicks = (maxValue: number) => {
    const step = Math.ceil(maxValue / 10);
    return Array.from(
      { length: Math.ceil(maxValue / step) + 1 },
      (_, index) => index * step
    );
  };

  const EHSSProgramTicks = generateTicks(getMaxValue(EHSSProgramData));
  const BehaviorObservationTicks = generateTicks(
    getMaxValue(BehaviorObservationData)
  );

  const getOption = (data: Data[], ticks: number[]) => {
    return {
      grid: {
        left: "5%",
        right: "10%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        axisLabel: {
          fontSize: 10,
          fontFamily: "Inter",
          color: "#595959",
          fontWeight: 700,
        },
        splitLine: { show: false },
        data: ticks,
      },
      yAxis: {
        type: "category",
        data: data?.map((item) => item.name),
        axisLabel: {
          fontSize: 10,
          fontFamily: "Inter",
          color: "#595959",
          fontWeight: 700,
        },
        splitLine: { show: false },
        axisTick: { show: false },
      },
      series: [
        {
          type: "bar",
          data: data?.map((item) => item.value),
          itemStyle: {
            color: "#0063C3",
          },
        },
      ],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      dataZoom: [
        {
          type: "inside",
          start: 0,
          end: data?.length > 5 ? Math.floor((5 / data?.length) * 100) : 100,
          zoomLock: true,
          borderWidth: 12, // Border width of the slider track area
          borderColor: "#F0F0F0",
        },
        {
          type: "slider",
          yAxisIndex: 0,
          show: data?.length > 5,
          showDetail: false,
          backgroundColor: "#f0f0f0",
          fillerColor: "#217ecf",
          // borderRadius: 12,
          borderColor: "transparent", // Border color of the slider track area
          borderRadius: 12,
          width: 15,
          handleStyle: {
            color: "#175891",
            borderRadius: 5,
            borderColor: "transparent",
            shadowColor: "transparent",
            // opacity: 0,
          },
          dataBackground: {
            lineStyle: {
              opacity: 0,
            },
            areaStyle: {
              opacity: 0,
            },
          },
          start: 0,
          end: data?.length > 5 ? Math.floor((5 / data?.length) * 100) : 100,
          brushSelect: false,
          brushStyle: {
            // opacity: 0,
          },
        },
      ],
    };
  };

  useEffect(() => {
    const option1 = getOption(EHSSProgramData, EHSSProgramTicks);
    const option2 = getOption(
      BehaviorObservationData,
      BehaviorObservationTicks
    );
    setChartOptions(option1);
    setChartOptions2(option2);
  }, [EHSSProgramData, BehaviorObservationData]);

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ width: "60%" }}>
        <h3 className="tier0-title">{title1}</h3>
        <p className="tier0-subtitle">{subtitle1}</p>
        {EHSSProgramData?.length > 0 ? (
          <ReactECharts option={chartOptions} style={{ height: 400 }} />
        ) : (
          <p>No data available</p>
        )}
      </div>

      <div style={{ width: "60%" }}>
        <h3 className="tier0-title">{title2}</h3>
        <p className="tier0-subtitle">{subtitle2}</p>
        {BehaviorObservationData?.length > 0 ? (
          <ReactECharts option={chartOptions2} style={{ height: 400 }} />
        ) : (
          <p>No data available</p>
        )}
      </div>
    </div>
  );
};

export default HorizontalBarChart;
