import AohTable from "../../common/table/AohTable";
import SummaryCard from "../../common/summaryCard/summaryCard";
import "./Quality.scss";
import { GXPTableColumnsType, minorDeviationColumns } from "types/wctTypes";
import { Button } from "@gitlab-rtsensing/component-library";
import { DownloadOutlined } from "@ant-design/icons";

interface QualityTier0Props {
  data: any;
  tierLabel: string;
  activeKeyDeepDiveTab: string;
}

const QualityTier0: React.FC<QualityTier0Props> = ({
  data: { summary, tableData },
  tierLabel,
  activeKeyDeepDiveTab,
}) => {
  const qualityDeepDive: any = {
    "Minor Deviation": {
      summaryTitle: "Summary",
      summary: (
        <SummaryCard
          tierLabel={tierLabel}
          activeKeyDeepDiveTab={activeKeyDeepDiveTab}
          data={summary?.[0]}
        />
      ),
      title: "New Open Minor Deviation",
      button: (
        <Button
          label="Download"
          icon={
            <DownloadOutlined style={{ color: "#0063C3", fontSize: "14px" }} />
          }
          iconPosition="right"
          onClick={() => { }}
          type="secondary"
        />
      ),
      table: <AohTable tableData={tableData} tableColumns={minorDeviationColumns} />,
    },
    "GXP Training %Compliance": {
      summaryTitle: "Summary",
      summary: (
        <SummaryCard
          tierLabel={tierLabel}
          activeKeyDeepDiveTab={activeKeyDeepDiveTab}
          data={summary}
        />
      ),
      title: "Upcoming Due",
      subtitle: "In 7 days",
      table: <AohTable tableData={tableData} tableColumns={GXPTableColumnsType} />,
    }
  };
  return (
    <div className="quality-tier-main">
      <div className="tier-container">
        <h2 className="tier-title">
          {/* Summary card */}
          {qualityDeepDive[activeKeyDeepDiveTab]?.summaryTitle}
        </h2>
      </div>
      {qualityDeepDive[activeKeyDeepDiveTab]?.summary}
      <div
        className={
          qualityDeepDive[activeKeyDeepDiveTab]?.summary ? "mr-bottom-20" : ""
        }
      ></div>
      <div className="tier-container">
        <h2 className="tier-title">
          {qualityDeepDive[activeKeyDeepDiveTab]?.title}
        </h2>
        {qualityDeepDive[activeKeyDeepDiveTab]?.button}
      </div>
      <div className="tier-subtitle">
        {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle}
      </div>
      {qualityDeepDive[activeKeyDeepDiveTab]?.table}
    </div>
  );
};
export default QualityTier0;
