// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TickFormatter_customTick__-B8Wz {
    fill: #000000; /* Text color */
    font-size: 10px; /* Font size */
    font-weight: 700; /* Font weight */
    line-height: 14px; /* Line height */
}  `, "",{"version":3,"sources":["webpack://./src/components/resuable/charts-graph/simple-line-graph/tick-formatter/TickFormatter.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa,EAAE,eAAe;IAC9B,eAAe,EAAE,cAAc;IAC/B,gBAAgB,EAAE,gBAAgB;IAClC,iBAAiB,EAAE,gBAAgB;AACvC","sourcesContent":[".customTick {\n    fill: #000000; /* Text color */\n    font-size: 10px; /* Font size */\n    font-weight: 700; /* Font weight */\n    line-height: 14px; /* Line height */\n}  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customTick": `TickFormatter_customTick__-B8Wz`
};
export default ___CSS_LOADER_EXPORT___;
