import { configureStore } from '@reduxjs/toolkit';
import tierInfoReducer from 'reducers/tierInfoReducer';
import calendarReducer from 'reducers/safetyBaseView/calendarReducer';
import workOrderReducer from 'reducers/safetyBaseView/workOrderReducer';
import seeCardReducer from 'reducers/safetyBaseView/seeCardsReducer';
import incaReducer from 'reducers/safetyBaseView/incaReducer';
import capaReducer from 'reducers/safetyBaseView/capaReducer';
import ehssReducer from 'reducers/safetyBaseView/ehssReducer';
import lineInfoReducer from 'reducers/lineInfoReducer';

// deep dive
import seeCardsDeepDiveReducer from 'reducers/safetyDeepDive/seeCardsDeepDiveReducer';
import incaDeepDiveReducer from 'reducers/safetyDeepDive/incaDeepDiveReducer';
import workOrderDeepDiveReducer from 'reducers/safetyDeepDive/workOrderReducer';
import ehssDeepDiveReducer from 'reducers/safetyDeepDive/ehssReducer';
import capaDeepDiveReducer from 'reducers/safetyDeepDive/capaDeepDiveReducer';
import qualityChangeControlReducer from 'reducers/qualityBaseView/qualityChangeControlReducer'
import qualityCAPAReducer from 'reducers/qualityBaseView/qualityCAPAReducer'
import overallSummaryReducer from 'reducers/sqdipSummaryReducer';
import minorDeviationReducer from 'reducers/qualityBaseView/minorDeviation';
import majorDeviationReducer from 'reducers/qualityBaseView/majorDeviation'
import gmpTrainingReducer from 'reducers/qualityBaseView/gmpReducer'
import deepdiveSummaryReducer from 'reducers/safetyDeepDive/deepdiveSummaryReducer';
import minorDeviationDeepDiveReducer from 'reducers/qualityDeepDive/minorDevDeepDiveReducer';
import majorDeviationDeepDiveReducer from 'reducers/qualityDeepDive/majorDevDeepDiveReducer'
import customMetricParamsReducer from 'reducers/customMetricParamsReducer'
import overallSummaryDeepdiveReducer from 'reducers/sqdipSummaryDeepdiveReducer';
import gxpDeepDiveReducer from 'reducers/qualityDeepDive/gxpReducer';
import changeControlDeepDiveReducer from "reducers/qualityDeepDive/changeControlDeepDiveReducer";
import qualityRFTReducer from 'reducers/qualityBaseView/qualityRFTReducers';
import qualityCapaDeepDiveReducer from 'reducers/qualityDeepDive/qualityCapaDeepDiveReducer';
import oneClickReportReducer from 'reducers/oneClickReportReducer';

const store = configureStore({
  reducer: {
    tierInfo: tierInfoReducer,
    calendar: calendarReducer,
    workOrder: workOrderReducer,
    seeCardsInfo: seeCardReducer,
    incaInfo: incaReducer,
    capaInfo: capaReducer,
    ehssInfo: ehssReducer,
    lineInfo: lineInfoReducer,
    seeCardsDeepDive: seeCardsDeepDiveReducer,
    incaDeepDive: incaDeepDiveReducer,
    workOrderDeepDive: workOrderDeepDiveReducer,
    ehssDeepDive: ehssDeepDiveReducer,
    capaDeepDive: capaDeepDiveReducer,
    qualityChangeControlInfo: qualityChangeControlReducer,
    qualityCAPAInfo: qualityCAPAReducer,
    overallSummary: overallSummaryReducer,
    deepdiveSummary: deepdiveSummaryReducer,
    minorDeviation: minorDeviationReducer,
    majorDeviation: majorDeviationReducer,
    gmpTraining: gmpTrainingReducer,
    minorDeviationDeepDive: minorDeviationDeepDiveReducer,
    majorDeviationDeepDive: majorDeviationDeepDiveReducer,
    customMetricParams: customMetricParamsReducer,
    overallSummaryDeepdive: overallSummaryDeepdiveReducer,
    qualityGxpDeepDiveInfo: gxpDeepDiveReducer,
    changeControlDeepdiveInfo: changeControlDeepDiveReducer,
    qualityRFTInfo: qualityRFTReducer,
    qualityCapaDeepDiveInfo: qualityCapaDeepDiveReducer,
    oneClickReport: oneClickReportReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
