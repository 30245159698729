import CircleIcon from "assets/icons/circle-icon";
import React from "react";
import './index.scss'

interface LabelProps {
  StatusValue: string | number;
  circleColor: string;
}

const TableLabel: React.FC<LabelProps> = (props: LabelProps) => {
  const { StatusValue, circleColor } = props;
  return (
    <div className="aoh-label-status">
      <div
        className="aoh-label-box aoh-d-flex align-items-center"
      >
        {circleColor !== "" ? <div className="aoh-pr-1 aoh-d-flex">
          <CircleIcon fill={circleColor} stroke={circleColor} width={16} height={16}/>
        </div> :  <div className="aoh-pr-1 aoh-d-flex">
          <CircleIcon fill={'transparent'} stroke={circleColor} width={16} height={16}/>
        </div> }    
        <div className="aoh-running-label aoh-d-flex">
          {StatusValue}
        </div>
      </div>
    </div>
  );
};
export default TableLabel;