import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOktaToken } from 'utils/common-methods';
import { WCT_URL } from 'utils/constants';

interface MetricName {
    name: string;
}

interface TierInfoItem {
    name: string;
    metricName: MetricName[]
}

interface TierInfoDataType {
    safety: TierInfoItem[];
}

interface ShiftType {
    // Define the properties of ShiftType here
    id: number;
    name: string;
}

interface CostCenterType {
    // Define the properties of CostCenterType here
    id: number;
    name: string;
}

interface TransformedTierItem {
    key: number;
    label: string;
    metricName: { key: number; label: string }[]; 
    shifts?: ShiftType[];
    costCenters?: CostCenterType[];
}

interface TransformedData {
    tiers: TransformedTierItem[];
}

interface TierInfoState {
    tierInfo: TransformedData | null;
    loading: boolean;
    error: boolean;
}

const initialState: TierInfoState = {
    tierInfo: null,
    loading: false,
    error: false,
};

const fetchTierInfo = createAsyncThunk<TierInfoDataType, undefined>(
    WCT_URL,
    async () => {
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/${WCT_URL}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch tiers');
        }
        return await response.json();
    }
);

const mapItems = (items: any[] = []): { key: number; label: string }[] =>
    items.map((item, index) => ({
        key: index,
        label: item.name
    }));

const transformData = (data: TierInfoDataType): TransformedData => ({
    tiers: data.safety.map((item, index) => ({
        key: index,
        label: item.name,
        metricName: mapItems(item.metricName)
    })),
});

const tierInfoSlice = createSlice({
    name: 'tierInfo',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTierInfo.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchTierInfo.fulfilled, (state, action) => {
                state.loading = false;
                state.tierInfo = transformData(action.payload);
            })
            .addCase(fetchTierInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });

    },
});

export { fetchTierInfo };

export default tierInfoSlice.reducer;