/* eslint-disable @typescript-eslint/restrict-plus-operands */

import { useDispatch, useSelector } from "react-redux";
import PageLayout from "components/resuable/layout";
import "./index.scss";
import { useLocation, useParams } from "react-router";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "context/app-context";
import {
  Alert,
  Avatar,
  Breadcrumb,
  ConfigProvider,
  Tabs,
  Popover,
} from "antd";
import {
  permissionObject,
  personas,
  wctNavigation,
  customCss,
  metricNames,
  wctSafetyDeepdiveNavigation,
  wctMetrics,
  wctQualityDeepdiveNavigation,
  SQDIPType,
} from "utils/constants";
import {
  BreadcrumbMenuItemType,
  MCT,
  PermissionType,
  PersonasAuthorization,
} from "types/permission-type";
import { DeepDiveTabItemsType } from "types/wctTypes";
import Header from "./aoh/wct/header/Header";
import type { MenuProps } from "antd";
import AohDropdown from "components/common/dropdown/aohdropdown";
import wctCss from "./workCenterTeam.module.css";
import AIGeneratedIcon from "assets/icons/AIGeneratedIcon";
import { NavLink } from "components/side-nav/types";
import {
  Button,
  StatusIssueIcon,
  StatusPositiveIcon,
  Loader,
} from "@gitlab-rtsensing/component-library";
import CustomMetricCard from "components/resuable/CustomMetricCard/CustomMetricCard";

import { AppDispatch, RootState } from "../store";
import { fetchTierInfo } from "reducers/tierInfoReducer";
import ErrorHandler from "utils/error-handler";
import { clearCalendarData, fetchCalendar } from "reducers/safetyBaseView/calendarReducer";
import { clearworkOrderData } from "reducers/safetyBaseView/workOrderReducer";
import { clearIncaData } from "reducers/safetyBaseView/incaReducer";
import { clearcapaData } from "reducers/safetyBaseView/capaReducer";
import { clearEhssData } from "reducers/safetyBaseView/ehssReducer";
import { clearSeeCardsData } from "reducers/safetyBaseView/seeCardsReducer";
import AohDeepDiveMain from "components/aoh-deep-dive-main";
import { fetchLineInfo } from "reducers/lineInfoReducer";
import { fetchOverallSummary } from "reducers/sqdipSummaryReducer";
import { fetchDeepdiveSummary } from "reducers/safetyDeepDive/deepdiveSummaryReducer";
import { updateCustomMetricParams } from "reducers/customMetricParamsReducer";
import { clearMinorDeviationData } from "reducers/qualityBaseView/minorDeviation";
import { clearMajorDeviationData } from "reducers/qualityBaseView/majorDeviation";
import { clearGmpTrainingData } from "reducers/qualityBaseView/gmpReducer";
import { CloseOutlined, DislikeTwoTone, LikeTwoTone } from "@ant-design/icons";
import DeepdiveDrawer from "components/common/drawer/DeepdiveDrawer";
import { clearQualityRFTData } from "reducers/qualityBaseView/qualityRFTReducers";
import { clearQualityGXPData } from "reducers/qualityDeepDive/gxpReducer";
import { clearQualityMinorDevData } from "reducers/qualityDeepDive/minorDevDeepDiveReducer";
import { clearQualityMajorDevData } from "reducers/qualityDeepDive/majorDevDeepDiveReducer";
import { StringKeyDataProps } from "utils/data-types";
import OneClickReport from "./one-click-report";

interface WorkCenterTeamType {
  permissions: PermissionType[];
  setNavList: (navList: NavLink) => void;
}

interface CustomEventDetail {
  tabKey: string;
}

interface HeaderDropDownType {
  key: string;
  label: string;
}

interface ShiftType {
  key?: number;
  label?: string;
  manager?: ManagerType[];
}

interface ManagerType {
  key: number;
  label: string;
}

interface CostCenterType {
  name: string;
}

function getFormattedMonthYear() {
  const now = new Date();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const month = monthNames[now.getMonth()];
  const year = now.getFullYear();

  return `${month}-${year}`;
}

interface SeeCardItem {
  date?: string;
  see_card_last_5_days?: number | string;
  see_card_last_30_days?: number | string;
}

interface SeeCardItem {
  date?: string;
  see_card_last_5_days?: number | string;
  see_card_last_30_days?: number | string;
}

interface ConvertedData {
  name?: string;
  value?: number | string;
  thresholdReferenceValue?: string;
}

interface ehssGraphItem {
  compliance_date?: string | number;
  cumulative_overdue?: number | string;
  cumulative_on_track?: number | string;
  compliance_rate?: number | string;
}

const initialDeepDiveTabItems: DeepDiveTabItemsType = {
  Safety: wctSafetyDeepdiveNavigation,
  Quality: Object.values(wctQualityDeepdiveNavigation),
  Delivery: [],
  Inventory: [],
  Productivity: []
}

const convertSeeCardData = (data: SeeCardItem[]): ConvertedData[] => {
  return data.map((item) => {
    const date = new Date(item?.date ?? "");
    const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "2-digit",
    };
    const name = date
      .toLocaleDateString("en-US", options)
      .replace(/\s/g, " ")
      .toUpperCase();
    return {
      name,
      value:
        item.see_card_last_5_days === ""
          ? item.see_card_last_30_days
          : item.see_card_last_5_days,
    };
  });
}

const ehssConvertedData = (data: ehssGraphItem[]): ConvertedData[] => {
  const sortedData = [...data].sort((a, b) => {
    if (
      typeof a.compliance_date === "string" &&
      typeof b.compliance_date === "string"
    ) {
      const prevDate = new Date(a.compliance_date);
      const nextDate = new Date(b.compliance_date);
      const monthDifference = prevDate.getMonth() - nextDate.getMonth();
      if (monthDifference !== 0) {
        return monthDifference;
      }
      return prevDate.getDate() - nextDate.getDate();
    }
    return 0;
  });
  const datas = sortedData.map((item) => {
    if (typeof item.compliance_date === "string") {
      const dateFormat = new Date(item.compliance_date).toLocaleString(
        "en-US",
        { month: "short", day: "numeric" }
      );
      return {
        name: dateFormat,
        value: item.compliance_rate === null ? 0 : item.compliance_rate,
      };
    }
    return { name: "", value: "" };
  });
  return datas;
};

const WorkCenterTeam = (props: WorkCenterTeamType) => {
  const dispatch = useDispatch<AppDispatch>();
  const { tierInfo, loading, error } = useSelector((state: RootState) => state.tierInfo);
  const { calendarInfo } = useSelector((state: RootState) => state.calendar);
  const { workOrderInfo } = useSelector((state: RootState) => state.workOrder);
  const { incaInfo } = useSelector((state: RootState) => state.incaInfo);
  const { capaInfo } = useSelector((state: RootState) => state.capaInfo);
  const { ehssInfo } = useSelector((state: RootState) => state.ehssInfo);
  const { seeCardsInfo } = useSelector((state: RootState) => state.seeCardsInfo);
  const { lineInfo } = useSelector((state: RootState) => state.lineInfo);
  const { seeCardsDeepDiveInfo } = useSelector((state: RootState) => state.seeCardsDeepDive)
  const { incaDeepDiveInfo } = useSelector((state: RootState) => state.incaDeepDive)
  const { workOrderDeepDiveInfo } = useSelector((state: RootState) => state.workOrderDeepDive)
  const { ehssDeepDiveInfo } = useSelector((state: RootState) => state.ehssDeepDive)
  const { capaDeepDiveInfo } = useSelector((state: RootState) => state.capaDeepDive)
  const { qualityChangeControlInfo } = useSelector((state: RootState) => state.qualityChangeControlInfo)
  const { qualityCAPAInfo } = useSelector((state: RootState) => state.qualityCAPAInfo)
  const { minorDeviationInfo } = useSelector((state: RootState) => state.minorDeviation);
  const { majorDeviationInfo } = useSelector((state: RootState) => state.majorDeviation);
  const { gmpTrainingInfo } = useSelector((state: RootState) => state.gmpTraining);
  const { minorDeviationDeepDiveInfo } = useSelector((state: RootState) => state.minorDeviationDeepDive);
  const { majorDeviationDeepDiveInfo } = useSelector((state: RootState) => state.majorDeviationDeepDive);
  const { changeControlDeepDiveInfo } = useSelector((state: RootState) => state.changeControlDeepdiveInfo);
  const { overallSummaryInfo, isBaseSummaryLoading } = useSelector((state: RootState) => state.overallSummary);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { deepdiveSummaryInfo } = useSelector((state: RootState) => state.deepdiveSummary);
  const { gxpDeepDiveInfo } = useSelector((state: RootState) => state.qualityGxpDeepDiveInfo)
  const { qualityRFTInfo } = useSelector((state: RootState) => state.qualityRFTInfo)
  const { qualityCapaDeepDiveInfo } = useSelector((state: RootState) => state.qualityCapaDeepDiveInfo)

  const {
    dropContainer,
    wctContainer,
    wctHeaderContainer,
    notificationBar,
    tabHeader,
    wctContentContainer,
    sqdipBtn,
    customSubCard,
  } = wctCss;
  const { appState } = useContext(AppContext);
  const { permissions, setNavList } = props;
  const { site } = useParams();
  const location = useLocation();
  const [persona, setPersona] = useState<string>("Work Center Team");
  const headerRef = useRef<HTMLDivElement>(null);
  const aiNotificationRef = useRef<HTMLDivElement>(null);
  const paragraphRef = useRef<HTMLParagraphElement>(null);
  const [activeKey, setActiveKey] = useState<string>("tab-1");
  const [showMore, setShowMore] = useState<boolean>(false);
  const [tier, setTier] = useState(tierInfo?.tiers[0]);
  const [tierLabel, setTierLabel] = useState("Tier 0");
  const [shifts, setShifts] = useState<ShiftType[]>();
  const [currentShift, setCurrentShift] = useState<ShiftType>();
  const [currentLine, setCurrentLine] = useState(lineInfo?.lines ? lineInfo?.lines[0]?.line : "");
  const [building, setBuilding] = useState(lineInfo?.lines ? lineInfo?.lines[0]?.buildingName : "");
  const [costCenters, setCostCenter] = useState<CostCenterType[]>();
  const [currentCostCenter, setCurrentCostCenter] = useState(
    lineInfo?.lines && lineInfo?.lines[0]?.costCenters
      ? lineInfo?.lines[0]?.costCenters[0]?.name
      : ""
  );
  const [metrics, setMetric] = useState(tierInfo?.tiers[0]?.metricName)
  const [ehssData, setEhssData] = useState({});
  const [seeCardData, setSeeCardsData] = useState({});
  const [redDates, setRedDates] = useState<number[]>();
  const [greenDates, setGreenDates] = useState<number[]>()
  const [isSeeCardGraph, setSeecardGraph] = useState(false);
  const [seecardGData, setSeecardGraphData] = useState([{}]);
  const [isEHSSGraph, setEHSSGraph] = useState(false);
  const [ehssGData, setEhssGraphData] = useState([{}])
  const [isSeeCardsOverdue, setIsSeeCardsOverdue] = useState<"error" | "success">("success");
  const [isEHSSOverdue, setIsEHSSOverdue] = useState<"error" | "success">("error");
  const [isTarget, setTarget] = useState(100);
  const [ehssTipContent, setEhssTipContent] = useState("");
  const [seeCardTipContent, setseeCardTipContent] = useState("");

  // set deep dive generic data
  const [deepDiveTabItems, setDeepDiveTabItems] = useState<DeepDiveTabItemsType>(initialDeepDiveTabItems)
  // Deep Dive State
  const [isSafetyOpenDeepDive, setIsSafetyOpenDeepDive] = useState(false);
  const [activeSafetyKeyDeepDiveTab, setActiveSafetyKeyDeepDiveTab] = useState<string>(wctSafetyDeepdiveNavigation[0]);
  // quality Deep Dive Status
  const [isQualityOpenDeepDive, setIsQualityOpenDeepDive] = useState(false);
  const [activeQualityKeyDeepDiveTab, setActiveQualityKeyDeepDiveTab] = useState<string>(deepDiveTabItems.Quality[0])

  const [deepDiveAlert, setDeepDiveAlert] = useState("");
  const [summaryId, setSummaryId] = useState("");
  const [summaryMetric, setSummaryMetric] = useState("");

  const summaryComponent = () => {
    const loopData = !showMore
      ? overallSummaryInfo?.data?.slice(0, 1)
      : overallSummaryInfo?.data;
    return (
      <div>
        {loopData?.map((item: any, index: any) => (
          <div
            className="d-flex align-items-center"
            style={{
              fontSize: "12px",
              width: "98%",
              marginLeft: "5px",
              marginTop: index === 0 ? "0px" : "5px",
              paddingTop: index === 0 ? "0px" : "5px",
              borderTop: index === 0 ? "none" : "1px solid #d9d9d9",
            }}
            key={index}
          >
            <div
              className="d-flex"
              title="Submit Feedback"
              style={{ marginRight: "20px" }}
            >
              <LikeTwoTone
                twoToneColor="#52c41a"
                style={{ fontSize: "18px" }}
              />
              <DislikeTwoTone
                twoToneColor="#E01029"
                style={{
                  fontSize: "18px",
                  marginLeft: "10px",
                }}
              />
            </div>
            <div style={{ width: "78px", marginRight: "40px", fontWeight: 700, fontSize: "16px", textAlign: 'center' }}>
              {item?.metric?.charAt(0).toUpperCase() + item?.metric?.slice(1)?.toLowerCase()}
            </div>
            <div
              style={{ width: "71%", marginRight: "40px" }}
              dangerouslySetInnerHTML={{ __html: item?.summary }}
            ></div>
            <Button
              className={sqdipBtn}
              label="Deep Dive"
              type="secondary"
              onClick={() => {
                setIsDrawerOpen(true);
                setDeepDiveAlert(item?.summary);
                setSummaryId(item?.id);
                setSummaryMetric(item?.metric.toLowerCase())
              }}
            />
            <CloseOutlined title="Disable Alert" style={{ marginLeft: "20px", fontSize: "16px" }} />
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    const redDates: number[] = [];
    const greenDates: number[] = [];
    calendarInfo?.calendar.map((item) => {
      return (
        (item.overdue_status === "G" && greenDates.push(item.date)) ||
        redDates.push(item.date)
      );
    });

    setRedDates(redDates);
    setGreenDates(greenDates);
  }, [calendarInfo])

  useEffect(() => {
    const EhssSanitizedData: StringKeyDataProps = {};
    let lineCompliancePercentage;
    let managerName;
    let managerStaffId;
    let target;
    let overdue;
    let pending;
    let shiftCompliancePercentage;
    let costCenterName;
    let costCenterCode;
    let costCenterCompliancePercentage;
    let siteCompliancePercentage;
    let complianceDate;
    let complianceRate;
    let cumulativeOverdue;
    if (ehssInfo?.data?.length) {
      managerName = ehssInfo?.data[0]?.manager_name;
      managerStaffId = ehssInfo?.data[0]?.manager_staff_id;
      target = ehssInfo?.data[0]?.target;
      overdue = ehssInfo?.data[0]?.overdue;
      pending = ehssInfo?.data[0]?.pending;
      costCenterName = ehssInfo?.data[0]?.cost_center_name;
      costCenterCode = ehssInfo?.data[0]?.cost_center_code;
    }
    if (ehssInfo?.compliance_percentage?.length) {
      lineCompliancePercentage = Number(
        Number(
          ehssInfo?.compliance_percentage?.[0]?.compliance_percentage
        ).toFixed(2)
      );
      shiftCompliancePercentage = Number(
        Number(ehssInfo?.compliance_percentage?.[0]?.shift_compliance).toFixed(
          2
        )
      );
      costCenterCompliancePercentage = Number(
        Number(
          ehssInfo?.compliance_percentage?.[0]?.cost_center_compliance
        ).toFixed(2)
      );
    }
    if (ehssInfo?.site_compliance?.length) {
      siteCompliancePercentage = Number(
        Number(ehssInfo?.site_compliance?.[0]?.aoh_compliance).toFixed(2)
      );
    }
    if (ehssInfo?.graph_data?.length) {
      complianceDate = ehssInfo?.graph_data[0]?.compliance_date;
      complianceRate = ehssInfo?.graph_data[0]?.compliance_rate;
      cumulativeOverdue = ehssInfo?.graph_data[0]?.cumulative_overdue;
    }
    if (
      typeof lineCompliancePercentage === "number" &&
      lineCompliancePercentage >= 0
    ) {
      EhssSanitizedData["LINE COMPLIANCE %"] = lineCompliancePercentage;
    }
    if (
      typeof shiftCompliancePercentage === "number" &&
      shiftCompliancePercentage >= 0
    ) {
      EhssSanitizedData["SHIFT COMPLIANCE %"] = shiftCompliancePercentage;
    }
    if (tierLabel.toLowerCase() !== "tier 3") {
      if (
        typeof costCenterCompliancePercentage === "number" &&
        costCenterCompliancePercentage >= 0
      ) {
        EhssSanitizedData["BUILDING COMPLIANCE %"] =
          costCenterCompliancePercentage;
      }
    }
    if (
      typeof siteCompliancePercentage === "number" &&
      siteCompliancePercentage >= 0
    ) {
      EhssSanitizedData["SITE COMPLIANCE %"] = siteCompliancePercentage;
    }
    ehssInfo?.data?.forEach((item, index) => {
      if (typeof item.la_name === "string") {
        EhssSanitizedData[" " + "&" + item.la_name] = item.pending ?? "";
      }
    });
    ehssInfo?.data?.forEach((item, index) => {
      if (typeof item.manager_name === "string") {
        EhssSanitizedData[item.manager_name] = item.pending ?? "";
      }
    });
    ehssInfo?.data?.forEach((item, index) => {
      if (typeof item.cost_center_name === "string") {
        EhssSanitizedData[item.cost_center_name] = item.overdue ?? "";
      }
    });

    if (
      tierLabel?.toLowerCase() === "tier 0" ||
      tierLabel?.toLowerCase() === "tier 1" ||
      tierLabel?.toLowerCase() === "tier 2"
    ) {
      setEhssTipContent("% Compliance each day over last 5 Days");
    } else if (tierLabel?.toLowerCase() === "tier 3") {
      setEhssTipContent("% Compliance each week over last 6 Months");
    }
    if (
      tierLabel?.toLowerCase() === "tier 0" &&
      typeof lineCompliancePercentage === "number" &&
      lineCompliancePercentage <= 95
    ) {
      setIsEHSSOverdue("error");
    } else if (
      tierLabel?.toLowerCase() === "tier 1" &&
      typeof shiftCompliancePercentage === "number" &&
      shiftCompliancePercentage <= 95
    ) {
      setIsEHSSOverdue("error");
    } else if (
      tierLabel?.toLowerCase() === "tier 2" &&
      typeof costCenterCompliancePercentage === "number" &&
      costCenterCompliancePercentage <= 95
    ) {
      setIsEHSSOverdue("error");
    } else if (
      tierLabel?.toLowerCase() === "tier 3" &&
      typeof siteCompliancePercentage === "number" &&
      siteCompliancePercentage <= 95
    ) {
      setIsEHSSOverdue("error");
    } else {
      setIsEHSSOverdue("success");
    }

    if (ehssInfo?.graph_data?.length) {
      const outputData = ehssConvertedData(ehssInfo?.graph_data);
      setEhssGraphData(outputData);
      setEHSSGraph(true);
    } else {
      setEhssGraphData([]);
      setEHSSGraph(false);
    }
    setEhssData(EhssSanitizedData);
  }, [ehssInfo]);

  useEffect(() => {
    const seeCardsSanitizedData: StringKeyDataProps = {};
    let seeCardOverDue;
    let seeCardsLast24hours;
    let seeCardsComingDueIn5Day;
    let seeCardsComingDueIn2Day;
    if (seeCardsInfo?.seeCards?.overdues?.length) {
      seeCardOverDue = seeCardsInfo?.seeCards?.overdues[0]?.total_overdue;
      seeCardsLast24hours =
        seeCardsInfo?.seeCards?.overdues[0]?.see_cards_last_24hours;
      seeCardsComingDueIn5Day =
        seeCardsInfo?.seeCards?.overdues[0]?.total_coming_due_in_5days;
      seeCardsComingDueIn2Day =
        seeCardsInfo?.seeCards?.overdues[0]?.total_coming_due_in_2days;
    }

    if (typeof seeCardOverDue === "number" && seeCardOverDue >= 0) {
      seeCardsSanitizedData.OVERDUE = seeCardOverDue;
    }

    if (seeCardsInfo?.seeCards?.areaAlert) {
      if (
        typeof seeCardsInfo?.seeCards?.areaAlert[0]?.count === "number" &&
        seeCardsInfo?.seeCards?.areaAlert[0]?.count >= 0
      ) {
        const alertArea = seeCardsInfo?.seeCards?.areaAlert[0]?.count;
        seeCardsSanitizedData["AREA ALERT"] = alertArea;
      } else {
        seeCardsSanitizedData["AREA ALERT"] = "";
        if (seeCardsInfo?.seeCards?.areaAlert[0]?.employee_cost_center_name) {
          seeCardsSanitizedData[
            seeCardsInfo?.seeCards?.areaAlert[0]?.employee_cost_center_name
          ] = seeCardsInfo?.seeCards?.areaAlert[0]?.see_card_count ?? 0;
        }
        if (seeCardsInfo?.seeCards?.areaAlert[1]?.employee_cost_center_name) {
          seeCardsSanitizedData[
            seeCardsInfo?.seeCards?.areaAlert[1]?.employee_cost_center_name
          ] = seeCardsInfo?.seeCards?.areaAlert[1]?.see_card_count ?? 0;
        }
      }
    }

    if (typeof seeCardsLast24hours === "number" && seeCardsLast24hours >= 0) {
      seeCardsSanitizedData["SEE CARDS LAST 24 HOURS"] = seeCardsLast24hours;
    }

    if (
      typeof seeCardsComingDueIn5Day === "number" &&
      seeCardsComingDueIn5Day >= 0
    ) {
      seeCardsSanitizedData["COMING DUE IN 5 DAYS"] = seeCardsComingDueIn5Day;
    }

    if (
      typeof seeCardsComingDueIn2Day === "number" &&
      seeCardsComingDueIn2Day >= 0
    ) {
      seeCardsSanitizedData["COMING DUE (2 DAYS)"] = seeCardsComingDueIn2Day;
    }

    setSeeCardsData(seeCardsSanitizedData);

    if (
      tierLabel?.toLowerCase() === "tier 1" ||
      tierLabel?.toLowerCase() === "tier 2"
    ) {
      setseeCardTipContent("Number of SEE Cards Created in last 5 days");
    } else if (tierLabel?.toLowerCase() === "tier 3") {
      setseeCardTipContent("Number of SEE Cards Created in last 30 days");
    }

    if (
      (tierLabel?.toLowerCase() === "tier 1" ||
        tierLabel?.toLowerCase() === "tier 2") &&
      typeof seeCardOverDue === "number" &&
      seeCardOverDue > 0
    ) {
      setIsSeeCardsOverdue("error");
    } else {
      setIsSeeCardsOverdue("success");
    }

    if (
      tierLabel?.toLowerCase() !== "tier 0" &&
      seeCardsInfo?.seeCards?.overduesPerDay?.length
    ) {
      const inputData1: SeeCardItem[] = seeCardsInfo?.seeCards?.overduesPerDay;
      const outputData: ConvertedData[] = convertSeeCardData(inputData1);
      setSeecardGraphData(outputData);
      setSeecardGraph(true);
    } else {
      setSeecardGraphData([]);
      setSeecardGraph(false);
    }
  }, [seeCardsInfo])

  const tierDropDownData: HeaderDropDownType[] =
    tierInfo?.tiers?.map((tier) => {
      return {
        key: tier?.label,
        label: tier?.label,
      };
    }) ?? [];

  const lineDropDownData: HeaderDropDownType[] =
    lineInfo?.lines?.map((line) => {
      return {
        key: line?.line ?? "",
        label: line?.line ?? "",
      };
    }) ?? [];

  const buildingDropDownData: HeaderDropDownType[] =
    lineInfo?.lines?.map((line) => {
      return {
        key: line?.buildingName ?? "",
        label: line?.buildingName ?? "",
      };
    }) ?? [];

  const shiftDropDownData: HeaderDropDownType[] =
    shifts?.map((shift) => {
      return {
        key: shift?.label ?? "",
        label: shift?.label ?? "",
      };
    }) ?? [];

  const handleTierClick: MenuProps["onClick"] = (e) => {
    const latestTierInfo = tierInfo?.tiers.filter(
      (tier) => tier?.label === e?.key
    )[0];
    if (latestTierInfo) {
      setTier(latestTierInfo);
      setTierLabel(latestTierInfo.label);
      setMetric(latestTierInfo?.metricName);
    }
  };

  const handleShiftClick: MenuProps["onClick"] = (e) => {
    const latestShiftInfo: ShiftType | undefined = shifts?.filter(
      (shift) => shift?.label === e?.key
    )[0];
    if (latestShiftInfo) {
      setCurrentShift(latestShiftInfo);
    }
  };

  const handleLineClick: MenuProps["onClick"] = (e) => {
    const latestLineInfo = lineInfo?.lines?.filter(
      (line) => line?.line === e?.key
    )[0];
    if (latestLineInfo) {
      setCurrentLine(latestLineInfo.line);
    }
  };

  const handleCostCenter = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget.textContent) {
      setCurrentCostCenter(e.currentTarget.textContent);
    }
  };

  const content = (
    <div style={{ maxHeight: "240px", overflowY: "auto" }}>
      {costCenters
        ? costCenters.map((item) => {
          return (
            <div
              key={item.name}
              onClick={handleCostCenter}
              className={wctCss.showCCDrop}
            >
              {item.name}
            </div>
          );
        })
        : "Loading..."}
    </div>
  );

  const handleBuildingClick: MenuProps["onClick"] = (e) => {
    const latestBuildingInfo = lineInfo?.lines?.filter(
      (line) => line?.buildingName === e?.key
    )[0];
    if (latestBuildingInfo) {
      setBuilding(latestBuildingInfo.line);
    }
  };

  const handleShowMoreClick = () => {
    setShowMore((prevState: boolean) => !prevState);
    if (paragraphRef.current) {
      paragraphRef.current.scrollTop = 0; // Scroll to the top
    }
  };

  const adjustHeaderWidth = () => {
    if (headerRef.current && aiNotificationRef.current) {
      headerRef.current.style.width = `${aiNotificationRef.current.clientWidth + 10
        }px`;
    }
  };

  const adjustHeaderHeight = () => {
    if (aiNotificationRef.current && tier?.label.toLowerCase() === "tier 3") {
      aiNotificationRef.current.style.marginTop = `${customCss.tier3MarginTop}px`;
    } else if (aiNotificationRef.current) {
      aiNotificationRef.current.style.marginTop = `${customCss.defaultMarginTop}px`;
    }
  };

  useEffect(() => {
    if (lineInfo?.lines) {
      if (lineInfo?.lines[0]?.line) {
        setCurrentLine(lineInfo?.lines[0]?.line);
      }
      setShifts(lineInfo?.lines[0]?.shift);
      setBuilding(lineInfo?.lines[0]?.buildingName);
    }
  }, [lineInfo?.lines]);

  useEffect(() => {
    if (lineInfo?.lines) {
      const buildingData = lineInfo?.lines.find(
        (item) => item.buildingName === building
      );
      setCostCenter(buildingData?.costCenters);
    }
  }, [building]);

  useEffect(() => {
    setTier(tierInfo?.tiers[0]);
    setMetric(tierInfo?.tiers[tier?.key ?? 0]?.metricName);
    const params = {
      tierNumber: tierInfo?.tiers[tier?.key ?? 0]?.label.toLowerCase(),
    };
    if (tierInfo) dispatch(fetchLineInfo(params));
  }, [tierInfo?.tiers]);

  useEffect(() => {
    if (shifts && shifts.length > 0) {
      setCurrentShift(shifts[0]);
    }
  }, [shifts]);

  useEffect(() => {
    dispatch(fetchTierInfo());
  }, []);

  const buildingNameArr =
    lineInfo &&
      Array.isArray(lineInfo.lines) &&
      lineInfo.lines.length > 0 &&
      lineInfo.lines[0].buildingName
      ? lineInfo.lines[0].buildingName
      : "AOH1";

  useEffect(() => {
    if (tierInfo) {
      const params = {
        tierNumber: tierInfo?.tiers[tier?.key ?? 0]?.label.toLowerCase(),
      };
      dispatch(fetchLineInfo(params));
    }
  }, [tierLabel, tierInfo])

  useEffect(() => {
    const safetyTabItems = tierLabel.includes('Tier 0') ? Object.values(wctSafetyDeepdiveNavigation).slice(0, 4) : Object.values(wctSafetyDeepdiveNavigation);

    const qualityTabItems: string[] = Object.values(wctQualityDeepdiveNavigation).map((enumValue) => {
      if (metrics?.find(({ label }) => enumValue === label)) {
        return enumValue as string
      }
      return ''
    }).filter(val => val !== undefined && val !== "") ?? Object.values(wctQualityDeepdiveNavigation)

    setDeepDiveTabItems(prev => ({
      ...prev,
      Safety: safetyTabItems,
      Quality: qualityTabItems
    }))

    setActiveSafetyKeyDeepDiveTab(safetyTabItems[0])
    setActiveQualityKeyDeepDiveTab(qualityTabItems[0])
  }, [metrics, tierLabel])

  useEffect(() => {
    const shift = currentShift?.label;
    const costCenter = currentCostCenter;
    const line = currentLine;
    if (shift ?? costCenter) {
      const updatedParams = {
        workStream: "manufacturing",
        site: "aoh",
        persona: "wct",
        tierNumber: tierInfo?.tiers[tier?.key ?? 0]?.label.toLowerCase() ?? "",
        line: line ?? "",
        shift: shift ?? "",
        interval: 7,
        costCenter: costCenter?.split("-")[0] ?? "",
        building: buildingNameArr ?? "AOH1",
      };

      dispatch(clearQualityRFTData())
      dispatch(clearEhssData())
      dispatch(clearCalendarData())
      dispatch(clearworkOrderData())
      dispatch(clearIncaData())
      dispatch(clearcapaData())
      dispatch(clearEhssData())
      dispatch(clearSeeCardsData())
      dispatch(clearEhssData());
      dispatch(clearCalendarData());
      dispatch(clearworkOrderData());
      dispatch(clearIncaData());
      dispatch(clearcapaData());
      dispatch(clearEhssData());
      dispatch(clearSeeCardsData());
      dispatch(clearMajorDeviationData());
      dispatch(clearMinorDeviationData());
      dispatch(clearGmpTrainingData());
      dispatch(clearQualityGXPData());
      dispatch(clearQualityMinorDevData());
      dispatch(clearQualityMajorDevData());

      dispatch(updateCustomMetricParams(updatedParams))
      dispatch(fetchCalendar(updatedParams));
      dispatch(fetchOverallSummary(updatedParams));
      dispatch(fetchDeepdiveSummary(updatedParams))
    }
  }, [tierLabel, currentShift, currentCostCenter, currentLine, tierInfo])

  useEffect(() => {
    adjustHeaderHeight();
    window.addEventListener("resize", adjustHeaderWidth);

    return () => {
      window.removeEventListener("resize", adjustHeaderHeight);
    };
  }, [tier]);

  useEffect(() => {
    setTimeout(() => {
      adjustHeaderWidth();
      window.addEventListener("resize", adjustHeaderWidth);
    }, 450);

    return () => {
      window.removeEventListener("resize", adjustHeaderWidth);
    };
  }, [appState.navActive]);

  const mfgItems = [
    {
      key: "1",
      label: <a href="/manufacturing">Manufacturing</a>,
    },
  ];

  const avaialableSites: Record<string, boolean> = Object.fromEntries(
    Object.entries(permissionObject).filter(
      ([, value]: [string, boolean]) => value
    )
  );

  const siteItems: BreadcrumbMenuItemType[] = Object.entries(
    avaialableSites
  ).map(([key], index) => {
    return {
      key: `${index + 1}`,
      label: (
        <a
          rel="noopener noreferrer"
          key={`${key}-menu-${index + 1}`}
          href={`/manufacturing/${key}`}
        >
          {key.toUpperCase()}
        </a>
      ),
    };
  });

  const personaObject: PersonasAuthorization | undefined =
    permissions[1]?.workstreams?.mct?.filter(
      (permission: MCT) => permission.url === site
    )[0]?.personas;

  const personaItems = Object.entries(personaObject ? personaObject : {})
    .filter(([key, value]) => {
      if (key === "eTOC" && value) {
        return false;
      } else {
        return true;
      }
    })
    ?.map(([key], index) => {
      const persona = personas.filter((persona) => persona.name === key)[0];

      return {
        key: `${index + 1}`,
        label: (
          <a
            rel="noopener noreferrer"
            href={`/manufacturing/${site}/${persona?.url}`}
          >
            {persona?.label}
          </a>
        ),
      };
    });

  useEffect(() => {
    setNavList({
      label: site?.toUpperCase() ?? "",
      authorized: true,
      externalSections: [],
      internalSections: [
        {
          sectionLabel: "WORK CENTER TEAM",
          links: wctNavigation.map((sideNavLabel: string, index: number) => {
            return {
              label: sideNavLabel,
              componentKey: `tab-${index + 1}`,
            };
          }),
        },
        {
          sectionLabel: "Shop Floor",
          links: [
            {
              label: "All Lines",
            },
            {
              label: "Line 1",
            },
            {
              label: "Line 2",
            },
          ],
        },
        {
          sectionLabel: "Learners/eTOC",
          links: [
            {
              label: `eTOC ${site?.toUpperCase()} Home`,
            },
            {
              label: "Menu Item 2",
            },
            {
              label: "Menu Item 3",
            },
          ],
        },
      ],
    })

    const handleCustomEvent = (event: CustomEvent<CustomEventDetail>) => {
      setActiveKey(event.detail.tabKey);
    };

    document.addEventListener(
      "sideNavClick",
      handleCustomEvent as EventListener
    );

    return () => {
      document.removeEventListener(
        "sideNavClick",
        handleCustomEvent as EventListener
      );
    };
  }, []);

  useEffect(() => {
    if (location.pathname.includes("shop-floor")) {
      setPersona("Shop Floor");
    } else {
      setPersona("Work Center Team");
    }
  }, [location]);

  const notificationCounts: Record<string, number> = {
    SQDIP: 0,
    "Tier Communications": 5,
    "Issue Tracking and Countermeasures": 3,
    Administrator: 0,
  };

  const items = wctNavigation.map((sideNavLabel, index) => {
    if (notificationCounts[sideNavLabel] > 0) {
      return {
        key: `tab-${index + 1}`,
        label: (
          <div className={wctCss.tabTitle}>
            {sideNavLabel}
            <Avatar
              className={wctCss.notificationNumber}
              style={{ backgroundColor: "black", verticalAlign: "middle" }}
              size="small"
            >
              {notificationCounts[sideNavLabel]}
            </Avatar>
          </div>
        ),
      };
    } else {
      return {
        key: `tab-${index + 1}`,
        label: <div className={wctCss.tabTitle}>{sideNavLabel}</div>,
      };
    }
  });

  const deepDiveData: Record<string, any | null> = {
    "SEE Cards": seeCardsDeepDiveInfo,
    "INCA Incidents": incaDeepDiveInfo,
    "Safety Critical Work Orders": workOrderDeepDiveInfo,
    "EHSS Training Compliance": ehssDeepDiveInfo,
    "Safety Critical CAPAs": capaDeepDiveInfo,
  };

  const qualityDeepDiveData: Record<string, any | null> = {
    "GXP Training %Compliance": gxpDeepDiveInfo,
    "Change Control Status": changeControlDeepDiveInfo,
    "Minor Deviation": minorDeviationDeepDiveInfo,
    "Major Deviation": majorDeviationDeepDiveInfo,
    "CAPA Status": qualityCapaDeepDiveInfo,
  };

  return (
    <PageLayout>
      <div className={wctContainer}>
        <div className={wctHeaderContainer} ref={headerRef}>
          <ConfigProvider theme={{ token: { fontSize: 12 } }}>
            <Breadcrumb
              className="breadcrumb sf-breadcrumb"
              items={[
                {
                  title: <a href="/">Digital Control Tower</a>,
                },
                {
                  title: <span>Manufacturing</span>,
                  menu: { items: mfgItems },
                },
                {
                  title: <span>{site?.toUpperCase()}</span>,
                  menu: { items: siteItems },
                },
                {
                  title: <span>{persona}</span>,
                  menu: { items: personaItems },
                },
              ]}
            />
          </ConfigProvider>
          {loading || error ? (
            <div className="shop-floor-loader">
              <ErrorHandler
                isLoading={loading}
                isError={error}
                loaderColor={"BLUE"}
              />
            </div>
          ) : (
            <div>
              <Header
                items={tierDropDownData}
                handleMenuClick={handleTierClick}
                title="TIER"
                dropOptions={tier?.label}
                activeKey={activeKey}
              />
              {tier?.label.toLowerCase() !== "tier 3" && activeKey !== "tab-5" && (
                <div className={dropContainer}>
                  {tier?.label.toLowerCase() === "tier 0" && (
                    <AohDropdown
                      items={lineDropDownData}
                      title="LINE"
                      dropOptions={currentLine}
                      handleMenuClick={handleLineClick}
                    />
                  )}
                  {(tier?.label.toLowerCase() === "tier 0" ||
                    tier?.label.toLowerCase() === "tier 1") && (
                      <AohDropdown
                        items={shiftDropDownData}
                        title="SHIFT"
                        dropOptions={currentShift?.label}
                        handleMenuClick={handleShiftClick}
                      />
                    )}
                  {tier?.label.toLowerCase() === "tier 2" && (
                    <div className={wctCss.buildingContainer}>
                      <AohDropdown
                        items={buildingDropDownData}
                        title="BUILDING"
                        dropOptions={building}
                        handleMenuClick={handleBuildingClick}
                      />
                      <Popover
                        placement="bottomRight"
                        title={<span className={wctCss.showCCHeader}>APPLICABLE COST CENTERS</span>}
                        content={content}
                      >
                        <div className={wctCss.showCC}>SHOW CC</div>
                      </Popover>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          <Tabs
            className={tabHeader}
            activeKey={activeKey}
            onChange={setActiveKey}
            defaultActiveKey={activeKey}
            items={items}
          />
        </div>
        <DeepdiveDrawer
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          deepDiveAlert={deepDiveAlert}
          tier={tier?.label}
          metrics={summaryMetric}
          id={summaryId}
        />
        {activeKey === "tab-5" && <OneClickReport />}
        {activeKey !== "tab-5" && <div
          className={notificationBar}
          ref={aiNotificationRef}
          id="sqdip-ai-insights-summary"
          style={{marginTop: tier?.label.toLowerCase() === "tier 3" ? "190px" : "240px"}}
        >
          <div className="d-flex" style={{ width: "100%" }}>
            <AIGeneratedIcon className={wctCss.aiSVGIcon} />
            {isBaseSummaryLoading ? (
              <div className="summary-loader">
                {" "}
                <Loader />{" "}
              </div>
            ) : (
              <div
                className="d-flex flex-column"
                style={{ maxHeight: "238px", overflowY: "auto", width: "100%" }}
              >
                {summaryComponent()}
              </div>
            )}
          </div>
          <div className={wctCss.buttonText} onClick={handleShowMoreClick}>
            {showMore ? "Show Less" : "Show More"}
          </div>
        </div>}
        {activeKey !== "tab-5" && <div className={wctContentContainer}>
          <div className={wctCss.cardContainer}>
            <Alert
              closable={false}
              showIcon
              icon={
                incaInfo?.cardProps.alertType === "success" &&
                  isSeeCardsOverdue === "success" &&
                  workOrderInfo?.cardProps.alertType === "success" &&
                  isEHSSOverdue === "success" &&
                  capaInfo?.cardProps.alertType === "success" ? (
                  <StatusPositiveIcon
                    fill={"#2CC84D"}
                    height={"20"}
                    width={"20"}
                  />
                ) : (
                  <StatusIssueIcon
                    fill={"var(--ops-sem-red-primary)"}
                    height={"20"}
                    width={"20"}
                  />
                )
              }
              message={wctMetrics[0]}
              type={`${incaInfo?.cardProps.alertType === "success" &&
                isSeeCardsOverdue === "success" &&
                workOrderInfo?.cardProps.alertType === "success" &&
                isEHSSOverdue === "success" &&
                capaInfo?.cardProps.alertType === "success"
                ? "success"
                : "error"
                }`}
              action={
                <Button
                  className={sqdipBtn}
                  label="View"
                  onClick={() => {
                    setIsSafetyOpenDeepDive(true);
                  }}
                  type="secondary"
                />
              }
            />

            <CustomMetricCard
              title={`${getFormattedMonthYear()} Summary`}
              isCalendar
              greenDates={greenDates}
              redDates={redDates}
              className={customSubCard}
              id="safety-month-summary"
            />
            {metrics &&
              metrics.length > 0 &&
              metrics.map(({ key, label }: { key: number; label: string }) => {
                if (label.toLowerCase() === metricNames?.inca?.toLowerCase()) {
                  return (
                    <CustomMetricCard
                      key={key}
                      title={label}
                      isAlert
                      {...incaInfo?.cardProps}
                      isGrid
                      data={incaInfo?.cardData}
                      {...incaInfo?.graphParams}
                      graphData={incaInfo?.graphData}
                      className={customSubCard}
                      setIsOpenDeepDive={setIsSafetyOpenDeepDive}
                      setActiveKeyDeepDiveTab={(key: string) =>
                        setActiveSafetyKeyDeepDiveTab(key)
                      }
                      id={`safety-${label.toLowerCase().replace(/ /g, "-")}`}
                      actionKey="inca"
                      tierLabel={tierLabel}
                    />
                  );
                }
                if (
                  label.toLowerCase() === metricNames?.seeCards?.toLowerCase()
                ) {
                  return (
                    <CustomMetricCard
                      key={key}
                      title={label}
                      isAlert
                      alertType={isSeeCardsOverdue}
                      isGrid
                      isGraph={isSeeCardGraph}
                      graphData={seecardGData}
                      data={seeCardData}
                      className={customSubCard}
                      tooltipContent={seeCardTipContent}
                      setIsOpenDeepDive={setIsSafetyOpenDeepDive}
                      setActiveKeyDeepDiveTab={(key: string) =>
                        setActiveSafetyKeyDeepDiveTab(key)
                      }
                      id={`safety-${label.toLowerCase().replace(/ /g, "-")}`}
                      actionKey="seecards"
                      tierLabel={tierLabel}
                    />
                  );
                }

                if (
                  label.toLowerCase() ===
                  metricNames?.workorder?.toLowerCase() &&
                  workOrderInfo
                ) {
                  return (
                    <CustomMetricCard
                      key={key}
                      title={label}
                      isAlert
                      {...workOrderInfo?.cardProps}
                      isGrid
                      data={workOrderInfo?.cardData}
                      className={customSubCard}
                      setIsOpenDeepDive={setIsSafetyOpenDeepDive}
                      setActiveKeyDeepDiveTab={(key: string) =>
                        setActiveSafetyKeyDeepDiveTab(key)
                      }
                      id={`safety-${label.toLowerCase().replace(/ /g, "-")}`}
                      actionKey="workorder"
                      tierLabel={tierLabel}
                    />
                  );
                }

                if (label.toLowerCase() === metricNames?.ehss?.toLowerCase()) {
                  return (
                    <CustomMetricCard
                      key={key}
                      title={label}
                      label
                      isAlert
                      alertType={isEHSSOverdue}
                      isGraph={isEHSSGraph}
                      graphData={ehssGData}
                      isGrid
                      data={ehssData}
                      referenceLine={isTarget}
                      className={customSubCard}
                      tooltipContent={ehssTipContent}
                      setIsOpenDeepDive={setIsSafetyOpenDeepDive}
                      setActiveKeyDeepDiveTab={(key: string) =>
                        setActiveSafetyKeyDeepDiveTab(key)
                      }
                      id={`safety-${label.toLowerCase().replace(/ /g, "-")}`}
                      actionKey="ehss"
                      tierLabel={tierLabel}
                    />
                  );
                }

                if (label.toLowerCase() === metricNames?.capa?.toLowerCase()) {
                  return (
                    <CustomMetricCard
                      key={key}
                      title={label}
                      isAlert
                      {...capaInfo?.cardProps}
                      {...capaInfo?.graphParams}
                      graphData={capaInfo?.graphData}
                      isGrid
                      data={capaInfo?.cardData}
                      className={customSubCard}
                      setIsOpenDeepDive={setIsSafetyOpenDeepDive}
                      setActiveKeyDeepDiveTab={(key: string) =>
                        setActiveSafetyKeyDeepDiveTab(key)
                      }
                      id={`safety-${label.toLowerCase().replace(/ /g, "-")}`}
                      actionKey="capa"
                      tierLabel={tierLabel}
                    />
                  );
                }
                return null;
              })}
          </div>

          {/* Quality Base View  */}
          <div id="wct-quality" className={wctCss.cardContainer}>
            <Alert
              closable={false}
              showIcon
              icon={
                minorDeviationInfo?.cardProps.alertType === "success" &&
                  majorDeviationInfo?.cardProps.alertType === "success" &&
                  gmpTrainingInfo?.cardProps.alertType === "success" ? (
                  <StatusPositiveIcon
                    fill={"#2CC84D"}
                    height={"20"}
                    width={"20"}
                  />
                ) : (
                  <StatusIssueIcon
                    fill={"var(--ops-sem-red-primary)"}
                    height={"20"}
                    width={"20"}
                  />
                )
              }
              message={SQDIPType.Quality}
              type={`${minorDeviationInfo?.cardProps.alertType === "success" &&
                majorDeviationInfo?.cardProps.alertType === "success" &&
                gmpTrainingInfo?.cardProps.alertType === "success"
                ? "success"
                : "error"
                }`}
              action={
                <Button
                  className={sqdipBtn}
                  label="View"
                  onClick={() => {
                    setIsQualityOpenDeepDive(true);
                  }}
                  type="secondary"
                />
              }
            />
            <CustomMetricCard
              title={`${getFormattedMonthYear()} Summary`}
              isCalendar
              greenDates={greenDates}
              redDates={redDates}
              className={customSubCard}
              id="safety-month-summary"
            />
            {metrics &&
              metrics.length > 0 &&
              metrics.map(({ key, label }: { key: number; label: string }) => {
                if (
                  label.toLowerCase() ===
                  metricNames?.minorDeviation.toLowerCase()
                ) {
                  return (
                    <CustomMetricCard
                      key={key}
                      title={label}
                      isAlert
                      {...minorDeviationInfo?.cardProps}
                      isGrid
                      data={minorDeviationInfo?.cardData}
                      {...minorDeviationInfo?.graphParams}
                      graphData={minorDeviationInfo?.graphData}
                      className={customSubCard}
                      setIsOpenDeepDive={setIsQualityOpenDeepDive}
                      setActiveKeyDeepDiveTab={(key: string) =>
                        setActiveQualityKeyDeepDiveTab(key)
                      }
                      id={`quality-${label.toLowerCase().replace(/ /g, "-")}`}
                      actionKey="minordeviation"
                      tierLabel={tierLabel}
                    />
                  );
                }
                if (
                  label.toLowerCase() ===
                  metricNames?.majorDeviation.toLowerCase()
                ) {
                  return (
                    <CustomMetricCard
                      key={key}
                      title={label}
                      isAlert
                      {...majorDeviationInfo}
                      isGrid
                      data={majorDeviationInfo?.cardData}
                      {...majorDeviationInfo?.graphParams}
                      graphData={majorDeviationInfo?.graphData}
                      className={customSubCard}
                      setIsOpenDeepDive={setIsQualityOpenDeepDive}
                      setActiveKeyDeepDiveTab={(key: string) =>
                        setActiveQualityKeyDeepDiveTab(key)
                      }
                      id={`quality-${label.toLowerCase().replace(/ /g, "-")}`}
                      actionKey="majordeviation"
                      tierLabel={tierLabel}
                    />
                  );
                }
                if (
                  label.toLowerCase() === metricNames?.gxpTraining.toLowerCase()
                ) {
                  return (
                    <CustomMetricCard
                      key={key}
                      title={label}
                      label
                      isAlert
                      {...gmpTrainingInfo?.cardProps}
                      isGrid
                      data={gmpTrainingInfo?.cardData}
                      graphData={gmpTrainingInfo?.graphData}
                      {...gmpTrainingInfo?.graphParams}
                      className={customSubCard}
                      setIsOpenDeepDive={setIsQualityOpenDeepDive}
                      setActiveKeyDeepDiveTab={(key: string) =>
                        setActiveQualityKeyDeepDiveTab(key)
                      }
                      id={`quality-${label.toLowerCase().replace(/ /g, "-")}`}
                      actionKey="gxptraining"
                      tierLabel={tierLabel}
                    />
                  );
                }
                if (label.toLowerCase() === metricNames?.rft?.toLowerCase()) {
                  return (
                    <CustomMetricCard
                      key={key}
                      title={label}
                      isAlert
                      {...qualityRFTInfo?.cardProps}
                      {...qualityRFTInfo?.graphParams}
                      graphData={qualityRFTInfo?.trendGraphData}
                      isGrid
                      data={qualityRFTInfo?.cardData}
                      className={customSubCard}
                      setIsOpenDeepDive={setIsSafetyOpenDeepDive}
                      setActiveKeyDeepDiveTab={(key: string) =>
                        setActiveSafetyKeyDeepDiveTab(key)
                      }
                      id={`quality-${label.toLowerCase().replace(/ /g, "-")}`}
                      actionKey="rft"
                      tierLabel={tierLabel}
                    />
                  );
                }
                if (label.toLowerCase() === metricNames?.changecontrol) {
                  return (
                    <CustomMetricCard
                      key={"q-cc"}
                      title={label}
                      isAlert
                      isGrid
                      data={qualityChangeControlInfo?.data}
                      isGraph
                      graphData={qualityChangeControlInfo?.graphData}
                      {...(qualityChangeControlInfo
                        ? qualityChangeControlInfo.graphParams
                        : {})}
                      {...(qualityChangeControlInfo
                        ? qualityChangeControlInfo.cardProps
                        : {})}
                      className={customSubCard}
                      setIsOpenDeepDive={setIsQualityOpenDeepDive}
                      setActiveKeyDeepDiveTab={(key: string) =>
                        setActiveQualityKeyDeepDiveTab(key)
                      }
                      actionKey="changecontrol"
                      tierLabel={tierLabel}
                    />
                  );
                }
                if (label.toLowerCase() === metricNames?.capastatus) {
                  return (
                    <CustomMetricCard
                      key={"q-capa"}
                      title={label}
                      isAlert
                      {...(qualityChangeControlInfo
                        ? qualityChangeControlInfo.cardProps
                        : {})}
                      isGrid
                      data={qualityCAPAInfo?.data}
                      className={customSubCard}
                      tooltipContent={""}
                      setIsOpenDeepDive={setIsQualityOpenDeepDive}
                      setActiveKeyDeepDiveTab={(key: string) =>
                        setActiveQualityKeyDeepDiveTab(key)
                      }
                      actionKey="capastatus"
                      tierLabel={tierLabel}
                    />
                  );
                }
                return null;
              })}
          </div>
          <div className={wctCss.cardContainer}>
            <Alert
              closable={false}
              showIcon
              icon={
                <StatusPositiveIcon
                  fill={"#2CC84D"}
                  height={"20"}
                  width={"20"}
                />
              }
              message={"Delivery"}
              type="success"
              action={
                <Button
                  className={sqdipBtn}
                  label="View"
                  onClick={() => { }}
                  type="secondary"
                />
              }
            />
          </div>
          <div className={wctCss.cardContainer}>
            <Alert
              closable={false}
              showIcon
              icon={
                <StatusIssueIcon
                  fill={"var(--ops-sem-red-primary)"}
                  height={"20"}
                  width={"20"}
                />
              }
              message={"Inventory"}
              type="error"
              action={
                <Button
                  className={sqdipBtn}
                  label="View"
                  onClick={() => { }}
                  type="secondary"
                />
              }
            />
          </div>
          <div className={wctCss.cardContainer}>
            <Alert
              closable={false}
              showIcon
              icon={
                <StatusPositiveIcon
                  fill={"#2CC84D"}
                  height={"20"}
                  width={"20"}
                />
              }
              message={"Productivity"}
              type="success"
              action={
                <Button
                  className={sqdipBtn}
                  label="View"
                  onClick={() => { }}
                  type="secondary"
                />
              }
            />
          </div>
        </div >}
        <AohDeepDiveMain
          data={deepDiveData[activeSafetyKeyDeepDiveTab] ?? []}
          tierLabel={tierLabel}
          shift={currentShift?.label}
          line={currentLine}
          building={building}
          costCenter={currentCostCenter}
          sqdipTabType={SQDIPType.Safety}
          isOpen={isSafetyOpenDeepDive}
          isClose={() => {
            setIsSafetyOpenDeepDive(false);
            setActiveSafetyKeyDeepDiveTab(deepDiveTabItems.Safety[0]);
          }}
          activeKeyDeepDiveTab={activeSafetyKeyDeepDiveTab}
          setActiveKeyDeepDiveTab={(key: string) =>
            setActiveSafetyKeyDeepDiveTab(key)
          }
          deepdiveSummaryData={
            deepdiveSummaryInfo?.data?.[
            activeSafetyKeyDeepDiveTab?.toLowerCase().replace(/ /g, "_")
            ]
          }
          tabItems={deepDiveTabItems.Safety ?? []}
        />
        <AohDeepDiveMain
          data={qualityDeepDiveData[activeQualityKeyDeepDiveTab] ?? []}
          tierLabel={tierLabel}
          shift={currentShift?.label}
          line={currentLine}
          building={building}
          costCenter={currentCostCenter}
          sqdipTabType={SQDIPType.Quality}
          isOpen={isQualityOpenDeepDive}
          isClose={() => {
            setIsQualityOpenDeepDive(false);
            setActiveQualityKeyDeepDiveTab(deepDiveTabItems.Quality[0]);
          }}
          activeKeyDeepDiveTab={activeQualityKeyDeepDiveTab}
          setActiveKeyDeepDiveTab={(key: string) =>
            setActiveQualityKeyDeepDiveTab(key)
          }
          deepdiveSummaryData={
            deepdiveSummaryInfo?.data?.[
            activeQualityKeyDeepDiveTab?.toLowerCase().replace(/ /g, "_")
            ]
          }
          tabItems={deepDiveTabItems.Quality ?? []}
        />
      </div >
    </PageLayout >
  );
};

export default WorkCenterTeam;
