import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from 'react-redux';
import store from 'store';
import "./aoh-site.scss";
import { BrowserRouter, Navigate, Route, Routes, useParams } from "react-router-dom";
import { LoginCallback } from "@okta/okta-react";
import ShopFloorPage from "page/shop-floor";
import ErrorPage from "page/error-page";
import { useState } from "react";
import { createBrowserHistory } from "history";
import { AppContext } from "context/app-context";
import DesktopSideNav from "components/side-nav";
import AuthComponent from "components/auth-component";
import RouteErrorMessage from "components/route-error";
import RequiredAuthComponent from "components/auth-component/required-auth";
import { MatomoContextProvider } from "utils/matomo-context-provider";
import { DEV_DIMENSIONS } from "utils/dimensions";
import PersonasContainer from "page/personas-container";
import WorkCenterTeam from "page/work-center-team";
import { permissionObject } from "utils/constants";
import { MCT } from "types/permission-type";
import { NavLink } from "components/side-nav/types";

const matomoClientID = process.env.REACT_APP_MATAMO_CLIENT_ID;
const matomoClientUrl = process.env.REACT_APP_MATOMO_URL;

const history = createBrowserHistory();

const initialState = {
  navRoot: null,
  navActive: true,
  plant: "",
  mfg_stage: "",
  building: "",
  line: "",
  allLines: [],
  curr_stage: null,
  updatedAt: "",
  currentBatchId: "",
  refetchInterval: 30000,
  phase: null,
  nonCommercialFlag: false,
  //  More you can add here if any other state findings..
};

const AohSite: React.FC = (props: any) => {
  const { permissions } = props
  const [appState, setAppState] = useState<any>(initialState);
  const { site } = useParams()
  const [navList, setNavList] = useState<NavLink>({
    label: site?.toUpperCase() ?? "",
    authorized: true,
    jumpToSections: [],
    externalSections: [],
    internalSections: []
  });

  permissions[1]?.workstreams?.mct?.forEach((site: MCT) => {
    permissionObject[site?.url] = site?.isAuthorized
  })

  useState(() => {
    setAppState({
      ...appState,
      refetchInterval: parseInt(
        process.env.REACT_APP_REFETCH_INTERVAL ?? "30000"
      ),
    });
  });

  const queryClient = new QueryClient();

  return (
    <Provider store={store}>
      <MatomoContextProvider
        url={matomoClientUrl}
        siteId={matomoClientID}
        history={history}
        dimensions={DEV_DIMENSIONS}
      >
        <BrowserRouter>
          <AppContext.Provider value={{ appState, setAppState, initialState }}>
            <QueryClientProvider client={queryClient}>
              <AuthComponent>
                <div className="site-wrapper">
                  <Routes>
                    <Route path="/manufacturing/:site" element={<RequiredAuthComponent />}>
                      <Route
                        path="/manufacturing/:site"
                        element={<PersonasContainer permissions={permissions} setNavlist={setNavList} />}
                      />
                      <Route
                        path="/manufacturing/:site/shop-floor"
                        element={<RedirectToAllLines />}
                      />
                      <Route
                        path="/manufacturing/:site/work-center-team"
                        element={<WorkCenterTeam permissions={permissions} setNavList={setNavList} />}
                      />
                      <Route
                        path="/manufacturing/:site/*"
                        element={
                          <RouteErrorMessage
                            routeerror={`Page not configured in DCT `}
                          ></RouteErrorMessage>
                        }
                      />
                    </Route>
                    <Route
                      path="/manufacturing/:site/shop-floor/:line"
                      element={<RequiredAuthComponent />}
                    >
                      <Route
                        path="/manufacturing/:site/shop-floor/:line"
                        element={<ShopFloorPage permissions={permissions} setNavList={setNavList} />}
                      />
                    </Route>
                    <Route
                      path="/implicit/callback"
                      element={<LoginCallback />}
                    />
                    <Route
                      path="/unauthorized"
                      element={
                        <RouteErrorMessage routeerror="You are not authorized to access this page" />
                      }
                    />
                    <Route path="/notfound" element={<RequiredAuthComponent />}>
                      <Route
                        path="/notfound"
                        element={
                          <RouteErrorMessage
                            routeerror={`Site not configured in DCT `}
                          ></RouteErrorMessage>
                        }
                      />
                    </Route>
                    <Route
                      path="/manufacturing/:site/shop-floor/invalid_line"
                      element={<RequiredAuthComponent />}
                    >
                      <Route
                        path="/manufacturing/:site/shop-floor/invalid_line"
                        element={
                          <ErrorPage
                            errorMessage={`Line is not configured for this site `}
                          ></ErrorPage>
                        }
                      />
                    </Route>
                  </Routes>
                </div>

                <DesktopSideNav hideNav={false} activeRouteData={navList} />
              </AuthComponent>
            </QueryClientProvider>
          </AppContext.Provider>
        </BrowserRouter>
      </MatomoContextProvider>
    </Provider>
  );
};

const RedirectToAllLines = () => {
  const { site } = useParams()
  return <Navigate to={`/manufacturing/${site}/shop-floor/all-lines`} replace />
}

export default AohSite;
