import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import "../../../assets/bootstrap.scss";
import { Button} from "@gitlab-rtsensing/component-library";
import ProgressbarIndicator from "components/resuable/mutli-progress-indicator";
import { useNavigate, useParams } from "react-router-dom";
import StatusLogo from "components/resuable/status-logo";
import { getStatus }  from "../../../utils/common-methods";
import { MatomoContext } from "utils/matomo-context-provider";
import CircleIcon from "assets/icons/circle-icon";
import { getIdpDetails } from "api/batch-information/idp-details-api";
import { useQuery } from "react-query";
import { AppContext } from "context/app-context";
import { Tooltip } from "react-tooltip";
import { getBatchCompletionContent } from "api/batch-information/batch-completion-api";

interface MetricProps {
  progress: { status: string; percentage: number }[];
  lineNo: string;
  viewChangeFn: () => void;
  lineStatus: string;
  batchNo: string;
  batchName: string;
  batchProgress: string;
  phase: string;
  machineStatus: string;
  oee: string;
  sortType: string;
  statusColor: string;
  isNonCommercial: boolean;
  building: string;
}
const formatTime = (seconds: number) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;

  const hDisplay = h.toString().padStart(2, '0');
  const mDisplay = m.toString().padStart(2, '0');
  const sDisplay = s.toString().padStart(2, '0');

  return `${hDisplay}:${mDisplay} hrs`;
};

const LineCardContent: React.FC<MetricProps> = (props: MetricProps) => {
  const { viewChangeFn } = props;
  const {site} = useParams()
  const context = useContext(MatomoContext);
  const navigate = useNavigate();
  const [currentToolTipTime, setCurrentToolTip] = useState<number>(0)
  const [timer, setTimer] = useState<number>(0)

  useEffect(() => {
    if (timer > 0) {
      const timer = setInterval(() => {
        setTimer((prev) => prev - 1)
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [currentToolTipTime]);

  //Idp-details-api
  const { appState } = useContext(AppContext);
  const location: any = useParams();
  const { data: idpDetailsData, isLoading: isLoadingIdp, isError: isErrorIdp } = useQuery({
    queryKey: ['getIdpDetailsData', props.lineNo],
    queryFn: getIdpDetails.bind(this, {
      site: location.site,
      building: props.building,
      line_no: props.lineNo
    }),
    refetchInterval: appState.refetchInterval
  });

  // batch-completion-api
  const { data: batchCompletionData, isLoading: isBatchCompletionLoading, isError: isBatchCompletionError } = useQuery({
    queryKey: ["getBatchCompletionContent", props.lineNo],
    queryFn: getBatchCompletionContent.bind(this, {
      site: location.site,
      line_no: props.lineNo,
      curr_stage: appState.curr_stage,
      mfg_stage: appState.mfg_stage,
      building: appState.building
    }),
    refetchInterval: appState.refetchInterval,
    enabled: appState.mfg_stage !== "" && appState.building !== ""
  });
  const actualPercentage = batchCompletionData?.data?.data?.actual;
  const cleanedPercentage = actualPercentage?.replace(/[^0-9.]/g, '');
  const percentageFloat = parseFloat(cleanedPercentage);
  const cappedPercentage = (!isNaN(percentageFloat) ? Math.min(percentageFloat, 100) : 0).toFixed(2) + "%";

  const percentageArray = props?.progress?.map(
    (progressEntity: { status: string; percentage: number }) => {
      if (progressEntity.status?.toUpperCase() === "RUNNING") {
        return { percentage: progressEntity.percentage, flag: "GREEN" };
      } else if (progressEntity.status?.toUpperCase() === "DOWN") {
        return { percentage: progressEntity.percentage, flag: "RED" };
      } else if (progressEntity.status?.toUpperCase() === "CHANGEOVER") {
        return { percentage: progressEntity.percentage, flag: "BLUE" };
      } else return { percentage: progressEntity.percentage, flag: "GREY" };
    }
  );

  const handleLineClick = () => {
    context.trackEvent("btn_click_event", "All lines", props.lineNo);
    navigate(`/manufacturing/${site}/shop-floor/${props.lineNo}`);
    viewChangeFn();
  };
  const hourToMilliseconds = (time: string) => {
    const second = time.split(":")
    const result = (+second[0] * (60000 * 60) + (+second[1] * 60000))
    return result
  }

  const remainingTimeChecker = (time: number) => {
    const totalMinutes = time / 60;
    if (totalMinutes > 120) {
      return '#008533';
    } else if (totalMinutes >= 15 && totalMinutes < 120) {
      return '#FFBF00';
    }
    return '#D62728';
  };
  return (
    <div
      className={
        "aoh-row aoh-metric-content aoh-d-flex justify-content-between aoh-w-100"
      }
    >
      <div className="aoh-d-flex aoh-col-1 aoh-pl-4 align-items-center aoh-summary aoh-status ops-fs-5 ops-fw-semibold aoh-pointer aoh-line-hover" onClick={handleLineClick}>
        {props?.lineNo ?? "N/A"}
      </div>
      <div className="aoh-d-flex aoh-col-1 aoh-pl-2 aoh-all-lines-status align-items-center aoh-status ops-fs-5">
        {props?.statusColor && (
          <div className="aoh-pt-1">
            <StatusLogo
              color={props.statusColor.toLowerCase()}
              size={15}
              alertClassName="aoh-disable-status-hover"
              className={`${
                props.statusColor.toLowerCase() === "grey"
                ? "ops-text-light aoh-gray-icon-all-lines"
                : ""
                }`}
            />
          </div>
        )}
        <div className="ops-mx-1 ops-fw-semibold">
          {getStatus(props?.lineStatus?.toLowerCase()) ?? "N/A"}
        </div>
      </div>
      <div className="aoh-d-flex aoh-col-1 align-items-center aoh-lines ops-fs-5 aoh-status ops-fw-normal">
        {!props?.isNonCommercial ? props?.batchNo ?? "N/A" : "-"}
      </div>
      <div className="aoh-d-flex aoh-col-2 align-items-center aoh-lines ops-fs-5 aoh-status ops-fw-normal">
        {!props?.isNonCommercial ? props?.batchName ?? "N/A" : "-"}
      </div>
      <div className="aoh-d-flex aoh-col-1 align-items-center aoh-lines ops-fs-5 aoh-status ops-fw-normal">
        {!props?.isNonCommercial ? props?.phase ?? "N/A" : "-"}
      </div>
      <div className="aoh-d-flex aoh-col-2 align-items-center aoh-status ops-fs-5">
        {!props?.isNonCommercial && (
          <div className="aoh-progress-bar">
            <ProgressbarIndicator percentageArray={percentageArray ?? 0} />
          </div>
        )}
        {!props?.isNonCommercial ? (
          <div className="ops-mx-2 ops-fw-semibold">
            {cappedPercentage ?? "N/A"}
          </div>
        ) : (
          "-"
        )}
      </div>
      <div className="aoh-d-flex aoh-col-2 align-items-center aoh-lines ops-fs-5 aoh-status ops-fw-semibold ">
        {!props?.isNonCommercial ? props?.machineStatus ?? "N/A" : "-"}
      </div>
      {(props?.phase === "Setup" || props?.phase === "Run")
        ? <div className="aoh-d-flex aoh-col-1 align-items-center aoh-lines ops-fs-5 aoh-status ops-fw-semibold group aoh-margin">
          {idpDetailsData?.data?.data?.length > 0
            ? idpDetailsData?.data?.data?.map((item: any, i: any) => (
              item?.remaining_time === "00:00" ? null : (
                <div key={i} className="circle-tooltip-item aoh-pointer"
                  data-tooltip-id="my-tooltip-inline"
                  data-time={item.remaining_time}
                  data-lineNo={item.hu_number_final}>
                  <CircleIcon
                    fill={remainingTimeChecker(hourToMilliseconds(item?.remaining_time) / 1000)}
                    height={15}
                    width={15}
                  />
                </div>
              )
            ))
            : <span className="no-active-idp">No Active IDP</span>}
          <Tooltip
            id="my-tooltip-inline"
            className="tooltip-container-rte tooltip-container-rte-arrow"
            render={({ content, activeAnchor }) => {
              const time = activeAnchor?.getAttribute('data-time') ?? "00:00";
              const line = activeAnchor?.getAttribute('data-lineNo');
              const timeNumber = hourToMilliseconds(time) / 1000
              setCurrentToolTip(() => timeNumber)
              setTimer(() => timeNumber)
              return (
                <div>
                  <h2 className="rte-heading">Remaining RTE</h2>
                  <div className="aoh-tooltip-body">
                    <div className="aoh-tooltip-rte-hu">HU {line ?? 'not set'}</div>
                    <div
                      style={{ backgroundColor: remainingTimeChecker(timer)}}
                      className="aoh-tooltip-rte-time">
                      {formatTime(timer)}
                    </div>
                  </div>
                </div>
              )
            }}
          />
        </div>
        : <div className="aoh-d-flex aoh-col-1 align-items-center aoh-lines ops-fs-5 aoh-status ops-fw-semibold group aoh-margin-hyphen">
          {"-"}
        </div>}
      <div className="aoh-d-flex aoh-col-1 align-items-center aoh-lines ops-fs-5 aoh-status ops-fw-semibold">
        {!props?.isNonCommercial ? props?.oee ?? "N/A" : "-"}
      </div>
      {/* <div className=" aoh-d-flex aoh-col-1 align-items-center aoh-status ops-fs-5">
        <Button
          label="View"
          onClick={() => {
            context.trackEvent("btn_click_event", "All lines", props.lineNo);
            navigate(`/${site}/shop-floor/${props.lineNo}`);
            viewChangeFn();
          }}
          type="secondary"
        />
      </div> */}
    </div>
  );
};

export default LineCardContent;
