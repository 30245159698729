import React from "react";
import { Table } from "antd";
import Label from "../../../components/resuable/label";
import classes from "./ColorCodedTable.module.css";

interface ColorCodedTableProps {
  data?: any;
  metricKey?: string
}

interface ColumnType {
  title: string;
  dataIndex: string;
  key: string;
  width: string;
  render?: (
    value: any,
    record: { cost_center_name: any; manager: any }
  ) => JSX.Element;
  className?: string
}

const ColorCodedTable: React.FC<ColorCodedTableProps> = ({ data, metricKey }) => {
  const columns: ColumnType[] = [
    {
      title: "COST CENTER",
      dataIndex: "cost_center_name",
      key: "cost_center_name",
      width: "203.5px",
      className: "cost-center-column",
    },
  ];

  if (data?.[0]?.manager) {
    columns.push({
      title: "MANAGER",
      dataIndex: "manager",
      key: "manager",
      width: "203.5px",
      className: "manager-column"
    });
  }

  const uniqueDates: string[] = Array.from(
    new Set(data?.map((item: any) => item.compliance_date))
  );

  interface ComplianceData {
    cost_center_name: any;
    manager: any;
  }
  const uniqueData: ComplianceData[] = data && data.length > 0 ? Array.from(
    data
      .reduce((map: Map<string, ComplianceData>, item: ComplianceData) => {
        const uniqueKey = `${item.cost_center_name}-${item.manager}`;
        if (!map.has(uniqueKey)) {
          map.set(uniqueKey, item);
        }
        return map;
      }, new Map<string, ComplianceData>())
      .values()
  ) : [];

  uniqueDates.forEach((date: string) => {
    columns.push({
      title: date,
      dataIndex: date,
      key: date,
      width: "203.5px",
      render: (_: any, record: { cost_center_name: any; manager: any }) => {
        const item = data?.find((d: any) => d.cost_center_name === record.cost_center_name && d.compliance_date === date);

        const complianceRate = item ? item.compliance_rate : null;
        return (
          <div
            style={{
              backgroundColor: complianceRate > 95 ? "#1BA13D" : "#E01029",
              color: "white",
              paddingLeft: "8px",
              paddingTop: "10px",
              textAlign: "center",
              height: "100%",
              border: "2px solid #F0F0F0",
            }}
          >
            {complianceRate !== null && complianceRate <= 95
              ? complianceRate
              : ""}
          </div>
        );
      },
    });
  });

  const legendItems = [
    {
      value: "% COMPLIANCE FOR COST CENTER < 95 %",
      circleColor: "#E01029",
    },
    {
      value: "% COMPLIANCE FOR COST CENTER > 95 %",
      circleColor: "#1BA13D",
    },
    {
      value: "COMPLIANCE RATE (%) BELOW THE TARGET",
      circleColor: "#E01029",
      metricKey: "gxp"
    },
    {
      value: "COMPLIANCE RATE (%) ABOVE THE TARGET",
      circleColor: "#1BA13D",
      metricKey: "gxp"
    },
  ];

  return (
    <>
      <Table
        className={classes.customTable}
        dataSource={uniqueData}
        columns={columns}
        pagination={{ pageSize: 10 }}
      />
      {data?.length > 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "20px auto 16px auto"
            }}
          >
            {legendItems.map((item, index) => {
              if (item.metricKey === metricKey) {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "16px",
                    }}
                  >
                    <Label
                      StatusValue={item.value}
                      circleColor={item.circleColor}
                    />
                  </div>)
              } else if (!metricKey && !item.metricKey) {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "16px",
                    }}
                  >
                    <Label
                      StatusValue={item.value}
                      circleColor={item.circleColor}
                    />
                  </div>)
              }
              return null
            }).filter(Boolean)}
          </div>
        </div>
      )}
    </>
  );
};

export default ColorCodedTable;
