import AohTable from "../../common/table/AohTable";
import AohDropdown from "components/common/dropdown/aohdropdown";
import { Button } from "@gitlab-rtsensing/component-library";
import SummaryCard from "../../common/summaryCard/summaryCard";
import "./Safety.scss";
import { useState } from "react";
import ColorCodedTable from "components/common/table/ColorCodedTable";
import AohTableWithLabels from "components/common/table/AohTableWithLabels";
import HorizontalBarChart from "components/common/HorizontalBar/HorizontalBar";
import { TransformedData } from "reducers/safetyDeepDive/workOrderReducer";
import {
  capaColumns,
  deepDiveStackedInterval,
  incaColumns,
  incaGlobalEhssColumns,
  seecardColumns,
  workOrderColumns,
} from "utils/constants";
import StackedBarChart, {
  DataItem,
} from "components/common/StackedBar/StackedBar";
import { RadioFilters } from "components/resuable/radio-filters/RadioFilters";
import { MenuProps } from "antd";
import { fetchIncaDeepDive } from "reducers/safetyDeepDive/incaDeepDiveReducer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";

interface SafetyTier2Props {
  data: any;
  tierLabel: string;
  activeKeyDeepDiveTab: string;
}

interface HeaderDropDownType {
  key: string;
  label: string;
}

const SafetyTier2: React.FC<SafetyTier2Props> = ({
  data,
  tierLabel,
  activeKeyDeepDiveTab,
}) => {
  const [filteredByStatusData, setFilteredByStatusData] = useState(
    data?.inca?.inca_open_incidents
  );
  const [filteredByCostCenterData, setFilteredByCostCenterData] = useState(
    data?.ehss?.complianceRateData
  );

  const dispatch = useDispatch<AppDispatch>();

  const [currentStatus, setCurrentStatus] = useState<HeaderDropDownType>({
    key: "All",
    label: "All",
  });
  const [currentCostCenter, setCurrentCostCenter] =
    useState<HeaderDropDownType>({
      key: "All Cost Centers",
      label: "All Cost Centers",
    });

  const costCenterDropDownData: HeaderDropDownType[] = [
    { key: "All Cost Centers", label: "All Cost Centers" },
    ...Array.from(
      new Set(
        data?.ehss?.complianceRateData?.map(
          (item: { cost_center_name: string }) => item.cost_center_name
        )
      )
    )
      .filter(
        (costCenterName): costCenterName is string =>
          costCenterName !== undefined && costCenterName !== null
      )
      .map((uniqueCostCenter: string) => ({
        key: uniqueCostCenter,
        label: uniqueCostCenter,
      })),
  ];
  const statusDropDownData: HeaderDropDownType[] = [
    { key: "All", label: "All" },
    ...Array.from(
      new Set(
        data?.inca?.inca_open_incidents?.map(
          (item: { status: string }) => item.status
        )
      )
    )
      .filter(
        (status): status is string => status !== undefined && status !== null
      )
      .map((uniqueStatus: string) => ({
        key: uniqueStatus,
        label: uniqueStatus,
      })),
  ];

  const handleStatusClick: MenuProps["onClick"] = (selectedStatus: any) => {
    setCurrentStatus({ key: selectedStatus.key, label: selectedStatus.key });

    const newFilteredData =
      selectedStatus.key === "All"
        ? data?.inca?.inca_open_incidents
        : data?.inca?.inca_open_incidents.filter(
            (item: { status: string }) => item.status === selectedStatus.key
          );

    setFilteredByStatusData(newFilteredData);
  };

  const handleCostCenterClick: MenuProps["onClick"] = (
    selectedCostCenter: any
  ) => {
    setCurrentCostCenter({
      key: selectedCostCenter.key,
      label: selectedCostCenter.key,
    });

    const filteredData =
      selectedCostCenter.key === "All Cost Centers"
        ? data?.ehss?.complianceRateData
        : data?.ehss?.complianceRateData?.filter(
            (item: { cost_center_name: string }) =>
              item?.cost_center_name === selectedCostCenter.key
          );

    setFilteredByCostCenterData(filteredData);
  };

  // --- Stacked bar charts logic ----

  // Inca Incidents Chart

  const [timePeriod, setTimePeriod] = useState<
    "Last Week" | "Last Month" | "Last 3 Months"
  >("Last Week");

  const filterDataByTimePeriod = (period: "Last Week" | "Last Month" | "Last 3 Months") => {
    const params = {
      workstream: "manufacturing",
      site: "aoh",
      persona: "wct",
      tierNumber: "tier 2",
      interval: deepDiveStackedInterval[period],
    };
    dispatch(fetchIncaDeepDive(params));
  };

  const safetyDeepDive: any = {
    "SEE Cards": {
      summaryTitle: "Summary",
      summary: (
        <SummaryCard
          tierLabel={tierLabel}
          activeKeyDeepDiveTab={activeKeyDeepDiveTab}
          data={data?.seeCards?.summary}
        />
      ),
      title: "See Cards Group by Type",
      table: (
        <StackedBarChart
          data={data?.seeCards?.see_trend_chart}
          chartType="see-cards"
        />
      ),
      component2: (
        <HorizontalBarChart
          title1="EHSS Program Area"
          title2="Behavior Observation Type"
          subtitle1="For Last 5 Days"
          subtitle2="For Last 5 Days"
          EHSSProgramData={data?.seeCards?.ehss_chart}
          BehaviorObservationData={data?.seeCards?.see_chart}
        />
      ),
      title3: "Open SEE Cards",
      table3: (
        <AohTable
          tableData={data?.seeCards?.open_see_data}
          tableColumns={seecardColumns}
        />
      ),
    },
    "INCA Incidents": {
      summaryTitle: "Summary",
      summary: (
        <SummaryCard
          tierLabel={tierLabel}
          activeKeyDeepDiveTab={activeKeyDeepDiveTab}
          data={data?.inca?.summary}
        />
      ),
      title: "Number of Incidents",
      radioFilters: (
        <RadioFilters timePeriod={timePeriod} setTimePeriod={setTimePeriod} filterDataByTimePeriod={filterDataByTimePeriod} />
      ),
      stackedBar: (
        <StackedBarChart
          data={data?.inca?.inca_trend_chart}
          timePeriod={timePeriod}
          chartType="inca"
        />
      ),
      title2: "Open Incident List",
      dropdown2: (
        <div className="tier0-dropdown">
          <AohDropdown
            items={statusDropDownData}
            title="STATUS"
            dropOptions={currentStatus?.label}
            handleMenuClick={handleStatusClick}
          />
        </div>
      ),
      table2: (
        <AohTableWithLabels
          tableData={filteredByStatusData ?? data?.inca?.inca_open_incidents}
          tableColumns={incaColumns}
        />
      ),
      title3: "Global EHSS Awareness",
      table3: (
        <AohTable
          tableData={data?.inca?.inca_global_ehss}
          tableColumns={incaGlobalEhssColumns}
        />
      ),
    },
    "Safety Critical Work Orders": {
      summaryTitle: "Summary",
      summary: (
        <SummaryCard
          data={data?.summary}
          tierLabel={tierLabel}
          activeKeyDeepDiveTab={activeKeyDeepDiveTab}
        />
      ),
      title: "Open Safety Critical Work Orders",
      table: (
        <AohTableWithLabels
          tableData={data?.workOrderDeepDive}
          tableColumns={workOrderColumns}
        />
      ),
    },
    "EHSS Training Compliance": {
      summaryTitle: "Summary",
      summary: (
        <SummaryCard
          data={data?.ehss?.summary}
          tierLabel={tierLabel}
          activeKeyDeepDiveTab={activeKeyDeepDiveTab}
        />
      ),
      title: "EHSS Compliance Rate",
      subtitle: "For Last 5 Hours",
      dropdown: (
        <div className="tier0-dropdown">
          <AohDropdown
            items={costCenterDropDownData}
            title="Cost Center"
            dropOptions={currentCostCenter?.label}
            handleMenuClick={handleCostCenterClick}
          />
        </div>
      ),
      heatmap: (
        <ColorCodedTable
          data={filteredByCostCenterData ?? data?.ehss?.complianceRateData}
        />
      ),
    },
    "Safety Critical CAPAs": {
      summaryTitle: "Summary",
      summary: (
        <SummaryCard
          data={data?.capa?.summary}
          tierLabel={tierLabel}
          activeKeyDeepDiveTab={activeKeyDeepDiveTab}
        />
      ),
      title: "Unclosed CAPAs",
      table: (
        <AohTable tableData={data?.capa?.data} tableColumns={capaColumns} />
      ),
    },
  };

  return (
    <div className="safety-tier-main">
      <h2 className="tier0-title">
        {/* Summary card */}
        {safetyDeepDive[activeKeyDeepDiveTab]?.summaryTitle}
      </h2>
      {safetyDeepDive[activeKeyDeepDiveTab]?.summary}
      <div
        className={
          safetyDeepDive[activeKeyDeepDiveTab]?.summary ? "mr-bottom-20" : ""
        }
      ></div>
      {/* Title card-1 */}
      <div className="tier0-container">
        <h2 className="tier0-title">
          {safetyDeepDive[activeKeyDeepDiveTab]?.title}
        </h2>
        {safetyDeepDive[activeKeyDeepDiveTab]?.button}
      </div>
      {safetyDeepDive[activeKeyDeepDiveTab]?.dropdown}
      <div className="tier0-subtitle">
        {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle}
      </div>
      {/* main component-1 */}
      {safetyDeepDive[activeKeyDeepDiveTab].heatmap}
      {safetyDeepDive[activeKeyDeepDiveTab].table}
      {safetyDeepDive[activeKeyDeepDiveTab].radioFilters}
      {safetyDeepDive[activeKeyDeepDiveTab].stackedBar}
      <div
        className={
          safetyDeepDive[activeKeyDeepDiveTab]?.heatmap ||
          safetyDeepDive[activeKeyDeepDiveTab]?.table ||
          safetyDeepDive[activeKeyDeepDiveTab]?.stackedBar
            ? "mr-bottom-20"
            : ""
        }
      ></div>

      {/* Title card-2*/}
      <div className="tier0-container">
        <h2 className="tier0-title">
          {safetyDeepDive[activeKeyDeepDiveTab]?.title2}
        </h2>
        {safetyDeepDive[activeKeyDeepDiveTab]?.button2}
      </div>
      {safetyDeepDive[activeKeyDeepDiveTab]?.dropdown2}
      <div className="tier0-subtitle">
        {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle2}
      </div>
      {/* main component-2 */}
      {safetyDeepDive[activeKeyDeepDiveTab].heatmap2}
      {safetyDeepDive[activeKeyDeepDiveTab].table2}
      {safetyDeepDive[activeKeyDeepDiveTab]?.component2}
      <div
        className={
          safetyDeepDive[activeKeyDeepDiveTab]?.heatmap2 ||
          safetyDeepDive[activeKeyDeepDiveTab].table2 ||
          safetyDeepDive[activeKeyDeepDiveTab].component2
            ? "mr-bottom-20"
            : ""
        }
      ></div>

      {/* Title card-3*/}
      <div className="tier0-container">
        <h2 className="tier0-title">
          {safetyDeepDive[activeKeyDeepDiveTab]?.title3}
        </h2>
        {safetyDeepDive[activeKeyDeepDiveTab]?.button3}
      </div>
      {safetyDeepDive[activeKeyDeepDiveTab]?.dropdown3}
      <div className="tier0-subtitle">
        {safetyDeepDive[activeKeyDeepDiveTab]?.subtitle3}
      </div>
      {/* main component-3 */}
      {safetyDeepDive[activeKeyDeepDiveTab].table3}
    </div>
  );
};
export default SafetyTier2;
