export const sqdipOptions = [
  { label: "All", value: "all" },
  { label: "Safety", value: "safety" },
  { label: "Quality", value: "quality" },
  { label: "Delivery", value: "delivery" },
  { label: "Inventory", value: "inventory" },
  { label: "Productivity", value: "productivity" },
];

export const timeOptions = [
  { label: "Last Month", value: "last_month" },
  { label: "Last 2 Days", value: "last_2_days" },
  { label: "Last 24 Hours", value: "last_24_hours" },
];

export const tierOptions = [
  { label: "Tier 0", value: "tier0" },
  { label: "Tier 1", value: "tier1" },
  { label: "Tier 2", value: "tier2" },
  { label: "Tier 3", value: "tier3" },
];

export const tierLabelMappping: any = {
  tier0: "Tier 0",
  tier1: "Tier 1",
  tier2: "Tier 2",
  tier3: "Tier 3",
};
