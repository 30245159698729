import React from "react";
import "./deepDiveModalHeader.scss";
import { EnvironmentOutlined } from "@ant-design/icons";
interface DeepdiveModalHeaderProps {
  tierLabel: string;
  shift?: string;
  building?: string;
  line?: string;
  costCenter?: string;
}

const DeepdiveModalHeader: React.FC<DeepdiveModalHeaderProps> = ({
  tierLabel,
  shift,
  line,
  building,
  costCenter,
}) => {
  // create today's date in this format: Friday August 27, 2024
  const date = new Date().toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  return (
    <div className="table-row">
      <EnvironmentOutlined />
      <div className="text bold">AOH</div>
      <div className="text leftborderHeader">{tierLabel}</div>
      {tierLabel === "Tier 0" && (
        <>
          <div className="text leftborderHeader">{shift}</div>
          <div className="text leftborderHeader">{line}</div>
        </>
      )}
      {tierLabel === "Tier 1" && (
        <div className="text leftborderHeader">{shift}</div>
      )}
      {tierLabel === "Tier 2" && (
        <>
         {building && <div className="text leftborderHeader">{building}</div>}
          <div className="text leftborderHeader">{costCenter}</div>{" "}
        </>
      )}
      <div className="text leftborderHeader">{date}</div>
    </div>
  );
};

export default DeepdiveModalHeader;
