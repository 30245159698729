import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { FetchDataParams, GxpDataType, GxpInfoType } from 'types/wctTypes';
import { filterParams, getOktaToken } from 'utils/common-methods';
import { GXP_DEEP_DIVE_URL } from 'utils/constants';

interface GxpInfoState {
    gxpDeepDiveInfo: GxpInfoType | null;
    loading: boolean;
    error: boolean;
}

const initialState: GxpInfoState = {
    gxpDeepDiveInfo: null,
    loading: false,
    error: false,
}

const fetchGxpDeepDive = createAsyncThunk<GxpDataType, FetchDataParams>(
    GXP_DEEP_DIVE_URL,
    async (params) => {
        const filteredParams = filterParams(params)

        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/${GXP_DEEP_DIVE_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })

        if (!response.ok) {
            throw new Error('Failed to fetch ehss');
        }
        return await response.json()
    }
);

const transformData = (data: GxpDataType): GxpInfoType => ({
    summary: data?.summary,
    tableData: data?.table_data?.map((item) => ({
        due_date: item.due_date ? new Date(item.due_date).toLocaleDateString('en-GB') : "",
        name_of_task: item.name_of_task ?? "",
        task_code: item.task_code ?? ""
    })),
    heatmapData: data?.heatmap?.map((item, key) => {
        const complianceDate = item.date
            ? new Date(item.date)
            : item.compliance_week
                ? new Date(item.compliance_week)
                : item.week_start
                    ? new Date(item.week_start)
                    : item.formatted_date ? new Date(item.formatted_date) : null;
        const formattedComplianceDate = complianceDate ? complianceDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' }) : "";
        return {
            key,
            overdue: item.overdue ?? "",
            compliance_date: formattedComplianceDate,
            cumulative_overdue: item.cumulative_overdue ?? "",
            cumulative_on_track: item.cumulative_on_track ?? "",
            compliance_rate: (item.compliance_percent ?? item.Compliance_percentage) ?? "",
            manager: item.manager_name?.replace(/ \(\d+\)$/, "") ?? "",
            cost_center_name: item.cost_center_name ?? "",
        };
    })
});

const qualityGxpSlice = createSlice({
    name: 'gxpDeepDive',
    initialState,
    reducers: {
        clearQualityGXPData(state) {
            state.gxpDeepDiveInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGxpDeepDive.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchGxpDeepDive.fulfilled, (state, action: PayloadAction<GxpDataType>) => {
                state.loading = false;
                state.gxpDeepDiveInfo = transformData(action.payload);
            })
            .addCase(fetchGxpDeepDive.rejected, (state) => {
                state.loading = false;
                state.error = true;
            });
    }
})

export { fetchGxpDeepDive }

export const { clearQualityGXPData } = qualityGxpSlice.actions

export default qualityGxpSlice.reducer