import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOktaToken } from 'utils/common-methods';

interface CapaDataItem {
    assigned_to?: string;
    capa_description?: string;
    capa_due_date?: string;
    capa_source?: string;
    capa_type?: string;
    priority?: string;
    status?: string;
    funtional_group?: string;
    coming_due_in_5days?: string;
    record_id?: string;
}

interface CapaGraphItem {
    Month?: number | string;
    Year?: number | string;
    compliance_percentage_6m?: number | string;
}

interface capaSummaryItem {
    coming_due_in_5days?: string;
}

interface CapaDataType {
    data: CapaDataItem[];
    graph_data: CapaGraphItem[];
    summary?: capaSummaryItem[];
}

interface TransformedData {
    capa: CapaDataType;
}

interface CapaInfoState {
    capaDeepDiveInfo: TransformedData | null;
    loading: boolean;
    error: boolean;
}

const initialState: CapaInfoState = {
    capaDeepDiveInfo: null,
    loading: false,
    error: false,
};

interface FetchDataParams {
    workStream?: string | undefined,
    site?: string | undefined,
    persona?: string | undefined,
    tierNumber?: string | undefined,
    shift?: string | undefined,
    line?: string | undefined,
    building?: string | undefined,
}

const fetchCapaDeepDive = createAsyncThunk<CapaDataType, FetchDataParams>(
    'work-center-team/metrics/safety/deep-dive-view/capa',
    async (params) => {
        const { workStream, site, persona, tierNumber, shift, line, building } = params;
        const token: string = getOktaToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/work-center-team/metrics/safety/deep-dive-view/capa?workStream=${workStream}&site=${site}&persona=${persona}&tierNumber=${tierNumber}&line=${line}&shift=${shift}&building=${building}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch capa');
        }
        return await response.json();
    }
);

const transformData = (data: CapaDataType): TransformedData => ({
    capa: {
        data: data?.data?.map((item) => ({
            assigned_to: item.assigned_to ?? "",
            capa_description: item.capa_description ?? "",
            capa_due_date: item.capa_due_date ?? "",
            capa_source: item.capa_source ?? "",
            capa_type: item.capa_type ?? "",
            priority: item.priority ?? "",
            status: item.status ?? "",
            record_id: item.record_id ?? "",
            funtional_group: item.funtional_group ?? "",
        })),
        graph_data: data?.graph_data?.map((item) => ({
            Month: item.Month ?? "",
            Year: item.Year ?? "",
            compliance_percentage_6m: item.compliance_percentage_6m ?? ""
        })),
        summary: data?.summary
    }
});

const capaDeepDiveReducer = createSlice({
    name: 'capa',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCapaDeepDive.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchCapaDeepDive.fulfilled, (state, action) => {
                state.loading = false;
                state.capaDeepDiveInfo = transformData(action.payload);
            })
            .addCase(fetchCapaDeepDive.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });

    },
});

export { fetchCapaDeepDive };

export default capaDeepDiveReducer.reducer;