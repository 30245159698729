import withAohModal from "components/hoc/withAohModal"
import React from "react"
import withAohModalTab from "components/hoc/withAohModalTab"

import SafetyTier0 from "./safety-tierwise/SafetyTier0";
import SafetyTier1 from "./safety-tierwise/SafetyTier1";
import SafetyTier2 from "./safety-tierwise/SafetyTier2";
import SafetyTier3 from "./safety-tierwise/SafetyTier3";
import QualityTier0 from "./quality-tierwise/QualityTier0";
import QualityTier1 from "./quality-tierwise/QualityTier1";
import QualityTier2 from "./quality-tierwise/QualityTier2";
import QualityTier3 from "./quality-tierwise/QualityTier3";

interface AohDeepDiveContainerType {
  data: any;
  sqdipTabType: string;
  tierLabel: string;
  shift?: string;
  building?: string;
  line?: string;
  costCenter?: string;
  activeKeyDeepDiveTab: string;
  setActiveKeyDeepDiveTab: (key: string) => void;
}

interface AohDeepDiveType {
  data: any;
  tierLabel: string;
  shift?: string;
  building?: string;
  line?: string;
  costCenter?: string;
  activeKeyDeepDiveTab: string;
}

interface AohDeepDiveMainType {
  data: any;
  sqdipTabType: string;
  tierLabel: string;
  shift?: string;
  building?: string;
  line?: string;
  costCenter?: string;
  isOpen: boolean;
  isClose: () => void;
  activeKeyDeepDiveTab: string;
  setActiveKeyDeepDiveTab: (key: string) => void;
  deepdiveSummaryData: string;
  tabItems: string[]
}

const tabTierKeyMap: { [key: string]: React.FC<AohDeepDiveType> } = {
  SafetyTier0,
  SafetyTier1,
  SafetyTier2,
  SafetyTier3,
  QualityTier0,
  QualityTier1,
  QualityTier2,
  QualityTier3
}

const AohDeepDiveContainer: React.FC<AohDeepDiveContainerType> = ({
  data,
  sqdipTabType,
  tierLabel,
  shift,
  building,
  line,
  costCenter,
  activeKeyDeepDiveTab
}) => {
  const key = `${sqdipTabType}${tierLabel.replace(/\s+/g, "")}`
  const Component = tabTierKeyMap[key];

  return Component ? (
      <Component
        data={data}
        tierLabel={tierLabel}
        shift={shift}
        building={building}
        line={line}
        costCenter={costCenter}
        activeKeyDeepDiveTab={activeKeyDeepDiveTab}
      />
  ) : (
    <div>No Data</div>
  );
}

const AohDeepDiveMain: React.FC<AohDeepDiveMainType> = withAohModal(
  withAohModalTab(AohDeepDiveContainer)
)

export default AohDeepDiveMain