import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { FetchDataParams } from 'types/wctTypes';
import { filterParams, getOktaToken } from 'utils/common-methods';
import { CALENDAR_URL } from 'utils/constants';

interface CalendarDataItem {
    date: string,
    overdue_status: string
}

interface CalendarDataType {
    data: CalendarDataItem[];
}

interface TransformedCalendarItem {
    key: number;
    date: number;
    month: number,
    year: number,
    overdue_status: string;
}

interface TransformedData {
    calendar: TransformedCalendarItem[];
}

interface calendarInfoState {
    calendarInfo: TransformedData | null;
    loading: boolean;
    error: boolean;
}

const initialState: calendarInfoState = {
    calendarInfo: null,
    loading: false,
    error: false,
}

const fetchCalendar = createAsyncThunk<CalendarDataType, FetchDataParams>(
    CALENDAR_URL,
    async (params) => {
        const filteredParams = filterParams(params)
        const token: string = getOktaToken();

        const response = await fetch(`${process.env.REACT_APP_API_URL}${CALENDAR_URL}?${new URLSearchParams(filteredParams).toString()}`, {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch calendar');
        }
        return await response.json();
    }
);

const transformData = (data: CalendarDataType): TransformedData => ({
    calendar: data.data.map((item, index) => ({
        key: index,
        date: Number(item.date.substring(8,10)),
        month: Number(item.date.substring(5,7)),
        year: Number(item.date.substring(0,4)),
        overdue_status: item.overdue_status
    })),
});

const calendarReducer = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        clearCalendarData(state) {
            state.calendarInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCalendar.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchCalendar.fulfilled, (state, action) => {
                state.loading = false;
                state.calendarInfo = transformData(action.payload);
            })
            .addCase(fetchCalendar.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });

    },
});

export { fetchCalendar };

export const { clearCalendarData } = calendarReducer.actions 

export default calendarReducer.reducer;