import { useState, useEffect } from "react";
import { CustomAxisTickProps } from "../CustomMetricCardTypes";
import TickFormatterCSS from './TickFormatter.module.css'

const CustomYAxisTick: React.FC<CustomAxisTickProps> = ({ x, y, payload , isDeci=true, startLabel=true, endLabel=true }) => {
  
    return (
      <text
        x={x - 10}
        y={y + 4}
        textAnchor="start"
        className={TickFormatterCSS.customTick}
      >  
        {payload.value}{isDeci?"%":""}
      </text>
    );
  }

  export default CustomYAxisTick