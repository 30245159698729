import AohTable from "../../common/table/AohTable";
import SummaryCard from "../../common/summaryCard/summaryCard";
import "./Quality.scss";
import { GXPTableColumnsType, minorDeviationColumns } from "types/wctTypes";
import { Button } from "@gitlab-rtsensing/component-library";
import { DownloadOutlined } from "@ant-design/icons";
import { RadioFilters } from "components/resuable/radio-filters/RadioFiltersQuality";
import { useState } from "react";
import ColorCodedTable from "components/common/table/ColorCodedTable";

interface QualityTier1Props {
  data: any;
  tierLabel: string;
  activeKeyDeepDiveTab: string;
}

const QualityTier1: React.FC<QualityTier1Props> = ({
  data: { summary, heatmapData, data, tableDataAll, tableDataAdherence, tableDataAging, tableDataOpen },
  tierLabel,
  activeKeyDeepDiveTab,
}) => {
  const [minDevTableTimePeriod, setMinDevTableTimePeriod] = useState("All")
  const qualityDeepDive: any = {
    "Minor Deviation": {
      summaryTitle: "Summary",
      summary: (
        <SummaryCard
          tierLabel={tierLabel}
          activeKeyDeepDiveTab={activeKeyDeepDiveTab}
          data={summary?.[0]}
        />
      ),
      title: "Minor Deviations",
      radioFilters: (
        <RadioFilters
          timePeriod={minDevTableTimePeriod}
          setTimePeriod={setMinDevTableTimePeriod}
          tierLabel={tierLabel}
        />
      ),
      button: (
        <Button
          label="Download"
          icon={
            <DownloadOutlined style={{ color: "#0063C3", fontSize: "14px" }} />
          }
          iconPosition="right"
          onClick={() => { }}
          type="secondary"
        />
      ),
      table: <AohTable
        tableData={
          (minDevTableTimePeriod === "All" || minDevTableTimePeriod === "Adherence")
            ? (minDevTableTimePeriod === "All" ? tableDataAll : tableDataAdherence) : (minDevTableTimePeriod === "Aging" ? tableDataAging : tableDataOpen)}
        tableColumns={minorDeviationColumns} />,
    },
    "GXP Training %Compliance": {
      summaryTitle: "Summary",
      summary: (
        <SummaryCard
          tierLabel={tierLabel}
          activeKeyDeepDiveTab={activeKeyDeepDiveTab}
          data={summary}
        />
      ),
      heatmapTitle: "Compliance Rate (%)",
      heatmap: (
        <ColorCodedTable
          data={heatmapData}
          metricKey={"gxp"}
        />
      ),
      title: "Upcoming Due",
      subtitle: "In 7 days",
      table: <AohTable tableData={data?.data} tableColumns={GXPTableColumnsType} />,
    }
  };

  return (
    <div className="quality-tier-main">
      <div className="tier-container">
        <h2 className="tier-title">
          {/* Summary card */}
          {qualityDeepDive[activeKeyDeepDiveTab]?.summaryTitle}
        </h2>
      </div>
      {qualityDeepDive[activeKeyDeepDiveTab]?.summary}
      <div
        className={
          qualityDeepDive[activeKeyDeepDiveTab]?.summary ? "mr-bottom-20" : ""
        }
      ></div>
      {/* Heatmap Card */}
      <div className="tier0-container">
        <h2 className="tier0-title">
          {qualityDeepDive[activeKeyDeepDiveTab]?.heatmapTitle}
        </h2>
      </div>
      {/* main heatmap component */}
      {qualityDeepDive[activeKeyDeepDiveTab]?.heatmap}
      <div
        className={
          qualityDeepDive[activeKeyDeepDiveTab]?.heatmap ||
            qualityDeepDive[activeKeyDeepDiveTab]?.table
            ? "mr-bottom-20"
            : ""
        }
      ></div>
      <div className="tier-container">
        <h2 className="tier-title">
          {qualityDeepDive[activeKeyDeepDiveTab]?.title}
        </h2>
        {qualityDeepDive[activeKeyDeepDiveTab]?.button}
      </div>
      <div className="tier-subtitle">
        {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle}
      </div>
      {qualityDeepDive[activeKeyDeepDiveTab]?.radioFilters}
      {qualityDeepDive[activeKeyDeepDiveTab]?.table}
    </div>
  );
};
export default QualityTier1;
