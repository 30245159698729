import { fetchInca } from "reducers/safetyBaseView/incaReducer";
import { fetchCapa } from "reducers/safetyBaseView/capaReducer";
import { fetchEhss } from "reducers/safetyBaseView/ehssReducer";
import { fetchSeeCards } from "reducers/safetyBaseView/seeCardsReducer";

import { fetchMinorDeviation } from "reducers/qualityBaseView/minorDeviation";
import { fetchMajorDeviation } from "reducers/qualityBaseView/majorDeviation";
import { fetchGmpTraining } from "reducers/qualityBaseView/gmpReducer";
import { AppDispatch } from "../store";
import { fetchWorkOrder } from "reducers/safetyBaseView/workOrderReducer";
import { fetchCapaDeepDive } from "reducers/safetyDeepDive/capaDeepDiveReducer";
import { fetchIncaDeepDive } from "reducers/safetyDeepDive/incaDeepDiveReducer";
import { fetchWorkOrderDeepDive } from "reducers/safetyDeepDive/workOrderReducer";
import { fetchEhssDeepDive } from "reducers/safetyDeepDive/ehssReducer";
import { fetchSeeCardsDeepDive } from "reducers/safetyDeepDive/seeCardsDeepDiveReducer";
import { clearQualityCAPAData, fetchQualityCAPA } from "reducers/qualityBaseView/qualityCAPAReducer";
import { clearQualityChangeControlData, fetchQualityChangeControl } from "reducers/qualityBaseView/qualityChangeControlReducer";
import { fetchChangeControlDeepDive } from "reducers/qualityDeepDive/changeControlDeepDiveReducer";
import { clearQualityRFTData, fetchQualityRFT } from "reducers/qualityBaseView/qualityRFTReducers";
import { fetchMajorDeviationDeepDive } from "reducers/qualityDeepDive/majorDevDeepDiveReducer";
import { fetchGxpDeepDive } from "reducers/qualityDeepDive/gxpReducer";
import { fetchMinorDeviationDeepDive } from "reducers/qualityDeepDive/minorDevDeepDiveReducer";
import { fetchCapaStatusDeepDive } from "reducers/qualityDeepDive/qualityCapaDeepDiveReducer";

export interface ParamsType {
  workStream: string;
  site: string;
  line: string;
  shift: string;
  costCenter: string;
  tierNumber?: string;
  persona: string;
  building?: string;
}

export const actionMap = {
  inca: async (dispatch: AppDispatch, params: ParamsType) => {
    dispatch(fetchInca(params));
    dispatch(fetchIncaDeepDive(params));
  },
  capa: async (dispatch: AppDispatch, params: ParamsType) => {
    dispatch(fetchCapa(params));
    dispatch(fetchCapaDeepDive(params));
  },
  ehss: async (dispatch: AppDispatch, params: ParamsType) => {
    dispatch(fetchEhss(params));
    dispatch(fetchEhssDeepDive(params));
  },
  seecards: async (dispatch: AppDispatch, params: ParamsType) => {
    dispatch(fetchSeeCards(params));
    dispatch(fetchSeeCardsDeepDive(params));
  },
  workorder: async (dispatch: AppDispatch, params: ParamsType) => {
    dispatch(fetchWorkOrder(params));
    dispatch(fetchWorkOrderDeepDive(params));
  },
  minordeviation: async (dispatch: AppDispatch, params: ParamsType) => {
    dispatch(fetchMinorDeviation(params))
    dispatch(fetchMinorDeviationDeepDive(params))
  },
  majordeviation: async (dispatch: AppDispatch, params: ParamsType) => {
    dispatch(fetchMajorDeviation(params))
    dispatch(fetchMajorDeviationDeepDive(params))
  },
  gxptraining: async (dispatch: AppDispatch, params: ParamsType) => {
    dispatch(fetchGmpTraining(params))
    dispatch(fetchGxpDeepDive(params))
  },
  changecontrol: async (dispatch: AppDispatch, params: ParamsType) => {
    dispatch(clearQualityChangeControlData())
    dispatch(fetchQualityChangeControl(params))
    dispatch(fetchChangeControlDeepDive(params));
  },
  capastatus: async (dispatch: AppDispatch, params: ParamsType) => {
    dispatch(clearQualityCAPAData())
    dispatch(fetchQualityCAPA(params))
    dispatch(fetchCapaStatusDeepDive(params))
  },
  rft: async (dispatch: AppDispatch, params: ParamsType) => {
    dispatch(clearQualityRFTData())
    dispatch(fetchQualityRFT(params))
  }
}

/**
 * Checks if any key in an object has a non -empty . non-undifined value.
 * @param {Object} obj - the object to check
 * @returns {boolean}
 */
export const hasValidValues = (obj: Record<string, any>): boolean => {
  return Object.values(obj).some((val) => val !== "" && val !== undefined);
}