import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOktaToken } from 'utils/common-methods';
import { CAPA_DEEP_DIVE_URL, CHANGE_CONTROL_DEEP_DIVE_URL } from 'utils/constants'
import { StringKeyDataProps } from 'utils/data-types';

interface FetchDataParams {
    workstream?: string | undefined,
    site?: string | undefined,
    persona?: string | undefined,
    tierNumber?: string | undefined,
    shift?: string | undefined,
    costCenter?: string | undefined,
    line?: string | undefined,
    building?: string | undefined
  }
  interface capaStatusInfoState {
    qualityCapaDeepDiveInfo: CapaStatusDataType | null;
    loading: boolean;
    error: boolean;
  }
  
  const initialState: capaStatusInfoState = {
    qualityCapaDeepDiveInfo: null,
    loading: false,
    error: false,
  };
  
  interface CapaStatusDataType {
    summary: CapaStatusSummaryItem;
    CAPAtableDataAll: CapaStatusTableItem[];
    CAPAtableDataOverdue: CapaStatusTableItem[];
    CAPAtableDataComingDue: CapaStatusTableItem[];
    CAPAtableDataAging: CapaStatusTableItem[];
    CAPAtableDataOpen: CapaStatusTableItem[];
    CAPAEVtableDataAll: CapaEVTableItem[];
    CAPAEVtableDataOverdue: CapaEVTableItem[];
    CAPAEVtableDataComingDue: CapaEVTableItem[];
    CAPAEVtableDataAging: CapaEVTableItem[];
    CAPAEVtableDataOpen: CapaEVTableItem[];
    capaAdherenceMtdGraph: graphItemAdherenceMtd[];
    capaAdherence6Month: graphItemAdherenceMtd[];
    capaAgingMtd: graphItemAging6m[];
    capaAging6Month: graphItemAging6m[];
    graphData: StringKeyDataProps[];
  }
  
interface CapaStatusSummaryItem {
    number_of_overdues: number | string;
    coming_due_in_7_days: number | string;
    open_capas: number | string;
    aging_percentage: number | string;
    target: number | string;
    capa_adherence_percentage_mtd: number | string;
    capa_ev_percentage_mtd: number | string;
    capa_aging_percentage_mtd: number | string;
}

  interface CapaStatusTableItem {
    capa_id?: number | string;
    status?: number | string;
    type?: number | string;
    area?: number | string;
    qa_contact?: number | string;
    capa_owner?: number | string;
    original_target_due_date?: number | string;
    latest_target_due_date?: number | string;
    closure_date?: number | string;
    short_description?: number | string;
  }

  interface CapaEVTableItem {
    ev_id?: number | string;
    status?: number | string;
    clinical_commercial?: number | string;
    effective?: number | string;
    qa_contact?: number | string;
    ev_owner?: number | string;
    original_target_due_date?: number | string;
    latest_target_due_date?: number | string;
    closure_date?: number | string;
    short_description?: number | string;
  }
  interface graphItemAdherenceMtd {
    compliance_date?: number | string;
    not_on_time?: number | string;
    on_time?: number | string;
    aging_percentage?: number | string;
  }
  
  interface graphItemAging6m {
    compliance_date?: number | string;
    non_aging?: number | string;
    aging?: number | string;
    aging_percentage?: number | string;
  }
  
  const fetchCapaStatusDeepDive = createAsyncThunk<CapaStatusDataType, FetchDataParams>(
    CAPA_DEEP_DIVE_URL,
    async (params) => {
      const { workstream, site, persona, tierNumber, shift, costCenter, line, building } = params;
      const token: string = getOktaToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}${CAPA_DEEP_DIVE_URL}?workStream=${workstream}&site=${site}&persona=${persona}&tierNumber=${tierNumber}&line=${line}&shift=${shift}&costCenter=${costCenter}&building=${building}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch Capa status');
      }
      return await response.json();
    }
  )

  const capaStatusDeepDiveReducer = createSlice({
    name: 'capaStatusDeepDive',
    initialState,
    reducers: {
      clearQualityChangeControlData(state) {
        state.qualityCapaDeepDiveInfo = null
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchCapaStatusDeepDive.pending, (state) => {
          state.loading = true;
          state.error = false;
        })
        .addCase(fetchCapaStatusDeepDive.fulfilled, (state, action) => {
          state.loading = false;
          state.qualityCapaDeepDiveInfo = action.payload;
        })
        .addCase(fetchCapaStatusDeepDive.rejected, (state, action) => {
          state.loading = false;
          state.error = true;
        });
    },
  });
  
  export { fetchCapaStatusDeepDive };
  export const { clearQualityChangeControlData } = capaStatusDeepDiveReducer.actions
  
  export default capaStatusDeepDiveReducer.reducer;