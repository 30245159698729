import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BaseViewCardPropsType, FetchDataParams, QueryParamsPropsType } from 'types/wctTypes';
import { filterParams, getMonthName, getOktaToken } from 'utils/common-methods';
import { MAJOR_DEVIATION_URL } from 'utils/constants'
import { StringKeyDataProps } from 'utils/data-types';

interface majorDeviationSummaryItem {
    overdue?: number | string;
    coming_due_in_7days?: number | string;
    aging_open_percentage?: number | string;
    open_deviation?: number | string;
    mtd_deviation_ad_percentage?: number | string;
    mtd_aging_deviation_percentage?: number | string;
}

interface majorDeviationGraphItem {
    Month?: number | string;
    Year?: number | string;
    deviation_percentage_6m?: number | string;
}

interface majorDeviationDataType {
    summary: majorDeviationSummaryItem[];
    graphData: majorDeviationGraphItem[];
    query_params?: QueryParamsPropsType
}

interface majorDeviation {
    Month?: number | string;
    Year?: number | string;
    deviation_percentage_6m?: number | string;
  }

interface majorDeviationInfoState {
    majorDeviationInfo: BaseViewCardPropsType | null;
    loading: boolean;
    error: boolean;
}

const initialState: majorDeviationInfoState = {
    majorDeviationInfo: null,
    loading: false,
    error: false,
}

const fetchMajorDeviation = createAsyncThunk<majorDeviationDataType, FetchDataParams>(
    MAJOR_DEVIATION_URL,
    async (params) => {
        const filteredParams = filterParams(params)

        const token: string = getOktaToken();
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}${MAJOR_DEVIATION_URL}?${new URLSearchParams(filteredParams).toString()}`,
            {
                method: "GET",
                headers: {
                    authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );

        if (!response.ok) {
            throw new Error('Failed to fetch Major Deviation');
        }
        return await response.json();
    }
)

const majorDevConvertData = (data: majorDeviation[]): StringKeyDataProps[] => {
    const sortedData = [...data].sort((a, b) => {
        if (typeof a.Month === "number" && typeof b.Month === "number") {
            return a.Month - b.Month;
        } else {
            return Number(a.Month) - Number(b.Month);
        }
    });
    return sortedData.map((item) => {
        const monthNumber =
            typeof item.Month === "number" ? item.Month : Number(item.Month);
        const value =
            typeof item.deviation_percentage_6m === "number"
                ? item.deviation_percentage_6m.toFixed(2)
                : "0.00";

        const monthName = getMonthName(monthNumber - 1)
        return { name: monthName, value: parseFloat(value) };
    });
}

const transformData = (majorDeviationInfo: majorDeviationDataType): BaseViewCardPropsType => {
    const majorDeviationBaseViewData: BaseViewCardPropsType = {
        cardData: {},
        graphData: [],
        graphParams: {},
        cardProps: {
            alertType: 'success'
        }
    }

    const tierLabel = majorDeviationInfo.query_params?.tierLabel

    const MajorDeviationSanitizedData: StringKeyDataProps = {};
    let overdue;
    let comingDueIn7Days;
    let agingOpenPercentage;
    let openDeviation;
    let mtdDeviationAdPercentage;
    let mtdAgingDeviationPercentage;
    if (majorDeviationInfo?.summary?.length) {
        overdue = majorDeviationInfo?.summary[0]?.overdue;
        comingDueIn7Days = majorDeviationInfo?.summary[0]?.coming_due_in_7days;
        agingOpenPercentage =
            majorDeviationInfo?.summary[0]?.aging_open_percentage;
        openDeviation = majorDeviationInfo?.summary[0]?.open_deviation;
        mtdDeviationAdPercentage =
            majorDeviationInfo?.summary[0]?.mtd_deviation_ad_percentage;
        mtdAgingDeviationPercentage =
            majorDeviationInfo?.summary[0]?.mtd_aging_deviation_percentage;
    }

    if (typeof overdue === "number" && overdue >= 0) {
        MajorDeviationSanitizedData.OVERDUE = overdue;
    }
    if (typeof comingDueIn7Days === "number" && comingDueIn7Days >= 0) {
        MajorDeviationSanitizedData["COMING DUE IN 7 DAYS"] = comingDueIn7Days;
    }
    if (typeof agingOpenPercentage === "number" && agingOpenPercentage >= 0) {
        MajorDeviationSanitizedData["AGING OPEN %"] = agingOpenPercentage;
    }
    if (typeof openDeviation === "number" && openDeviation >= 0) {
        MajorDeviationSanitizedData["OPEN DEVIATION"] = openDeviation;
    }
    if (
        typeof mtdDeviationAdPercentage === "number" &&
        mtdDeviationAdPercentage >= 0
    ) {
        MajorDeviationSanitizedData["MTD DEVIATION ADHERENCE %"] =
            mtdDeviationAdPercentage;
    }
    if (
        typeof mtdAgingDeviationPercentage === "number" &&
        mtdAgingDeviationPercentage >= 0
    ) {
        MajorDeviationSanitizedData["MTD AGING DEVIATION %"] =
            mtdAgingDeviationPercentage;
    }
    majorDeviationBaseViewData.cardData = MajorDeviationSanitizedData

    if (tierLabel?.toLowerCase() === "tier 3") {
        majorDeviationBaseViewData.graphParams.tooltipContent = "% of TRA Deviations closed meeting target due date in last 6 months"
    } else {
        majorDeviationBaseViewData.graphParams.tooltipContent = ""
    }

    if (
        tierLabel?.toLowerCase() === "tier 2" &&
        typeof overdue === "number" &&
        overdue > 0
    ) {
        majorDeviationBaseViewData.cardProps.alertType = "error"
    } else if (
        tierLabel?.toLowerCase() === "tier 3" &&
        typeof mtdDeviationAdPercentage === "number" &&
        mtdDeviationAdPercentage <= 95
    ) {
        majorDeviationBaseViewData.cardProps.alertType = "error"
    } else {
        majorDeviationBaseViewData.cardProps.alertType = "success"
    }

    if (
        tierLabel?.toLowerCase() === "tier 3" &&
        majorDeviationInfo?.graphData?.length
    ) {
        const inputData1: majorDeviation[] = majorDeviationInfo?.graphData;
        const outputData: StringKeyDataProps[] = majorDevConvertData(inputData1);
        majorDeviationBaseViewData.graphData = outputData
        majorDeviationBaseViewData.graphParams.isGraph = true
    } else {
        majorDeviationBaseViewData.graphData = []
        majorDeviationBaseViewData.graphParams.isGraph = false
    }

    return majorDeviationBaseViewData
}

const majorDeviationReducer = createSlice({
    name: 'majorDeviation',
    initialState,
    reducers: {
        clearMajorDeviationData(state) {
            state.majorDeviationInfo = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMajorDeviation.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(fetchMajorDeviation.fulfilled, (state, action) => {
                state.loading = false;
                state.majorDeviationInfo = transformData(action.payload);
            })
            .addCase(fetchMajorDeviation.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
            });
    },
});

export { fetchMajorDeviation };

export const { clearMajorDeviationData } = majorDeviationReducer.actions

export default majorDeviationReducer.reducer;