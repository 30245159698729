import { CustomAxisTickProps } from "../CustomMetricCardTypes";
import TickFormatterCss from './TickFormatter.module.css'

const CustomXAxisTick: React.FC<CustomAxisTickProps> = ({ x, y, payload, startLabel=true, endLabel=true }) => {
    const isStartLabel = payload.value === startLabel;
    const isEndLabel = payload.value === endLabel;
  
    if (!isStartLabel && !isEndLabel) {
      return null; // Skip labels in between
    }
  
    return (
      <text
        x={x}
        y={y + 8}
        textAnchor={isStartLabel ? 'start' : isEndLabel ? 'end' : 'end'}
        className={TickFormatterCss.customTick}
      >
        {payload.value}
      </text>
    );
};

export default CustomXAxisTick