import "../../assets/bootstrap.scss";
import React, { useContext, useEffect, useState } from "react";
import LineCardContent from "./line-card-content";
import LineCardHeader from "./line-card-header";
import { useWindowSizeListener } from "hooks/use-windowsize-listener";
import LineCardContentMobile from "./line-card-content-mobile";
import { lineRowType } from "api/fetch-api";
import "./index.scss";
import { AppContext } from "context/app-context";
import { MatomoContext } from "utils/matomo-context-provider";

interface AllLinesProps {
  viewChangeFn: () => void;
  linesDataList: any;
}

interface sortCriteriaType {
  value: string;
  name: string;
  type: string;
  direction: string;
}

const AllLinesPage: React.FC<AllLinesProps> = (props: AllLinesProps) => {
  const { viewChangeFn } = props;

  const context = useContext(MatomoContext)
  const screenType = useWindowSizeListener();
  const isMobile = screenType === "mobile";
  const isTab = screenType === "tab";

  const sortCriteriaList: sortCriteriaType[] = [
    {
      value: "line_status",
      name: "Line Status",
      type: "string",
      direction: "asc",
    },
    {
      value: "line_desc",
      name: "Line Number",
      type: "string",
      direction: "asc",
    },
    {
      value: "material_desc",
      name: "Batch Name",
      type: "string",
      direction: "asc",
    },
    {
      value: "batch_no",
      name: "Batch Number",
      type: "string",
      direction: "asc",
    },
    {
      value: "progress_label",
      name: "Batch Progress",
      type: "string",
      direction: "asc",
    },
    { value: "phase", name: "Phase", type: "string", direction: "asc" },
    {
      value: "machine_status",
      name: "Machine Status",
      type: "string",
      direction: "asc",
    },
    { value: "oee", name: "OEE", type: "string", direction: "asc" },
  ];
  const [selectedSort, setSelectedSort] = useState({
    value: "line_status",
    name: "Line Status",
    type: "string",
    direction: "asc",
  });
  const [sortedArray, setSortedArray] = useState<lineRowType[]>(
    props.linesDataList
  );
  const handleSort = (sortType: string) => {
    const sortObject = sortCriteriaList.filter((item: sortCriteriaType) => {
      if (item.value === sortType) {
        context.trackApplicationFeature("sort", "Sort By", item.value)
        return true;
      }
      return false;
    });    
    setSelectedSort(sortObject[0]);
  };

  const handleSortDirection = (newSortSelected: sortCriteriaType): void => {
    setSelectedSort(newSortSelected);
  };

  useEffect(() => {
    const originalList = [...props.linesDataList];
    if (selectedSort.type === "string") {
      originalList.sort((a: any, b: any) => {
        const fa = a[selectedSort.value]?.toLowerCase();
        const fb = b[selectedSort.value]?.toLowerCase();
        if (selectedSort.direction === "asc") {
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
        } else {
          if (fa < fb) {
            return 1;
          }
          if (fa > fb) {
            return -1;
          }
        }
        return 0;
      });
    } else {
      originalList.sort((a: any, b: any) => {
        if (selectedSort.direction === "asc") {
          return a - b;
        } else {
          return b - a;
        }
      });
    }
    setSortedArray(originalList);
  }, [selectedSort,props.linesDataList]);

  //TODO: dropdown component should be made reusable
  return (
    <div className="aoh-mb-4 aoh-lines-container" id="all-lines-container">
      {isMobile || isTab ? (
        <div className={`aoh-d-flex ops-mt-2 `}>
          <span className="aoh-dropdown-value ops-mx-2 ops-mt-2 ops-fw-bold ops-fs-6 ops-mb-2">
            {" "}
            SORT BY{" "}
          </span>
          <select
            className="aoh-dropdown ops-fs-5 ops-fw-medium "
            onChange={(e) => handleSort(e.target.value)}
            value={selectedSort.value}
            defaultValue="Line Status"
          >
            {sortCriteriaList?.map((item: any, index: number) => {
              return (
                <option
                  key={index}
                  value={item.value}
                  className="aoh-dropdown-value"
                >
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
      ) : (
        <></>
      )}
      {!isMobile && !isTab ? (
        <LineCardHeader
          selectedSort={selectedSort}
          sortLine={handleSortDirection}
          sortCriteriaList={sortCriteriaList}
        />
      ) : (
        <></>
      )}

      {!isMobile && !isTab
        ? sortedArray?.map((el, i) => { 
                      return (
              <div
                className={
                  (el.line_status === "Down"
                    ? "danger-line-wrapper"
                    : "aoh-all-lines-wrapper") +
                  (el.is_non_commercial ? " aoh-non-comm" : " ") +
                  " aoh-d-flex justify-content-between align-items-center ops-mb-0 aoh-w-100 aoh-mb-2 aoh-mt-2"
                }
                key={i}
              >
                <LineCardContent
                  key={i}
                  lineNo={el.line_desc}
                  batchNo={el.batch_no}
                  viewChangeFn={viewChangeFn}
                  batchName={el.material_desc}
                  phase={el.phase}
                  machineStatus={el.machine_status}
                  oee={el.oee}
                  sortType={"line_status"}
                  lineStatus={el.line_status}
                  batchProgress={el.progress_label}
                  statusColor={el.line_status_color_code}
                  progress={el.progress}
                  isNonCommercial={el.is_non_commercial}
                  building ={el.building}
                />
              </div>
            );
          })
        : sortedArray?.map((el, i) => {
            return (
              <div
                className={
                  (el.line_status === "Down"
                    ? "danger-line-wrapper"
                    : "aoh-all-lines-wrapper") +
                  " aoh-d-flex justify-content-between align-items-center   ops-mb-0  aoh-mb-2 aoh-mt-2 "
                }
                key={i}
              >
                <LineCardContentMobile
                  key={i}
                  lineNo={el.line_desc}
                  batchNo={el.batch_no}
                  viewChangeFn={viewChangeFn}
                  batchName={el.material_desc}
                  phase={el.phase}
                  machineStatus={el.machine_status}
                  oee={el.oee}
                  sortType={selectedSort.value}
                  lineStatus={el.line_status}
                  statusColor={el.line_status_color_code}
                  batchProgress={el.progress_label}
                  progress={el.progress}
                  isNonCommercial={el.is_non_commercial}
                  building ={el.building}
                />
              </div>
            );
          })}
    </div>
  );
};

export default AllLinesPage;
