import React from "react";
import wctCss from "./../../../page/workCenterTeam.module.css";
import AIGeneratedIcon from "assets/icons/AIGeneratedIcon";
import { LikeTwoTone, DislikeTwoTone, CloseOutlined } from "@ant-design/icons";
import { Button } from "@gitlab-rtsensing/component-library";
import DeepdiveDrawer from "components/common/drawer/DeepdiveDrawer";

interface AiGeneratedComponentProps {
  summary: any;
  tierLabel: any;
}

export const AiGeneratedComponent: React.FC<AiGeneratedComponentProps> = ({ summary , tierLabel }) => {
  const { deepDiveAIContainer, sqdipBtn } = wctCss;
  const aiNotificationRef = React.useRef<HTMLDivElement>(null);
  const paragraphRef = React.useRef<HTMLParagraphElement>(null);
  const [showMore, setShowMore] = React.useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [deepDiveAlert, setDeepDiveAlert] = React.useState("");
  const handleShowMoreClick = () => {
    setShowMore(!showMore);
    if (paragraphRef.current) {
      paragraphRef.current.scrollTop = 0; // Scroll to the top
    }
  };

  const summaryComponent = () => {
    const loopData = !showMore ? summary?.slice(0, 1) : summary;
    return (
      <div>
        {loopData?.map((item: any, index: any) => (
          <div 
            className="d-flex align-items-center justify-content-between"
            style={{fontSize: '12px', width: '98%', marginLeft: '5px', marginTop: index === 0 ? '0px' : '5px', paddingTop: index === 0 ? '0px' : '5px', borderTop: index === 0 ? 'none' : '1px solid #e3e3e3'}}
            key={index}
          >
           <div
                className="d-flex"
                title="Submit Feedback"
                style={{ marginRight: "20px" }}
              >
                <LikeTwoTone
                  twoToneColor="#52c41a"
                  style={{ fontSize: "18px" }}
                />
                <DislikeTwoTone
                  twoToneColor="#E01029"
                  style={{
                    fontSize: "18px",
                    marginLeft: "10px",
                  }}
                />
              </div>
              <div style={{ width: "78px", marginRight: "40px", fontWeight: 700, fontSize: "16px", textAlign: 'center' }}>
                {`${item?.metric?.charAt(0).toUpperCase()}${item?.metric?.slice(1)?.toLowerCase()}`}
              </div>
              <div
                style={{ width: "69%", marginRight: "20px" }}
                dangerouslySetInnerHTML={{ __html: item?.summary }}
              ></div>
          <Button
            className={sqdipBtn}
            label="Deep Dive"
            type="secondary"
            onClick={() => {setIsDrawerOpen(true); setDeepDiveAlert(item?.summary)}}
          />
           <CloseOutlined title="Disable Alert" style={{ marginLeft: "20px", fontSize: "16px" }} />
        </div>
        ))}
      </div>
    );
  };
 
  return (
    <div
      className={deepDiveAIContainer}
      ref={aiNotificationRef}
      id="sqdip-ai-insights-summary"
    >
      <DeepdiveDrawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} deepDiveAlert={deepDiveAlert} tier= {tierLabel} metrics={summary ? summary[0].metric : ""} id={summary ? summary[0].id : ""}/>
      <div className="d-flex" style={{width: '100%'}}>
        <AIGeneratedIcon className={wctCss.aiSVGIcon} />
        <div className="d-flex flex-column" style={{maxHeight: '238px', overflowY: 'auto', width: '100%'}}>
          {summaryComponent()}
        </div>
      </div>
      <div className={wctCss.buttonText} onClick={handleShowMoreClick}>
        {showMore ? "Show Less" : "Show More"}
      </div>
    </div>
  );
};
