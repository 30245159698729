import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOktaToken } from "utils/common-methods";

interface FetchDataParams {
  workstream?: string | undefined;
  site?: string | undefined;
  persona?: string | undefined;
  tierNumber?: string | undefined;
  shift?: string | undefined;
  costCenter?: string | undefined;
  line?: string | undefined;
  building?: string | undefined;
}

const fetchOverallSummary = createAsyncThunk<any, FetchDataParams>(
  "/work-center-team/base-view/summary",
  async (params: FetchDataParams) => {
    const { workstream, site, persona, tierNumber, shift, costCenter, line, building} =
      params;
    const token: string = getOktaToken();
    const response = await fetch(
      `${process.env.REACT_APP_GENAI_API_URL}/work-center-team/base-view/summary?workStream=${workstream}&site=${site}&persona=${persona}&tierNumber=${tierNumber}&line=${line}&shift=${shift}&costCenter=${costCenter}&building=${building}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch overall summary");
    }
    return await response.json();
  }
);

const overallSummaryReducer = createSlice({
  name: "overallSummary",
  initialState: {
    overallSummaryInfo: { data: [] as any },
    isBaseSummaryLoading: false,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOverallSummary.pending, (state) => {
      state.isBaseSummaryLoading = true;
      state.error = false;
    });
    builder.addCase(fetchOverallSummary.fulfilled, (state, action) => {
      state.overallSummaryInfo = action.payload;
      state.isBaseSummaryLoading = false;
      state.error = false;
    });
    builder.addCase(fetchOverallSummary.rejected, (state) => {
      state.isBaseSummaryLoading = false;
      state.error = true;
    });
  },
});

export { fetchOverallSummary };
export default overallSummaryReducer.reducer;
