import { Radio } from "@gitlab-rtsensing/component-library";

type TimePeriod = string

interface RadioFiltersProps {
    timePeriod: TimePeriod;
    setTimePeriod: (value: TimePeriod) => void;
    tierLabel: string;
}
export const RadioFilters: React.FC<RadioFiltersProps> = ({
    timePeriod,
    setTimePeriod,
    tierLabel
}) => {
    let timePeriods: TimePeriod[] = []
    if (tierLabel === "Tier 2") {
        timePeriods = ["All", "Overdue", "Coming Due", "Aging", "Open"];
    } else if (tierLabel === "Tier 1" || tierLabel === "Tier 3") {
        timePeriods = ["All", "Adherence", "Aging", "Open"];
    } else {
        timePeriods = ["All", "Adherence", "Aging", "Open"];
    }
    if (!timePeriods.includes(timePeriod)) {
        timePeriod = "All";
    }
    return (
        <div className="d-flex" style={{ marginBottom: "10px" }}>
            <div className="ops-fs-5 ops-fw-bold" style={{ marginRight: "15px" }}>
                TIME PERIOD :
            </div>
            {timePeriods.map((period) => (
                <div key={period} style={{ marginRight: "8px" }}>
                    <Radio
                        name="timePeriod"
                        value={period}
                        checked={timePeriod === period}
                        onChange={() => setTimePeriod(period)}
                        variant="primary"
                    />
                </div>
            ))}
        </div>
    );
};
