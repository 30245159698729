import { Modal } from "antd";
import DeepdiveModalHeader from "components/aoh-deep-dive-main/deep-dive-modal-header/deepDiveModalHeader";
import { AiGeneratedComponent } from "components/resuable/ai-generated/AiGenerated";
import React from "react";

const withAohModal = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  const ModalComponent: React.FC<
    P & {
      tierLabel: string;
      sqdipTabType: string;
      shift?: string;
      building?: string;
      line?: string;
      costCenter?: string;
      isOpen: boolean;
      isClose: () => void;
      deepdiveSummaryData: any;
    }
  > = (props) => {
    const { tierLabel, shift, line, building, costCenter, sqdipTabType, isOpen, isClose, deepdiveSummaryData, ...restProps } = props;

    return (
      <Modal
        title={sqdipTabType}
        open={isOpen}
        onCancel={isClose}
        width={1248}
        footer={null}
      >
        <DeepdiveModalHeader tierLabel={tierLabel} shift={shift} line={line} building={building} costCenter={costCenter}/>
        <AiGeneratedComponent summary={deepdiveSummaryData} tierLabel = {tierLabel} />
        <WrappedComponent
          {...(restProps as P)}
          sqdipTabType={sqdipTabType}
          tierLabel={tierLabel}
        />
      </Modal>
    );
  };

  ModalComponent.displayName = `WithAohModal(${
    WrappedComponent.displayName ?? WrappedComponent.name ?? "Component"
  })`;

  return ModalComponent;
};

export default withAohModal;
