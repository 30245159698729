import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { actionMap, ParamsType } from "utils/custom-metric-card.helper";
import { AppDispatch, RootState } from "store";
interface customMetricParamsState {
  customMetricParams: ParamsType | null;
}

const initialState: customMetricParamsState = {
  customMetricParams: null,
};

export const updateCustomMetricParams = createAsyncThunk(
  "customMetricParams/setCustomMetricParams",
  async (newParams: ParamsType, { dispatch }) => {
    dispatch(setCustomMetricParams(newParams));
  }
);

export const callCustomMetricApiWithCurrentParams = createAsyncThunk<
  Promise<void>,
  keyof typeof actionMap,
  { dispatch: AppDispatch;  state: RootState}
  >('customMetricParams/callCustomMetricApiWithCurrentParams',
  async (actionKey: keyof typeof actionMap, { getState, dispatch }) => {
    const state = getState() as { customMetricParams: customMetricParamsState }
    const params = state.customMetricParams.customMetricParams;
    const apiAction = actionMap[actionKey];
    if (apiAction && params) {
      await apiAction(dispatch, params);
    }
  }
)

const customMetricParamsSlice = createSlice({
  name: 'customMetricParams',
  initialState,
  reducers: {
    setCustomMetricParams: (state, action: PayloadAction<ParamsType>) => {
      state.customMetricParams = action.payload
    },
    resetCustomMetricParams: (state) => {
      state.customMetricParams = null;
    }
  }
})

export const { setCustomMetricParams, resetCustomMetricParams } = customMetricParamsSlice.actions;
export default customMetricParamsSlice.reducer;