import { TableColumnsType } from "antd";
import { ascendDescend } from "utils/common-methods";
import { StringKeyAnyDataProps, StringKeyDataProps } from "utils/data-types";
import TooltipWrapper from "utils/TooltipWrapper";

export interface FetchDataParams {
  workStream: string;
  site: string;
  persona: string;
  tierNumber?: string;
  shift?: string;
  costCenter?: string;
  building?: string;
  line?: string;
  interval?: number;
}

export interface QueryParamsPropsType {
  tierLabel: string
}

export interface CardPropsType {
  alertType: "error" | "success"
}

export interface BaseViewCardPropsType {
  cardData: StringKeyDataProps
  graphData: StringKeyDataProps[]
  graphParams: StringKeyAnyDataProps
  cardProps: CardPropsType
}

export interface GraphParamsType {
  isGraph?: boolean
  tooltipContent?: string
  yAxisUnit?: string
}

export interface SummaryItem {
  total_due_in_5_days?: string;
  total_due_in_2_days?: string;
  "compliance_%_for_line"?: string;
  "%_compliance"?: string;
}

interface GxpDataItem {
  due_date: string;
  name_of_task: string;
  task_code: string;
}

interface GxpGraphItem {
  date?: string
  compliance?: number | string;
  compliance_date?: string;
  compliance_week?: string;
  cumulative_overdue?: number | string;
  cumulative_on_track?: number | string;
  compliance_rate_percentage?: number | string;
  compliance_percent?: number | string;
  manager_name?: string;
  overdue?: number | string;
  cost_center_name?: string;
  Compliance_percentage?: number;
  week_start?: string;
  formatted_date?: string;
}

export interface GxpDataType {
  summary?: SummaryItem
  table_data: GxpDataItem[]
  heatmap?: GxpGraphItem[]
}

export interface GxpInfoType {
  summary?: SummaryItem
  tableData: GxpDataItem[]
  heatmapData?: GxpGraphItem[]
}

export const GXPTableColumnsType: TableColumnsType<any> = [
  {
    title: "DUE DATE",
    dataIndex: "due_date",
    sorter: (a, b) => {
      return ascendDescend(a.due_date, b.due_date);
    },
  },
  {
    title: "NAME OF TASK",
    dataIndex: "name_of_task",
  },
  {
    title: "TASK CODE",
    dataIndex: "task_code",
  },
];

// Data for aging chart

export interface AgingChartData {
  date: string;
  aging_last_7days: number;
}

// Data for normal (non-aging) chart

export interface NormalChartData {
  date: string;
  normal_last_7days: number;
}

// Data for trend chart

export interface TrendChartData {
  last_7d_date: string;
  coming_due_last_7d_count: number;
}

export interface DataItem {
  date: string;
  aging?: number;
  adherence?: number;
  trends?: number;
}

export interface Summary {
  aging: {
    aging_mtd: number;
  };

  adherence: {
    adherence_mtd: number;
  };
}

interface MTDMonthChartType {
  mtd: StringKeyDataProps[];
  month: StringKeyDataProps[];
}

interface ChangeControlChartsType {
  is_aging_chart?: StringKeyDataProps[];
  not_aging_chart?: StringKeyDataProps[];
  trend_chart?: StringKeyDataProps[];
  aging?: MTDMonthChartType;
  adherence?: MTDMonthChartType;
}

interface ChangeControlChartsInfoType {
  isAgingChart?: StringKeyDataProps[];
  notAgingChart?: StringKeyDataProps[];
  trendChart?: StringKeyDataProps[];
  aging?: MTDMonthChartType;
  adherence?: MTDMonthChartType;
}

export interface ChangeControlSummaryType {
  overdue?: StringKeyDataProps;
  due_in_7_days?: StringKeyDataProps;
  open?: StringKeyDataProps;
  is_aging?: StringKeyDataProps;
  aging?: StringKeyDataProps;
  adherence?: StringKeyDataProps;
}

export interface ChangeControlDataType {
  summary: ChangeControlSummaryType;
  charts: ChangeControlChartsType;
}

export interface ChangeControlInfoType {
  summary: ChangeControlSummaryType;
  charts: ChangeControlChartsInfoType;
}

interface minorDeviationColumnType {
  initiated_date: string;
  deviation_id: string;
  area: string;
  status: string;
  nc_type: string;
  age: number | string;
  deviation_owner: string;
  qa_contact: string;
  short_desc: string;
}

interface ChangeControlColumnType {
  cc_id: number | string;
  status: number | string;
  initiated_date: number | string;
  category_type: number | string;
  qa_owner: number | string;
  cc_owner: number | string;
  short_description: number | string;
  target_due_date: number | string;
  closure_date: number | string;
  function: number | string;
}

export interface DeepDiveTabItemsType {
  Safety: string[]
  Quality: string[]
  Delivery: string[]
  Inventory: string[]
  Productivity: string[]
}

export const minorDeviationColumns: TableColumnsType<minorDeviationColumnType> = [
  {
    title: "INITIATED DATE",
    dataIndex: "initiated_date",
    sorter: (a, b) => {
      return ascendDescend(a.initiated_date, b.initiated_date);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "DEVIATION ID",
    dataIndex: "deviation_id",
    sorter: (a, b) => {
      return ascendDescend(a.deviation_id, b.deviation_id);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "STATUS",
    dataIndex: "status",
    sorter: (a, b) => {
      return ascendDescend(a.status, b.status);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "AREA",
    dataIndex: "area",
    sorter: (a, b) => {
      return ascendDescend(a.area, b.area);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "TYPE",
    dataIndex: "nc_type",
    sorter: (a, b) => {
      return ascendDescend(a.nc_type, b.nc_type);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "AGE",
    dataIndex: "age",
    sorter: (a, b) => {
      return ascendDescend(a.age, b.age);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "DEVIATION OWNER",
    dataIndex: "deviation_owner",
    sorter: (a, b) => {
      return ascendDescend(a.deviation_owner, b.deviation_owner);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "QA CONTACT",
    dataIndex: "qa_contact",
    sorter: (a, b) => {
      return ascendDescend(a.qa_contact, b.qa_contact);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "SHORT DESCRIPTION",
    dataIndex: "short_desc",
    sorter: (a, b) => {
      return ascendDescend(a.short_desc, b.short_desc);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
]

export const ChangeControlColumns: TableColumnsType<ChangeControlColumnType> = [
  {
    title: "CC ID",
    dataIndex: "cc_id",
    sorter: (a, b) => {
      return ascendDescend(a.cc_id, b.cc_id);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "STATUS",
    dataIndex: "status",
    sorter: (a, b) => {
      return ascendDescend(a.status, b.status);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "INITIATED DATE",
    dataIndex: "initiated_date",
    sorter: (a, b) => {
      return ascendDescend(a.initiated_date, b.initiated_date);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "CATEGORY TYPE",
    dataIndex: "category_type",
    sorter: (a, b) => {
      return ascendDescend(a.category_type, b.category_type);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "QA OWNER",
    dataIndex: "qa_owner",
    sorter: (a, b) => {
      return ascendDescend(a.qa_owner, b.qa_owner);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "CC OWNER",
    dataIndex: "cc_owner",
    sorter: (a, b) => {
      return ascendDescend(a.cc_owner, b.cc_owner);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "SHORT DESCRIPTION",
    dataIndex: "short_description",
    sorter: (a, b) => {
      return ascendDescend(a.short_description, b.short_description);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "TARGET DUE DATE",
    dataIndex: "target_due_date",
    sorter: (a, b) => {
      return ascendDescend(a.target_due_date, b.target_due_date);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "CLOSURE DATE",
    dataIndex: "closure_date",
    sorter: (a, b) => {
      return ascendDescend(a.closure_date, b.closure_date);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "FUNCTION",
    dataIndex: "function",
    sorter: (a, b) => {
      return ascendDescend(a.function, b.function);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  }
]

interface majorDeviationColumnType {
  initiated_date: string;
  deviation_id: string;
  area: string;
  status: string;
  nc_type: string;
  extension_times: number | string;
  deviation_owner: string;
  qa_contact: string;
  short_desc: string;
}

export const majorDeviationColumns: TableColumnsType<majorDeviationColumnType> = [
  {
    title: "INITIATED DATE",
    dataIndex: "initiated_date",
    sorter: (a, b) => {
      return ascendDescend(a.initiated_date, b.initiated_date);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "DEVIATION ID",
    dataIndex: "deviation_id",
    sorter: (a, b) => {
      return ascendDescend(a.deviation_id, b.deviation_id);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "STATUS",
    dataIndex: "status",
    sorter: (a, b) => {
      return ascendDescend(a.status, b.status);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "AREA",
    dataIndex: "area",
    sorter: (a, b) => {
      return ascendDescend(a.area, b.area);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "TYPE",
    dataIndex: "nc_type",
    sorter: (a, b) => {
      return ascendDescend(a.nc_type, b.nc_type);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "EXTENSION TIMES",
    dataIndex: "extension_times",
    sorter: (a, b) => {
      return ascendDescend(a.extension_times, b.extension_times);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "DEVIATION OWNER",
    dataIndex: "deviation_owner",
    sorter: (a, b) => {
      return ascendDescend(a.deviation_owner, b.deviation_owner);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "QA CONTACT",
    dataIndex: "qa_contact",
    sorter: (a, b) => {
      return ascendDescend(a.qa_contact, b.qa_contact);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "SHORT DESCRIPTION",
    dataIndex: "short_desc",
    sorter: (a, b) => {
      return ascendDescend(a.short_desc, b.short_desc);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
];

interface CapaStatusColumnType {
  capa_id: string;
  status: string;
  type: string;
  area: string;
  qa_contact: string;
  capa_owner: number | string;
  original_target_due_date: string;
  latest_target_due_date: string;
  closure_date: string;
  short_description: string;
}

export const CapaStatusColumns: TableColumnsType<CapaStatusColumnType> = [
  {
    title: "CAPA ID",
    dataIndex: "capa_id",
    sorter: (a, b) => {
      console.log(a,b)
      return ascendDescend(a.capa_id, b.capa_id);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "STATUS",
    dataIndex: "status",
    sorter: (a, b) => {
      return ascendDescend(a.status, b.status);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "TYPE",
    dataIndex: "type",
    sorter: (a, b) => {
      return ascendDescend(a.type, b.type);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "AREA",
    dataIndex: "area",
    sorter: (a, b) => {
      return ascendDescend(a.area, b.area);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "QA CONTACT",
    dataIndex: "qa_contact",
    sorter: (a, b) => {
      return ascendDescend(a.qa_contact, b.qa_contact);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "CAPA OWNER",
    dataIndex: "capa_owner",
    sorter: (a, b) => {
      return ascendDescend(a.capa_owner, b.capa_owner);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "ORIGINAL TARGET DUE DATE",
    dataIndex: "original_target_due_date",
    sorter: (a, b) => {
      return ascendDescend(a.original_target_due_date, b.original_target_due_date);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "LATEST TARGET DUE DATE",
    dataIndex: "latest_target_due_date",
    sorter: (a, b) => {
      return ascendDescend(a.latest_target_due_date, b.latest_target_due_date);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "CLOSURE DATE",
    dataIndex: "closure_date",
    sorter: (a, b) => {
      return ascendDescend(a.closure_date, b.closure_date);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "SHORT DESCRIPTION",
    dataIndex: "short_description",
    sorter: (a, b) => {
      return ascendDescend(a.short_description, b.short_description);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
];

interface CapaEVColumnType {
  ev_id: string;
  status: string;
  clinical_commercial: string;
  effective: string;
  qa_contact: string;
  ev_owner: number | string;
  original_target_due_date: string;
  latest_target_due_date: string;
  closure_date: string;
  short_description: string;
}

export const CapaEVColumns: TableColumnsType<CapaEVColumnType> = [
  {
    title: "EV ID",
    dataIndex: "ev_id",
    sorter: (a, b) => {
      console.log(a,b)
      return ascendDescend(a.ev_id, b.ev_id);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "STATUS",
    dataIndex: "status",
    sorter: (a, b) => {
      return ascendDescend(a.status, b.status);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "CLINICAL_COMMERCIALS",
    dataIndex: "clinical_commercial",
    sorter: (a, b) => {
      return ascendDescend(a.clinical_commercial, b.clinical_commercial);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "EFFECTIVE",
    dataIndex: "effective",
    sorter: (a, b) => {
      return ascendDescend(a.effective, b.effective);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "QA CONTACT",
    dataIndex: "qa_contact",
    sorter: (a, b) => {
      return ascendDescend(a.qa_contact, b.qa_contact);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "EV OWNER",
    dataIndex: "ev_owner",
    sorter: (a, b) => {
      return ascendDescend(a.ev_owner, b.ev_owner);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "ORIGINAL TARGET DUE DATE",
    dataIndex: "original_target_due_date",
    sorter: (a, b) => {
      return ascendDescend(a.original_target_due_date, b.original_target_due_date);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "LATEST TARGET DUE DATE",
    dataIndex: "latest_target_due_date",
    sorter: (a, b) => {
      return ascendDescend(a.latest_target_due_date, b.latest_target_due_date);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "CLOSURE DATE",
    dataIndex: "closure_date",
    sorter: (a, b) => {
      return ascendDescend(a.closure_date, b.closure_date);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
  {
    title: "SHORT DESCRIPTION",
    dataIndex: "short_description",
    sorter: (a, b) => {
      return ascendDescend(a.short_description, b.short_description);
    },
    render: (text: string) => <TooltipWrapper text={text} maxWidth={200} />,
  },
];