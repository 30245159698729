import { Tabs } from "antd";
import React, { useState } from "react";
import { SQDIPType, wctQualityDeepdiveNavigation, wctSafetyDeepdiveNavigation } from "utils/constants";
import wctCss from "../../page/workCenterTeam.module.css";
import { StringKeyDataProps } from "utils/data-types";

const withAohModalTab = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  const ModalTabComponent: React.FC<
    P & {
      tierLabel: string;
      activeKeyDeepDiveTab: string;
      setActiveKeyDeepDiveTab: (key: string) => void;
      sqdipTabType: string
      tabItems: string[]
    }
  > = (props) => {
    const { tierLabel, activeKeyDeepDiveTab, setActiveKeyDeepDiveTab, sqdipTabType, tabItems, ...restProps } =
      props
    
    const items = tabItems.map((sideNavLabel) => {
      return {
        key: sideNavLabel,
        label: <div>{sideNavLabel}</div>,
      };
    })

    return (
      <>
        <Tabs
          activeKey={activeKeyDeepDiveTab}
          onChange={setActiveKeyDeepDiveTab}
          defaultActiveKey={activeKeyDeepDiveTab}
          items={items}
          className={wctCss.tabHeader}
        />
        <WrappedComponent
          {...(restProps as P)}
          sqdipTabType={sqdipTabType}
          activeKeyDeepDiveTab={activeKeyDeepDiveTab}
          tierLabel={tierLabel}
        />
      </>
    );
  };

  ModalTabComponent.displayName = `withAohModalTab(${WrappedComponent.displayName ?? WrappedComponent.name ?? "Component"
    })`;

  return ModalTabComponent;
};

export default withAohModalTab;
