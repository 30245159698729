/* eslint-disable @typescript-eslint/restrict-plus-operands */
import PageLayout from "components/resuable/layout";
import SegmentHeader from "components/resuable/segment-header";
import "./index.scss";
import ShopFloorContainer from "components/shop-floor-container/container.aoh";
import { useLocation, useNavigate, useParams } from "react-router";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "context/app-context";
import { Breadcrumb, ConfigProvider } from "antd";
import { permissionObject, personas } from "utils/constants";
import { BreadcrumbMenuItemType, MCT, PermissionType, PersonasAuthorization } from "types/permission-type";

interface ShopFloorType {
  permissions: PermissionType[];
  setNavList: (navList: any) => void;
}

const ShopFloorPage = (props: ShopFloorType) => {
  const { appState } = useContext(AppContext);
  const { permissions, setNavList } = props;
  const { stage, site, plant, line } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const [persona, setPersona] = useState<string>('Shop Floor')
  const [lineItems, setLineItems] = useState<any>([])

  const sqdipRef = useRef<React.MutableRefObject<HTMLElement>>();
  const batchInfoRef = useRef<React.MutableRefObject<HTMLElement>>();

  useEffect(() => {
    setNavList({
      label: site?.toUpperCase() ?? "",
      authorized: true,
      ...(line !== "all-lines" &&
        !appState.nonCommercialFlag && {
        jumpToSections: [],
      }),
      externalSections: [],
      internalSections: [
        {
          sectionLabel: "SHOP FLOOR",
          links: [{
            label: "All Lines",
            url: `/manufacturing/${site}/shop-floor/all-lines`
          },
          ...appState.allLines.map((line: string, i: number) => {
            return {
              label: line,
              url: `/manufacturing/${site}/shop-floor/${line}`,
            }
          })]
        },
      ]
    });

    let li = []

    li = appState.allLines.map((line: string, i: number) => {
      let index = i + 1
      if (location.pathname.includes("shop-floor")) {
        index++
      }
      return {
        key: `${index}`,
        label: (
          <a rel="noopener noreferrer" href={`/manufacturing/${site}/shop-floor/${line}`}>
            {line}
          </a>
        )
      }
    })

    if (location.pathname.includes("shop-floor")) {
      li.unshift({
        key: `${1}`,
        label: (
          <a rel="noopener noreferrer" href={`/manufacturing/${site}/shop-floor/all-lines`}>
            All Lines
          </a>
        )
      })
    }

    setLineItems(li)
  }, [line, appState]);

  const mfgItems = [
    {
      key: '1',
      label: <a href="/manufacturing">Manufacturing</a>
    }
    // ,
    // {
    //   key: '2',
    //   label: <a href="/cct">CCT</a>
    // }
  ]

  const avaialableSites: Record<string, boolean> = Object.fromEntries(
    Object.entries(permissionObject).filter(([, value]: [string, boolean]) => value)
  )

  const siteItems: BreadcrumbMenuItemType[] = Object.entries(avaialableSites).map(([key,], index) => {
    return {
      key: `${index + 1}`,
      label: (
        <a rel="noopener noreferrer" key={`${key}-menu-${index + 1}`} href={`/manufacturing/${key}`}>
          {key.toUpperCase()}
        </a>
      )
    }
  })

  const personaObject: PersonasAuthorization | undefined = permissions[1]?.workstreams?.mct
    ?.filter((permission: MCT) => permission.url === site)[0]?.personas

  const personaItems = Object.entries(personaObject ? personaObject : {}).filter(([key, value]) => {
    if (key === "eTOC" && value) {
      return false
    } else {
      return true
    }
  })?.map(([key,], index) => {
      const persona = personas.filter(persona => persona.name === key)[0]

      return {
        key: `${index + 1}`,
        label: (
          <a rel="noopener noreferrer" href={`/manufacturing/${site}/${persona?.url}`}>
            {persona?.label}
          </a>
        )
      }
    })

  useEffect(() => {
    if (!location.pathname.includes("shop-floor")) {
      setPersona('Work Center Team')
    } else {
      setPersona("Shop Floor")
    }

    if (location) {
      const currentSqdip: any = sqdipRef.current;
      const currentBatchInfo: any = batchInfoRef.current;
      if (location.hash === "#aoh-sqdip") {
        if (currentSqdip) {
          window.scroll({
            top:
              currentSqdip?.getBoundingClientRect()?.top +
              window.pageYOffset -
              150,

            behavior: "smooth",
          });
        }
      }
      if (location.hash === "#aoh-current-batch") {
        if (currentBatchInfo) {
          window.scroll({
            top: 10,
            behavior: "smooth",
          });
        }
      }
    }
  }, [location]);

  return (
    <PageLayout className="aoh-shopfloor-layout-wrapper">
      <div className="aoh-pl-64">
        <ConfigProvider theme={{ token: { fontSize: 12 } }}>
          <Breadcrumb
            className="breadcrumb sf-breadcrumb"
            items={[
              {
                title: <a href="/">Digital Control Tower</a>,
              },
              {
                title: <span>Manufacturing</span>,
                menu: { items: mfgItems }
              },
              {
                title: <span>{site?.toUpperCase()}</span>,
                menu: { items: siteItems },
              },
              {
                title: <span>{persona}</span>,
                menu: { items: personaItems },
              },
              {
                title: line ? (line === 'all-lines' ? 'All Lines' : line) : '',
                menu: { items: lineItems }
              }
            ]}
          />
        </ConfigProvider>
        <div className="sticky-header-shop-floor ">
          <SegmentHeader
            id="shop-floor"
            title={`${site?.toUpperCase() ?? ""} Shop Floor`}
          />
        </div>
        <ShopFloorContainer batchInfoRef={batchInfoRef} sqdipRef={sqdipRef} />
      </div>
    </PageLayout>
  );
};
export default ShopFloorPage;
