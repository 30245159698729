import React, { useState } from "react";
import SummaryCard from "../../common/summaryCard/summaryCard";
import "./Quality.scss";
import { CapaEVColumns, CapaStatusColumns, ChangeControlColumns, majorDeviationColumns, minorDeviationColumns } from "types/wctTypes";
import { Button } from "@gitlab-rtsensing/component-library";
import { DownloadOutlined } from "@ant-design/icons";
import { RadioFilters } from "components/resuable/radio-filters/RadioFiltersQuality";
import ColorCodedTable from "components/common/table/ColorCodedTable";
import StackedBarChart, { DataItem } from "components/common/StackedBar/StackedBar";
import AohTable from "components/common/table/AohTable";

interface QualityTier2Props {
    data: any;
    tierLabel: string;
    activeKeyDeepDiveTab: string;
}

const QualityTier2: React.FC<QualityTier2Props> = ({
    data: { summary, heatmapData, tableDataAll, tableDataOverdue, tableDataComingDue, tableDataAging, tableDataOpen, graphData, CAPAtableDataAll, CAPAtableDataOverdue, CAPAtableDataComingDue, CAPAtableDataAging, CAPAtableDataOpen, CAPAEVtableDataAll, CAPAEVtableDataOverdue, CAPAEVtableDataComingDue, CAPAEVtableDataAging, CAPAEVtableDataOpen },
    tierLabel,
    activeKeyDeepDiveTab,
}) => {
    const [minDevTableTimePeriod, setMinDevTableTimePeriod] = useState("All")
    const [minDevTable2TimePeriod, setMinDevTable2TimePeriod] = useState("All")
    const qualityDeepDive: any = {
        "Major Deviation": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary?.[0]}
                />
            ),
            title: "Trend of Daily Coming Due Major Deviation with Volume",
            subtitle: "In last 7 days",
            stackBar: (
                <StackedBarChart
                    data={graphData}
                    chartType="major-deviation"
                />
            ),
            title2: "Major Deviations",
            radioFilters: (
                <RadioFilters
                    timePeriod={minDevTableTimePeriod}
                    setTimePeriod={setMinDevTableTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            button: (
                <Button
                    label="Download"
                    icon={
                        <DownloadOutlined style={{ color: "#0063C3", fontSize: "14px" }} />
                    }
                    iconPosition="right"
                    onClick={() => { }}
                    type="secondary"
                />
            ),
            table: <AohTable
                tableData={
                    (minDevTableTimePeriod === "All" || minDevTableTimePeriod === "Overdue")
                        ? (minDevTableTimePeriod === "All" ? tableDataAll : minDevTableTimePeriod === "Overdue" ? tableDataOverdue : tableDataComingDue) : (minDevTableTimePeriod === "Aging" ? tableDataAging : tableDataOpen)}
                tableColumns={majorDeviationColumns} />,
        },
        "Minor Deviation": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary?.[0]}
                />
            ),
            title: "Trend of Daily Aging Minor Deviations",
            subtitle: "In last 7 days",
            stackBar: (
                <StackedBarChart
                    data={graphData}
                    chartType="minor-deviation"
                />
            ),
            title2: "Minor Deviations",
            radioFilters: (
                <RadioFilters
                    timePeriod={minDevTableTimePeriod}
                    setTimePeriod={setMinDevTableTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            button: (
                <Button
                    label="Download"
                    icon={
                        <DownloadOutlined style={{ color: "#0063C3", fontSize: "14px" }} />
                    }
                    iconPosition="right"
                    onClick={() => { }}
                    type="secondary"
                />
            ),
            table: <AohTable
                tableData={
                    (minDevTableTimePeriod === "All" || minDevTableTimePeriod === "Overdue")
                        ? (minDevTableTimePeriod === "All" ? tableDataAll : minDevTableTimePeriod === "Overdue" ? tableDataOverdue : tableDataComingDue) : (minDevTableTimePeriod === "Aging" ? tableDataAging : tableDataOpen)}
                tableColumns={minorDeviationColumns} />,
        },
        "GXP Training %Compliance": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary}
                />
            ),
            heatmapTitle: "Compliance Rate (%)",
            heatmap: (
                <ColorCodedTable
                    data={heatmapData}
                    metricKey="gxp"
                />
            )
        },
        "CAPA Status": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary}
                />
            ),
            title: "Trend of Daily Coming Due CAPA's with Volume",
            subtitle: "In last 6 months",
            stackBar:
                <StackedBarChart data={graphData} chartType="capa-status" />,
            title2: "CAPA",
            radioFilters: (
                <RadioFilters
                    timePeriod={minDevTableTimePeriod}
                    setTimePeriod={setMinDevTableTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            button: (
                <Button
                    label="Download"
                    icon={
                        <DownloadOutlined style={{ color: "#0063C3", fontSize: "14px" }} />
                    }
                    iconPosition="right"
                    onClick={() => { }}
                    type="secondary"
                />
            ),
            table: <AohTable
            tableData={
                (minDevTableTimePeriod === "All" || minDevTableTimePeriod === "Overdue")
                    ? (minDevTableTimePeriod === "All" ? CAPAtableDataAll : minDevTableTimePeriod === "Overdue" ? CAPAtableDataOverdue : CAPAtableDataComingDue) : (minDevTableTimePeriod === "Aging" ? CAPAtableDataAging : CAPAtableDataOpen)}
            tableColumns={CapaStatusColumns} />,          
            title3: "CAPA EV",
            radioFilters3: (
                    <RadioFilters
                        timePeriod={minDevTable2TimePeriod}
                        setTimePeriod={setMinDevTable2TimePeriod}
                        tierLabel={tierLabel}
                    />
                ),
            button3: (
                    <Button
                        label="Download"
                        icon={
                            <DownloadOutlined style={{ color: "#0063C3", fontSize: "14px" }} />
                        }
                        iconPosition="right"
                        onClick={() => { }}
                        type="secondary"
                    />
                ),
            table3: <AohTable
                    tableData={
                        (minDevTable2TimePeriod === "All" || minDevTable2TimePeriod === "Overdue")
                            ? (minDevTable2TimePeriod === "All" ? CAPAEVtableDataAll : minDevTable2TimePeriod === "Overdue" ? CAPAEVtableDataOverdue : CAPAEVtableDataComingDue) : (minDevTable2TimePeriod === "Aging" ? CAPAEVtableDataAging : CAPAEVtableDataOpen)}
                    tableColumns={CapaEVColumns} />
        },
        "Change Control Status": {
            summaryTitle: "Summary",
            summary: (
                <SummaryCard
                    tierLabel={tierLabel}
                    activeKeyDeepDiveTab={activeKeyDeepDiveTab}
                    data={summary}
                />
            ),
            title: "Trend of Daily Coming Due Change Controls with Volume",
            subtitle: "In last 7 days",
            stackBar:
                <StackedBarChart data={graphData} chartType="change-control-status" />,
            title2: "Change Control",
            radioFilters: (
                <RadioFilters
                    timePeriod={minDevTableTimePeriod}
                    setTimePeriod={setMinDevTableTimePeriod}
                    tierLabel={tierLabel}
                />
            ),
            button: (
                <Button
                    label="Download"
                    icon={
                        <DownloadOutlined style={{ color: "#0063C3", fontSize: "14px" }} />
                    }
                    iconPosition="right"
                    onClick={() => { }}
                    type="secondary"
                />
            ),
            table: <AohTable
                tableData={
                    (minDevTableTimePeriod === "All" || minDevTableTimePeriod === "Overdue")
                        ? (minDevTableTimePeriod === "All" ? tableDataAll : minDevTableTimePeriod === "Overdue" ? tableDataOverdue : tableDataComingDue) : (minDevTableTimePeriod === "Aging" ? tableDataAging : tableDataOpen)}
                tableColumns={ChangeControlColumns} />
        }
    }

    return (
        <div className="quality-tier-main">
            <div className="tier-container">
                <h2 className="tier-title">
                    {/* Summary card */}
                    {qualityDeepDive[activeKeyDeepDiveTab]?.summaryTitle}
                </h2>

            </div>
            {qualityDeepDive[activeKeyDeepDiveTab]?.summary}
            <div
                className={
                    qualityDeepDive[activeKeyDeepDiveTab]?.summary ? "mr-bottom-20" : ""
                }
            ></div>
            {/* Heatmap Card */}
            <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.heatmapTitle}
                </h2>
            </div>
            {/* main heatmap component */}
            {qualityDeepDive[activeKeyDeepDiveTab]?.heatmap}
            <div
                className={
                    qualityDeepDive[activeKeyDeepDiveTab]?.heatmap ||
                        qualityDeepDive[activeKeyDeepDiveTab]?.table
                        ? "mr-bottom-20"
                        : ""
                }
            ></div>
            <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.title}
                </h2>
            </div>
            <div className="tier-subtitle">
                {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle}
            </div>
            {qualityDeepDive[activeKeyDeepDiveTab]?.stackBar}
            <div className="tier-container">
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.title2}
                </h2>
                {qualityDeepDive[activeKeyDeepDiveTab]?.button}
            </div>
            <div className="tier-subtitle">
                {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle2}
            </div>
            {qualityDeepDive[activeKeyDeepDiveTab]?.radioFilters}
            {qualityDeepDive[activeKeyDeepDiveTab]?.table}
            <div className="tier-container" key={'ev'}>
                <h2 className="tier-title">
                    {qualityDeepDive[activeKeyDeepDiveTab]?.title3}
                </h2>
                {qualityDeepDive[activeKeyDeepDiveTab]?.button3}
            </div>
            <div className="tier-subtitle">
                {qualityDeepDive[activeKeyDeepDiveTab]?.subtitle3}
            </div>
            {qualityDeepDive[activeKeyDeepDiveTab]?.radioFilters3}
            {qualityDeepDive[activeKeyDeepDiveTab]?.table3}
        </div>
    )
}
export default QualityTier2
