import { Radio } from "@gitlab-rtsensing/component-library";

type TimePeriod = "Last Week" | "Last Month" | "Last 3 Months";

interface RadioFiltersProps {
  timePeriod: TimePeriod;
  setTimePeriod: (value: TimePeriod) => void;
  filterDataByTimePeriod: (
    period: "Last Week" | "Last Month" | "Last 3 Months"
  ) => void;
}

export const RadioFilters: React.FC<RadioFiltersProps> = ({
  timePeriod,
  setTimePeriod,
  filterDataByTimePeriod,
}) => {
  const timePeriods: TimePeriod[] = [
    "Last Week",
    "Last Month",
    "Last 3 Months",
  ];

  return (
    <div className="d-flex" style={{ marginBottom: "10px" }}>
      <div className="ops-fs-5 ops-fw-bold" style={{ marginRight: "15px" }}>
        TIME PERIOD :
      </div>
      {timePeriods.map((period) => (
        <div key={period} style={{ marginRight: "8px" }}>
          <Radio
            name="timePeriod"
            value={period}
            checked={timePeriod === period}
            onChange={() => {
              setTimePeriod(period);
              filterDataByTimePeriod(period);
            }}
            variant="primary"
          />
        </div>
      ))}
    </div>
  );
};
